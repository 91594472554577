import { featureIsEnabled } from '@/integrations/launchDarkly'
import { FLAG_SSG_PROJECT_STATUS } from '@/integrations/launchDarkly/active-flags'
import store from '@/store'
import {
  refetchWorkspaceFromProjectIfNecessary,
  redirectToInitialWorkspace,
} from './_helpers'

export default [
  {
    path: 'researcher/workspaces/:workspaceId/projects',
    name: 'workspaces.projects',
    component: () => import('@/views/researcher/workspaces/Projects.vue'),
  },
  {
    path: 'researcher/workspaces/projects/:projectId/:status(active|scheduled|draft|completed)?',
    name: 'workspaces.project',
    async beforeEnter(to, from, next) {
      try {
        if (!featureIsEnabled(FLAG_SSG_PROJECT_STATUS)) {
          if (!to.params?.status) {
            // default status = draft
            return next({
              name: 'workspaces.project',
              params: {
                projectId: to.params.projectId,
                status: 'draft',
              },
            })
          }
        }
        // fetch the project
        await store.dispatch(
          'researcher/workspaces/fetchProject',
          to.params.projectId
        )
        await refetchWorkspaceFromProjectIfNecessary()
        next()
      } catch (error) {
        if (error.status === 404) {
          if (
            to.params.projectId ===
            store.getters['auth/user'].current_project_id
          ) {
            // if we were trying to redirect to the user.current_project_id and it wasn't
            // found, redirect back to home instead of 404
            from.meta.noRefetch = true
            return redirectToInitialWorkspace(next)
          }
        }
        next()
      }
    },
    component: () => import('@/views/researcher/workspaces/Project.vue'),
  },
]
