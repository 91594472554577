// typed manually. may not be 100% accurate
import type { Alpha2Code } from 'i18n-iso-countries'

export const USER_STATUSES = {
  OK: 'OK',
  BANNED: 'BANNED',
  DELETED: 'DELETED',
  UNDER_REVIEW: 'UNDER_REVIEW',
  ON_HOLD: 'ON_HOLD',
  IN_MFA_RECOVERY: 'IN_MFA_RECOVERY',
} as const

export type UserStatus = keyof typeof USER_STATUSES

export type UserCommonAttrs = {
  id: string
  email: string
  date_joined: string
  first_name: string
  last_name: string
  name: string
  username: string
  is_email_verified: boolean
  currency_code: 'USD' | 'GBP'
  balance: number
  has_password: boolean
  country: Alpha2Code
  terms_and_conditions: boolean
  privacy_policy: boolean
  status: UserStatus
  on_hold: boolean
  is_staff: boolean
  can_oidc_login_enabled: boolean
  experimental_group: number
  // TODO: these are participant specific, but returned for both researcher & participant
  can_contact_support_enabled: boolean
  can_cashout_enabled: boolean
  can_instant_cashout_enabled: boolean
  // TODO: these are researcher specific, but returned for both researcher & participant
  can_run_pilot_study_enabled: boolean
  invoice_usage_enabled: boolean
}
