import shortid from 'shortid'

function createBlankAnswer() {
  return {
    id: shortid.generate(),
    value: null,
  }
}

export default function createBlankSurvey() {
  return {
    title: '',
    sections: [
      {
        id: shortid.generate(),
        title: '',
        questions: [
          {
            id: shortid.generate(),
            title: null,
            type: null,
            answers: [createBlankAnswer(), createBlankAnswer()],
          },
        ],
      },
    ],
  }
}
