import { trackStructEvent } from '@/integrations/snowplow'
import { isDesktop } from '@/utils/device'

export const trackAccountEvent = (
  action: string,
  label?: string,
  property?: string
) => {
  trackStructEvent({
    category: 'account',
    action,
    label,
    property,
  })
}

export const trackLogin = () => {
  trackAccountEvent('logged-in')
}

export const trackLogout = () => {
  trackAccountEvent('logged-out')
}

export const trackCompletedOnboarding = () => {
  trackAccountEvent('onboarding-completed', 'click')
}

export const trackStartGoogleRegister = () => {
  trackAccountEvent('register-google-initiate', 'click')
}

export const trackStartEmailRegister = () => {
  trackAccountEvent('register-email-initiate', 'click')
}

export const trackRegisterGoogle = () => {
  trackAccountEvent('register-google-confirm', 'click')
}

export const trackRegisterEmail = () => {
  trackAccountEvent('register-email-confirm', 'click')
}

export const trackBalanceHubTooltip = async (action: string, label: string) => {
  // we don't want to track desktop click events, since the tooltip only appears on hover there
  const deviceIsDesktop = await isDesktop()
  if (deviceIsDesktop && action === 'click') return

  return trackAccountEvent(action, label, 'balance-hub')
}
