import store from '@/store'

export default [
  {
    path: 'researcher/workspaces/:workspaceId/team',
    name: 'workspaces.team',
    async beforeEnter(to, from, next) {
      await store.dispatch(
        'researcher/workspaces/fetchWorkspaceInvitations',
        to.params.workspaceId
      )
      next()
    },
    component: () => import('@/views/researcher/workspaces/Team.vue'),
  },
]
