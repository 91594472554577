import { i18n } from '@/i18n'
import { featureIsEnabled } from '@/integrations/launchDarkly'
import { FLAG_INC_AI_WORKSPACE_LANGUAGE } from '@/integrations/launchDarkly/active-flags'

export type WorkspaceProduct = 'ai' | 'human'

export const applyWorkspaceProduct = (workspace: WorkspaceWithProduct) => {
  if (featureIsEnabled(FLAG_INC_AI_WORKSPACE_LANGUAGE)) {
    if (isAIProductWorkspace(workspace)) {
      i18n.global.locale = 'en-x-ai'
    } else {
      i18n.global.locale = 'en'
    }
  }
}

export const isAIProductWorkspace = (workspace: WorkspaceWithProduct) => {
  return workspace.product === 'ai'
}

export type WorkspaceWithProduct = {
  id: string
  product: WorkspaceProduct
  [key: string]: unknown
}
