import endpoints from '@/api/endpoints'
import http from '@/api/http'

export const state = {
  integrations: [
    {
      title: 'Alchemer',
      cost: 'Paid',
      website: 'https://www.alchemer.com/',
      guide: 'General',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/866615',
      description:
        'From survey software to customer feedback - understand their engagement with markets, customers, and employees',
      category: { title: 'Experiment' },
      id: 0,
    },
    {
      title: 'Cognito Forms',
      cost: 'Some features are free',
      website: 'https://www.cognitoforms.com/',
      guide: 'General',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/866615',
      description: 'From surveys to registration forms - no code required',
      category: { title: 'Survey' },
      id: 1,
    },
    {
      title: 'FindingFive',
      cost: 'Some features are free',
      website: 'https://www.findingfive.com/',
      guide: 'General',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/866615',
      description:
        'Powers online research studies that uncover how our minds work',
      category: { title: 'Experiment' },
      id: 2,
    },
    {
      title: 'Google Forms',
      cost: 'Free',
      website: 'https://www.google.co.uk/forms/about/',
      guide: 'Specific',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/fe2fb4',
      description:
        'Create and share online forms and surveys, and analyze responses',
      category: { title: 'Survey' },
      id: 3,
    },
    {
      title: 'Gorilla',
      cost: 'Some features are free',
      website: 'https://gorilla.sc/',
      guide: 'Specific',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/7a2273',
      description:
        'All-in-one platform for behavioural science. Surveys, reaction time tasks, games, shopping simulations, multiplayer and more.',
      category: { title: 'Experiment' },
      id: 4,
    },
    {
      title: 'Heroku',
      cost: 'Some features are free',
      website: 'https://www.heroku.com/',
      guide: 'General',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/866615',
      description:
        'Cloud platform that lets you build, deliver, monitor and scale apps from idea to URL',
      category: { title: 'Cloud' },
      id: 5,
    },
    {
      title: 'Inquisit',
      cost: 'Paid',
      website: 'https://www.millisecond.com/',
      guide: 'General',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/866615',
      description:
        'Precision software for cognitive, social, neurophysiological and online psychological experiments',
      category: { title: 'Experiment' },
      id: 6,
    },
    {
      title: 'Jotform',
      cost: 'Free',
      website: 'https://www.jotform.com/',
      guide: 'General',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/866615',
      description: 'Free Online Form Builder & Form Creator',
      category: { title: 'Survey' },
      id: 7,
    },
    {
      title: 'Labvanced',
      cost: 'Some features are free',
      website: 'https://www.labvanced.com/',
      guide: 'Partner',
      setupGuideLink:
        'https://www.labvanced.com/static/docs/Prolific_Integration_Guide.pdf',
      description:
        'Cloud solution for professional web based experiments used by universities',
      category: { title: 'Experiment' },
      id: 8,
    },
    {
      title: 'Lime Survey',
      cost: 'Some features are free',
      website: 'https://www.limesurvey.org/',
      guide: 'General',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/866615',
      description:
        'Online survey tool for research institutes, universities and other educational institutions',
      category: { title: 'Survey' },
      id: 9,
    },
    {
      title: 'Microsoft Forms',
      cost: 'Paid',
      website: 'https://forms.office.com/',
      guide: 'General',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/866615',
      description: 'Create surveys and polls to collect customer feedback',
      category: { title: 'Survey' },
      id: 10,
    },
    {
      title: 'OTree',
      cost: 'Free',
      website: 'https://www.otree.org/',
      guide: 'General',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/866615',
      description:
        'Open-source platform for web-based interactive tasks for behavioral research and experiments',
      category: { title: 'Experiment' },
      id: 11,
    },
    {
      title: 'Pavlovia',
      cost: 'Free, pay for participants',
      website: 'https://pavlovia.org/',
      guide: 'Partner',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/a39cac',
      description:
        'Run, share, and explore experiments online for researchers in the behavioural sciences',
      category: { title: 'Experiment' },
      id: 12,
    },
    {
      title: 'PsyTester',
      cost: 'Free',
      website: 'https://psytester.com/',
      guide: 'General',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/866615',
      description:
        'Online repository of Psychology experiments created in Unity and built as WebGL applications',
      category: { title: 'Experiment' },
      id: 13,
    },
    {
      title: 'PsyToolKit',
      cost: 'Free',
      website: 'https://www.psytoolkit.org/',
      guide: 'General',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/866615',
      description:
        'Free-to-use toolkit for demonstrating, programming, and running cognitive-psychological experiments and surveys',
      category: { title: 'Experiment' },
      id: 14,
    },
    {
      title: 'Qualtrics',
      cost: 'Paid',
      website: 'https://www.qualtrics.com/uk/customer-experience/surveys/',
      guide: 'Specific',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/c9a271',
      description: 'Measure and optimise your CX with customer survey software',
      category: { title: 'Survey' },
      id: 15,
    },
    {
      title: 'QuestionPro',
      cost: 'Some features are free',
      website: 'https://www.questionpro.com/',
      guide: 'General',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/866615',
      description:
        'From surveys to research, customer experience and employee engagement',
      category: { title: 'Survey' },
      id: 16,
    },
    {
      title: 'RedCap',
      cost: 'Some features are free (free for non-profit)',
      website: 'https://www.project-redcap.org/',
      guide: 'General',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/866615',
      description:
        'Web application for building and managing online surveys and databases',
      category: { title: 'Survey' },
      id: 17,
    },
    {
      title: 'SmartSurvey',
      cost: 'Some features are free',
      website: 'https://www.smartsurvey.co.uk/',
      guide: 'General',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/866615',
      description: 'Online survey and questionnaire tool',
      category: { title: 'Survey' },
      id: 18,
    },
    {
      title: 'SoSci Survey',
      cost: 'Some features are free (free for non-profit)',
      website: 'https://www.soscisurvey.de/en/index',
      guide: 'General',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/866615',
      description:
        'White-label solution that adheres to the strict German data privacy laws with accessible survey interface',
      category: { title: 'Survey' },
      id: 19,
    },
    {
      title: 'Survey Monkey',
      cost: 'Paid',
      website: 'https://www.surveymonkey.co.uk/',
      guide: 'General',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/866615',
      description:
        'Free online survey tool to capture the voices and opinions of the people to drive your business forward',
      category: { title: 'Survey' },
      id: 20,
    },
    {
      title: 'Tatool Web',
      cost: 'Free',
      website: 'https://www.tatool-web.com/#!/',
      guide: 'General',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/866615',
      description: 'Open-source experiment software for researchers',
      category: { title: 'Experiment' },
      id: 21,
    },
    {
      title: 'Testable',
      cost: 'Some features are free',
      website: 'https://www.testable.org/',
      guide: 'General',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/866615',
      description: 'Create a wide range of behavioural experiments and surveys',
      category: { title: 'Experiment' },
      id: 22,
    },
    {
      title: 'Typeform',
      cost: 'Some features are free',
      website: 'https://www.typeform.com/',
      guide: 'Specific',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/34e7e3',
      description: 'Forms and Surveys',
      category: { title: 'Survey' },
      id: 23,
    },
    {
      title: 'Unipark',
      cost: 'Paid',
      website: 'https://www.unipark.com/en/',
      guide: 'General',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/866615',
      description:
        'Online survey software for students, post-graduates and academics within university research departments',
      category: { title: 'Survey' },
      id: 24,
    },
    {
      title: 'Useberry',
      cost: 'Paid',
      website: 'https://www.useberry.com/',
      guide: 'General',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/228e77',
      description:
        'Your one-stop solution for quick remote testing and rich, actionable insights that enable teams to make informed, user-centered decisions',
      category: { title: 'Experiment' },
      id: 25,
    },
    {
      title: 'Zoho',
      cost: 'Some features are free',
      website: 'https://www.zoho.com/survey/',
      guide: 'General',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/866615',
      description: 'Collect data for research, feedback, business and more',
      category: { title: 'Survey' },
      id: 26,
    },
    {
      title: 'Potato',
      cost: 'Free',
      website: 'https://potato-annotation.readthedocs.io/en/latest/',
      guide: 'Partner',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/99e97c',
      description:
        'Data annotation tool for NLP/ML/AI supporting your entire annotation pipeline.',
      category: { title: 'Open Source' },
      id: 28,
    },
    {
      title: 'Prolific Survey Builder',
      cost: 'Free',
      website: 'https://www.prolific.com',
      guide: 'Built-in',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/71c7b2',
      description:
        'Create up to five questions for quick, frictionless data collection. Available in select countries.',
      category: { title: 'Prolific Tools' },
      id: 29,
    },
    {
      title: 'SoPHIELabs',
      cost: 'Paid',
      website: 'https://www.sophielabs.com/',
      guide: 'General',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/91fa77',
      description:
        'Web-based solution to build interactive research experiments using a simple user interface, including real-time interaction such as chats and bargaining.',
      category: { title: 'Experiment' },
      id: 30,
    },
    {
      title: 'Meadows',
      cost: 'Paid',
      website: 'https://meadows-research.com/',
      guide: 'General',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/d21ef3',
      description:
        'State-of-the-art paradigms for vision scientists, linguists, neuroscientists and other researchers including labelling, surveys, ratings, RSVP and custom paradigms, no coding required.',
      category: { title: 'Experiment' },
      id: 31,
    },
    {
      title: 'RealEye',
      cost: 'Paid',
      website: 'https://www.realeye.io/',
      guide: 'Specific',
      setupGuideLink: 'https://researcher-help.prolific.com/en/article/f5cb73',
      description:
        'Webcam eye-tracking platform with attention and emotions analysis, reaction time tests, surveys, mouse and clicks tracking',
      category: { title: 'Eye Tracking' },
      id: 32,
    },
  ],
  tokens: [],
}

export const types = {
  SET_TOKENS: 'SET_TOKENS',
}

const getters = {
  integrations: state => state.integrations,
  integration: state => state.integration,
  recommendations: state => state.recommendations,
  tokens: state => state.tokens,
}

const actions = {
  fetchTokens({ commit }) {
    return http.get(endpoints.TOKENS).then(({ results }) => {
      commit(types.SET_TOKENS, results)
    })
  },
  createToken({ state, commit }, tokenName) {
    return http
      .post(endpoints.TOKENS, {
        name: tokenName,
        roles: [],
        permissions: [],
      })
      .then(
        ({
          name,
          token,
          user,
          permissions,
          roles,
          datetime_created,
          deleted_at,
        }) => {
          commit(types.SET_TOKENS, [
            ...state.tokens,
            {
              name,
              token,
              user,
              permissions,
              roles,
              datetime_created,
              deleted_at,
            },
          ])
        }
      )
  },
  deleteToken({ state, commit }, token) {
    return http.delete(endpoints.TOKENS_DELETE(token)).then(() => {
      commit(
        types.SET_TOKENS,
        state.tokens.filter(t => t !== token)
      )
    })
  },
}

const mutations = {
  [types.SET_TOKENS](state, tokens) {
    state.tokens = tokens
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
