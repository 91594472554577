<template>
  <div :class="['actions', align]">
    <slot />
  </div>
</template>

<!-- TODO: Migrate to <script setup lang="ts"> -->
<!-- eslint-disable vue/component-api-style -->
<!-- eslint-disable vue/block-lang -->
<script>
export default {
  name: 'PActions',

  props: {
    align: {
      type: String,
      default: 'right',
    },
  },
}
</script>

<style lang="scss" scoped>
.actions {
  margin-top: $space-8;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  &.left {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
    align-items: stretch;
  }
}
</style>
