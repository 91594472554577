import {
  localStorageGetItem,
  localStorageSetItem,
  sessionStorageSetItem,
  sessionStorageGetItem,
} from '@/utils/storage'

const STORAGE_KEY = 'dismissed_messages'
const STORAGE_TYPES = {
  local: 'local',
  session: 'session',
}

function getDismissedMessages(storageType) {
  const storageGetItemFunction =
    storageType === STORAGE_TYPES.local
      ? localStorageGetItem
      : sessionStorageGetItem
  return storageGetItemFunction(STORAGE_KEY) || {}
}

export function setStoredDismissedMessage(
  storageId,
  storageType = STORAGE_TYPES.local
) {
  const dismissedMessages = getDismissedMessages(storageType)
  dismissedMessages[storageId] = true

  const storageSetItemFunction =
    storageType === STORAGE_TYPES.local
      ? localStorageSetItem
      : sessionStorageSetItem
  return storageSetItemFunction(STORAGE_KEY, dismissedMessages)
}

export function checkIfMessagePreviouslyDismissed(
  storageId,
  storageType = STORAGE_TYPES.local
) {
  const dismissedMessages = getDismissedMessages(storageType)
  return Boolean(dismissedMessages[storageId])
}
