import store from '@/store'

export default [
  {
    path: 'researcher/workspaces/:workspaceId/groups',
    name: 'workspaces.participant-groups',
    component: () =>
      import('@/views/researcher/workspaces/ParticipantGroups.vue'),
    async beforeEnter(to, from, next) {
      await store.dispatch(
        'researcher/participantGroups/fetchParticipantGroups'
      )

      next()
    },
  },
  {
    // TODO: check URL scheme. If we want it to be more like projects,
    // we'll need to put backlinks to the workspace in the participant group response.
    path: 'researcher/workspaces/:workspaceId/groups/new',
    name: 'workspaces.participant-groups.new',
    component: () =>
      import('@/views/researcher/workspaces/NewParticipantGroup.vue'),
  },
  {
    // TODO: check URL scheme. If we want it to be more like projects,
    // we'll need to put backlinks to the workspace in the participant group response.
    path: 'researcher/workspaces/:workspaceId/groups/:participantGroupId',
    name: 'workspaces.participant-group',
    component: () =>
      import('@/views/researcher/workspaces/ViewParticipantGroup.vue'),
    async beforeEnter(to, from, next) {
      await store.dispatch(
        'researcher/participantGroups/fetchParticipantGroup',
        {
          participantGroupId: to.params.participantGroupId,
        }
      )

      next()
    },
  },
]
