import isEmpty from 'lodash/isEmpty'

/**
 * Sets a localStorage item
 * @param  {String} label the localStorage key
 * @param  {Object} obj   the object to store
 * @return {undefined}
 */
export function localStorageSetItem(label, obj) {
  if (isEmpty(obj)) {
    localStorage.removeItem(label)
  } else {
    window.localStorage.setItem(label, JSON.stringify(obj))
  }
}

/**
 * Gets a localStorage item
 * @param  {String} label the localStorage key
 * @return {Object}       the saved object
 */
export function localStorageGetItem(label) {
  const string = window.localStorage.getItem(label)
  if (isEmpty(string) || string === 'undefined' || string === 'null') {
    return null
  }
  return JSON.parse(string)
}

/**
 * Sets a sessionStorage item
 * @param  {String} label        the sessionStorage key
 * @param  {Object|String} value the value to store
 * @return {undefined}
 */
export function sessionStorageSetItem(label, value) {
  if (typeof value === 'object') {
    value = JSON.stringify(value)
  }
  if (isEmpty(value)) {
    sessionStorage.removeItem(label)
  } else {
    window.sessionStorage.setItem(label, value)
  }
}

/**
 * Gets a sessionStorage item
 * @param {String} label    the sessionStorage key
 * @returns {Object|String|null} the saved value
 */
export function sessionStorageGetItem(label) {
  const sessionStorageItem = window.sessionStorage.getItem(label)
  if (
    isEmpty(sessionStorageItem) ||
    sessionStorageItem === 'undefined' ||
    sessionStorageItem === 'null'
  ) {
    return null
  }

  const isJSONObject =
    sessionStorageItem.charAt(0) === '{' || sessionStorageItem.charAt(0) === '['

  if (isJSONObject) {
    return JSON.parse(sessionStorageItem)
  }

  return sessionStorageItem
}
