// run html strings through dompurify for xss prevention
import DOMPurify from 'dompurify'

export const DEFAULT_ALLOWED_TAGS = [
  'b',
  'i',
  'em',
  'strong',
  's',
  'u',
  'h1',
  'h2',
  'ol',
  'ul',
  'li',
  'p',
]
export const DEFAULT_ALLOWED_ATTRS = []

export default (
  html,
  {
    allowedTags = DEFAULT_ALLOWED_TAGS,
    allowedAttrs = DEFAULT_ALLOWED_ATTRS,
  } = {}
) =>
  DOMPurify.sanitize(html, {
    ALLOWED_TAGS: allowedTags,
    ALLOWED_ATTR: allowedAttrs,
  })
