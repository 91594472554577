export enum Action {
  CLICK = 'click',
}

export enum Category {
  RESEARCHER_MFA_BANNER = 'researcher_mfa_banner',
  RESEARCHER_ACCOUNT_SETTINGS = 'researcher_account_settings',
  RESEARCHER_REGISTER = 'researcher_register',
  PARTICIPANT_MFA_BANNER = 'participant_mfa_banner',
  PARTICIPANT_ACCOUNT_SETTINGS = 'participant_account_settings',
  PARTICIPANT_REGISTER = 'participant_register',
}

export enum Label {
  MORE_INFO = 'more_info_link',
  CLOSE_BANNER = 'close_banner',
  ENABLE_BUTTON = 'enable_mfa_button',
  CANCEL_BUTTON = 'cancel_button',
  CLOSE_MODAL = 'close_modal',
  TURN_ON_BUTTON = 'turn_on_button',
  SKIP_BUTTON = 'skip_button',
  REMOVE_MFA_BUTTON = 'remove_mfa_button',
  REMOVE_BUTTON = 'remove_button',
}

export enum Property {
  MFA_BANNER = 'mfa_banner',
  MFA_MODAL = 'mfa_modal',
  SECURITY_MFA = 'security_mfa',
  PROTECT_ACCOUNT_MFA = 'protect_account_mfa',
  REMOVE_MFA_MODAL = 'remove_mfa_modal',
}
