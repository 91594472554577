import store from '@/store'

export const stepsData = [
  {
    name: 'register',
    innerSteps: ['phone_number'],
    svg: 'rocket',
  },
  {
    name: 'onboarding_study',
    innerSteps: ['onboarding_study'],
    svg: 'pencil',
    hideOnByPass: true,
  },
  {
    name: 'id_verification',
    innerSteps: ['id_verification'],
    svg: 'microscope_shadow',
    hideOnByPass: true,
  },
]

export const areInnerStepsCompleted = stepData => {
  const userOnboarding = store.getters['auth/user']?.onboarding
  if (!userOnboarding) return false
  return stepData.innerSteps.every(step => userOnboarding?.[step]?.completed)
}

export const isStepEnabled = step => {
  const userOnboarding = store.getters['auth/user']?.onboarding

  // If user is not logged in and step is register, always enable. If other step, return disable.
  if (!userOnboarding) {
    return step === 'register'
  }

  // Otherwise check if all inner steps of the step are completed
  const currentStepIndex = stepsData.findIndex(({ name }) => name === step)

  const isCurrentStepCompleted = areInnerStepsCompleted(
    stepsData[currentStepIndex]
  )

  if (isCurrentStepCompleted) return false

  // If current step is not yet completed, check if previous step exists and is fully done to enable current one
  const previousStep =
    currentStepIndex === 0 ? null : stepsData[currentStepIndex - 1]

  if (!previousStep) return true

  const previousStepCompleted = areInnerStepsCompleted(previousStep)

  return previousStepCompleted
}

export const isOnboardingCompleted = () => {
  const user = store.getters['auth/user']

  if (
    !user?.onboarding?.onboarding_study?.completed &&
    !user?.is_admin_verified
  ) {
    return false
  }

  return (
    user?.is_verified || stepsData.every(step => areInnerStepsCompleted(step))
  )
}
