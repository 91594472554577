<template>
  <div class="logo-loading-container">
    <div class="logo-loading">
      <Image
        :src="logo"
        :width="240"
        :height="65"
        :responsive="false"
        class="logo"
      />
      <div data-testid="progress-bar" class="progress-bar" />
    </div>
  </div>
</template>

<!-- TODO: Migrate to <script setup lang="ts"> -->
<!-- eslint-disable vue/component-api-style -->
<!-- eslint-disable vue/block-lang -->
<script>
import nprogress from 'nprogress'
import { mapActions } from 'vuex'
import logo from '@/assets/svg/logo_color.svg'
import Image from '@/components/common/Image.vue'

export default {
  name: 'OidcCallback',
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Image,
  },

  data: () => ({
    logo,
  }),

  created() {
    nprogress.configure({
      parent: '.progress-bar',
      trickleRate: 0.1,
      trickleSpeed: 200,
    })
    this.oidcSignInCallback()
      .then(redirectPath => {
        this.$router.push(redirectPath).catch(err => {})
      })
      .catch(error => {
        // pass the query params through so we can display any error messages auth0 provides
        this.$router.push({
          name: 'oidcCallbackError',
          query: this.$route.query,
        })
      })
  },

  mounted() {
    nprogress.start()
  },

  methods: {
    ...mapActions('oidc', ['oidcSignInCallback']),
  },
}
</script>

<style scoped lang="scss">
.logo-loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background-color: $color-primary-inverse;
  align-items: center;
  overflow: hidden;

  .logo-loading {
    width: 240px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .logo {
      text-align: center;
      margin-bottom: $space-2;
    }

    .progress-bar {
      height: $space-1;
      overflow: hidden;
      background-color: $color-primary-50;
      border-radius: $border-radius-4;

      :deep(#nprogress) {
        .bar {
          background-color: $color-primary-500;
          height: $space-1;
          border-top-right-radius: $border-radius-4;
          border-bottom-right-radius: $border-radius-4;
        }

        .peg,
        .spinner-icon {
          display: none;
        }
      }
    }
  }
}
</style>
