import type { CreateStudyFormFields } from '@/api/types'
import {
  DATA_COLLECTION_EXTERNAL,
  MAX_SUBMISSIONS_PER_PARTICIPANT_DEFAULT,
  MAX_CONCURRENT_SUBMISSIONS_DEFAULT,
} from '@/constants'
import { setSubmissionsConfigField } from '@/utils/submissions-config-browser'

export const createInitialStudyFormValues = () => {
  const study: Partial<CreateStudyFormFields> = {
    data_collection_method: DATA_COLLECTION_EXTERNAL,
    name: '',
    internal_name: '',
    description: '',
    external_study_url: '',
    estimated_completion_time: 1,
    reward: 16,
    total_available_places: null,
    device_compatibility: ['mobile', 'desktop', 'tablet'],
    peripheral_requirements: [],
    total_cost: 0,
    representative_sample_fee: 0,
    publish_at: null,
    prolific_id_option: null,
  }

  setSubmissionsConfigField(
    study,
    'max_submissions_per_participant',
    MAX_SUBMISSIONS_PER_PARTICIPANT_DEFAULT
  )

  setSubmissionsConfigField(
    study,
    'max_concurrent_submissions',
    MAX_CONCURRENT_SUBMISSIONS_DEFAULT
  )

  return study
}
