import { isEmpty } from 'lodash'
import { endpoints, http } from '@/api'
import { featureIsEnabled, setLDWorkspace } from '@/integrations/launchDarkly'
import { FLAG_SSG_PROJECT_STATUS } from '@/integrations/launchDarkly/active-flags'
import pusher from '@/integrations/pusher'
import rum from '@/integrations/rum'
import { trackStructEvent } from '@/integrations/snowplow'
import download from '@/utils/download'
import { applyWorkspaceProduct } from '@/utils/workspace-product'

// TODO: Refactor all of our stores to use constants for types for consistency/linting
const SET_PUSHER_CHANNELS = 'SET_PUSHER_CHANNELS'
const ADD_PUSHER_CHANNEL = 'ADD_PUSHER_CHANNEL'
const SET_WORKSPACES = 'SET_WORKSPACES'
const SET_WORKSPACE = 'SET_WORKSPACE'
const DELETE_WORKSPACE = 'DELETE_WORKSPACE'
const SET_WALLET = 'SET_WALLET'
const SET_LOADING_WALLET = 'SET_LOADING_WALLET'
const UPDATE_WALLET_BALANCE = 'UPDATE_WALLET_BALANCE'
const SET_PROJECTS = 'SET_PROJECTS'
const SET_PROJECT = 'SET_PROJECT'
const ADD_PROJECT = 'ADD_PROJECT'
const DELETE_PROJECT = 'DELETE_PROJECT'
const SET_INVOICES = 'SET_INVOICES'
const ADD_WORKSPACE = 'ADD_WORKSPACE'
const SET_INVITATIONS = 'SET_INVITATIONS'
const SET_LOCAL_CURRENCY_DETAILS = 'SET_LOCAL_CURRENCY_DETAILS'
const UPDATE_USER_ROLES = 'UPDATE_USER_ROLES'
const UPDATE_INVITATION_ROLES = 'UPDATE_INVITATION_ROLES'
const REMOVE_USER = 'REMOVE_USER'
const SET_QUOTE = 'SET_QUOTE'
const RESET_QUOTE = 'RESET_QUOTE'
const UPDATE_PAYMENTS_BETA_STATUS = 'UPDATE_PAYMENTS_BETA_STATUS'
const SET_IS_TRIAL_WORKSPACE = 'SET_IS_TRIAL_WORKSPACE'
const SET_OPEN_NEXT_ONBOARDING_STEP = 'SET_OPEN_NEXT_ONBOARDING_STEP'
const SET_IS_CLOUD_MARKETPLACE_WORKSPACE = 'SET_IS_CLOUD_MARKETPLACE_WORKSPACE'

const pusherEvents = {
  UPDATE: 'UPDATE',
  FEATURE_FLAG: 'FEATURE_FLAG',
}

const state = {
  pusherChannels: [],
  workspaces: [],
  workspace: {},
  wallet: {},
  loadingWallet: true,
  projects: [],
  project: {},
  invoices: [],
  invitations: [],
  quote: {},
  isTrialWorkspace: false,
  isCloudMarketplaceWorkspace: false,
  openNextOnboardingStep: false,
}

const mutations = {
  [SET_PUSHER_CHANNELS](state, channels) {
    state.pusherChannels = channels
  },

  [ADD_PUSHER_CHANNEL](state, channelId) {
    state.pusherChannels.push(channelId)
  },

  [SET_WORKSPACES](state, workspaces) {
    state.workspaces = workspaces
  },

  [SET_WORKSPACE](state, workspace) {
    state.workspace = workspace
  },

  [DELETE_WORKSPACE](state, workspaceId) {
    const workspaces = state.workspaces.filter(
      workspace => workspace.id !== workspaceId
    )
    state.workspaces = workspaces
  },

  [SET_WALLET](state, wallet) {
    state.wallet = wallet
  },
  [SET_LOADING_WALLET](state, loading) {
    state.loadingWallet = loading
  },
  [SET_PROJECTS](state, projects) {
    state.projects = projects
  },

  [SET_PROJECT](state, project) {
    state.project = project
  },

  [ADD_PROJECT](state, project) {
    if (featureIsEnabled(FLAG_SSG_PROJECT_STATUS)) {
      state.projects = [project, ...state.projects]
    } else {
      state.projects.push(project)
    }
  },

  [DELETE_PROJECT](state, projectId) {
    const projects = state.projects.filter(project => project.id !== projectId)
    state.projects = projects
  },

  [UPDATE_WALLET_BALANCE](state, balance) {
    state.wallet.available_balance = balance
  },

  [SET_INVOICES](state, invoices) {
    state.invoices = invoices
  },

  [ADD_WORKSPACE](state, workspace) {
    state.workspaces.push(workspace)
  },

  [SET_INVITATIONS](state, invitations) {
    state.invitations = invitations
  },

  [SET_LOCAL_CURRENCY_DETAILS](state, details) {
    state.localCurrencyCode = details.code
    state.conversionRate = details.rate
    state.currencyDecimalPlaces = details.decimal_places
  },

  [UPDATE_USER_ROLES](state, { id: userId, roles }) {
    const user = state.workspace?.users.find(({ id }) => userId === id)
    if (user) {
      user.roles = roles
    }
  },

  [UPDATE_INVITATION_ROLES](state, { id, roles }) {
    for (const invitation of state.invitations) {
      if (invitation.invitee.id === id) {
        invitation.roles = roles
        break
      }
    }
  },

  [REMOVE_USER](state, userId) {
    state.workspace.users = state.workspace.users.filter(
      ({ id }) => id !== userId
    )
  },

  [SET_QUOTE](state, quote) {
    state.quote = quote
  },

  [RESET_QUOTE](state) {
    state.quote = {}
  },

  [UPDATE_PAYMENTS_BETA_STATUS](state, inPaymentsBeta) {
    state.wallet.in_payments_beta = inPaymentsBeta
  },

  [SET_IS_TRIAL_WORKSPACE](state, isTrialWorkspace) {
    state.isTrialWorkspace = isTrialWorkspace
  },
  [SET_OPEN_NEXT_ONBOARDING_STEP](state, shouldOpenNextOnboardingStep) {
    state.openNextOnboardingStep = shouldOpenNextOnboardingStep
  },
  [SET_IS_CLOUD_MARKETPLACE_WORKSPACE](state, isCloudMarketplaceWorkspace) {
    state.isCloudMarketplaceWorkspace = isCloudMarketplaceWorkspace
  },
}

const actions = {
  subscribe({
    state: {
      wallet: { id: walletId },
    },
    commit,
  }) {
    if (!walletId) return

    const channel = pusher.subscribe(walletId)

    /**
     * We are not using this event for now as we're not confident it's working properly
     * and we're not 100% sure we should be using a pusher for the wallet balance. It's
     * commented out just in case we do decide to use pusher after all.
     */
    // channel.bind(pusherEvents.UPDATE, ({ available_balance }) => {
    //   commit(UPDATE_WALLET_BALANCE, available_balance)
    // })

    channel.bind(pusherEvents.FEATURE_FLAG, ({ in_payments_beta }) => {
      commit(UPDATE_PAYMENTS_BETA_STATUS, in_payments_beta)
    })

    commit(ADD_PUSHER_CHANNEL, walletId)
  },

  unsubscribe({ state: { pusherChannels }, commit }) {
    pusherChannels.forEach(id => pusher.unsubscribe(id))
    commit(SET_PUSHER_CHANNELS, [])
  },

  updateResearcherBalance({ commit }, balance) {
    return commit(`researcher/workspaces/UPDATE_WALLET_BALANCE`, balance, {
      root: true,
    })
  },

  fetchWorkspaces({ commit }, param) {
    return http
      .get(`${endpoints.WORKSPACES}${param ? param : ''}`)
      .then(({ results: workspaces }) => {
        rum.setGlobalContextProperty('workspaces_count', workspaces?.length)
        commit(SET_WORKSPACES, workspaces)
      })
  },

  async fetchWorkspace({ commit, dispatch }, workspaceId) {
    dispatch('researcher/filters/resetFilterSetsCount', {}, { root: true })
    dispatch(
      'researcher/participantGroups/resetParticipantGroupsCount',
      {},
      {
        root: true,
      }
    )
    dispatch('researcher/campaigns/resetCampaignCount', {}, { root: true })

    const [workspace] = await Promise.all([
      http.get(endpoints.WORKSPACE(workspaceId)).then(async workspace => {
        rum.setGlobalContextProperty('workspace_id', workspaceId)
        commit(SET_WORKSPACE, workspace)
        commit(SET_IS_TRIAL_WORKSPACE, workspace.is_trial_workspace)

        commit(
          SET_IS_CLOUD_MARKETPLACE_WORKSPACE,
          workspace.cloud_marketplace_account !== null
        )

        /**
         * We need to wait for the workspace context to be set before we can
         * apply any feature flags that target workspaces inside `applyWorkspaceProduct`.
         */
        await setLDWorkspace(workspace)
        applyWorkspaceProduct(workspace)
        return workspace

        // set workspace context in DD RUM
      }),
      dispatch('fetchProjects', workspaceId),
    ])

    return workspace
  },

  updateWorkspace({ commit, dispatch }, { id, ...patch }) {
    return http.patch(endpoints.WORKSPACE(id), patch).then(workspace => {
      commit(SET_WORKSPACE, workspace)
      // refetch workspaces list on successful update
      return dispatch('fetchWorkspaces')
    })
  },

  deleteWorkspace({ commit }, { id }) {
    return http.delete(endpoints.WORKSPACE(id)).then(() => {
      commit(DELETE_WORKSPACE, id)
    })
  },

  async fetchWallet({ commit, dispatch }, walletId) {
    try {
      commit(SET_LOADING_WALLET, true)

      const wallet = await http.get(endpoints.WALLET(walletId))
      rum.setGlobalContextProperty('wallet_id', walletId)
      rum.setGlobalContextProperty('wallet_currency_code', wallet.currency_code)
      rum.setGlobalContextProperty('wallet_balance', wallet.available_balance)

      commit(SET_WALLET, wallet)
      dispatch('subscribe')
    } finally {
      commit(SET_LOADING_WALLET, false)
    }
  },

  async fetchProjects({ commit }, workspaceId) {
    // TODO: RNC-677 we're setting ?page_size=100 as a quick fix
    // We will eventually want to paginate projects properly.

    const params = {
      page_size: 100,
    }

    if (featureIsEnabled(FLAG_SSG_PROJECT_STATUS)) {
      params.ordering = 'position'
      params.fields = 'study_summary'
    }

    const { results: projects } = await http.get(
      endpoints.PROJECTS(workspaceId),
      {
        params,
      }
    )
    rum.setGlobalContextProperty('paged_projects_count', projects?.length)
    commit(SET_PROJECTS, projects)
  },

  async fetchProject({ commit, dispatch, rootGetters }, projectId) {
    const params = {}

    if (featureIsEnabled(FLAG_SSG_PROJECT_STATUS)) {
      params.fields = 'study_summary'
    }

    const project = await http.get(endpoints.PROJECT(projectId), { params })
    rum.setGlobalContextProperty('project_id', projectId)
    commit(SET_PROJECT, project)
  },

  async fetchCurrentWorkspaceByProjectId({ dispatch, rootGetters }, projectId) {
    return await http.get(endpoints.PROJECT(projectId)).then(async project => {
      const workspaceId = project.workspace
      await dispatch('fetchWorkspace', workspaceId)
    })
  },

  createProject(
    {
      state: {
        workspace: { id: activeWorkspaceId },
      },
      commit,
    },
    payload
  ) {
    const { setCurrentProject, ...rest } = payload
    return http
      .post(endpoints.PROJECTS(activeWorkspaceId), rest)
      .then(project => {
        commit(ADD_PROJECT, project)
        if (setCurrentProject) {
          commit(SET_PROJECT, project)
        }
        return project
      })
  },

  updateProject(
    {
      commit,
      dispatch,
      state: {
        workspace: { id: workspaceId },
      },
    },
    { id, ...patch }
  ) {
    const params = {}

    if (featureIsEnabled(FLAG_SSG_PROJECT_STATUS)) {
      params.fields = 'study_summary'
    }

    return http
      .patch(endpoints.PROJECT(id), patch, { params })
      .then(project => {
        commit(SET_PROJECT, project)
        // refetch projects list on successful update
        dispatch('fetchProjects', workspaceId)
        return project
      })
  },

  async deleteProject({ commit, dispatch }, id) {
    await http.delete(endpoints.PROJECT(id))
    commit(DELETE_PROJECT, id)
  },

  createWorkspace({ commit }, payload) {
    return http.post(endpoints.WORKSPACES, payload).then(workspace => {
      commit(ADD_WORKSPACE, workspace)
      return workspace
    })
  },

  inviteUsers(
    {
      state: {
        workspace: { id: workspaceId },
      },
      dispatch,
    },
    users
  ) {
    return http.post(endpoints.INVITATIONS, users).then(() => {
      return dispatch('fetchWorkspace', workspaceId)
    })
  },

  handleInvitation(_, token) {
    return http
      .post(endpoints.INVITATIONS_ACCEPT(token))
      .then(({ association: workspaceId }) => {
        trackStructEvent({
          category: 'workspace',
          action: 'joined-workspace',
          label: workspaceId,
        })

        return workspaceId
      })
  },

  fetchInvoices({ commit }, walletId) {
    return http.get(endpoints.WALLET_INVOICES(walletId)).then(invoices => {
      commit(SET_INVOICES, invoices.results)
    })
  },

  downloadInvoice(
    {
      dispatch,
      state: {
        wallet: { id: walletId },
      },
    },
    invoiceId
  ) {
    dispatch('global/setLoading', true, { root: true })
    return http
      .get(endpoints.WALLET_DOWNLOAD_INVOICE(walletId, invoiceId), {
        responseType: 'blob',
      })
      .then(data => {
        download(data, `prolific-invoice-${invoiceId}.pdf`, 'application/pdf')
        dispatch('global/setLoading', false, { root: true })
      })
      .catch(_error => {
        dispatch('global/setLoading', false, { root: true })
      })
  },

  async downloadPaymentsInvoice(_, id) {
    try {
      const response = await http.get(endpoints.DOWNLOAD_PAYMENTS_INVOICE(id), {
        responseType: 'blob',
      })
      download(response, `prolific-invoice-${id}.pdf`, 'application/pdf')
    } catch (error) {
      // Do nothing, just catch the error
    }
  },

  emailInvoice(_, { invoiceId, email }) {
    return http.post(endpoints.EMAIL_INVOICE(invoiceId), { email })
  },

  requestCreateInvoice(_, data) {
    return http.post(endpoints.CREATE_INVOICES, data).then(res => res)
  },

  updateWallet(
    {
      state: {
        wallet: { id: walletId },
      },
      commit,
    },
    patch
  ) {
    return http.patch(endpoints.WALLET(walletId), patch).then(wallet => {
      commit(SET_WALLET, wallet)
    })
  },

  getBraintreeTokenWallet({
    state: {
      wallet: { id: walletId },
    },
  }) {
    return http
      .get(endpoints.BRAINTREE_TOKEN, {
        params: { wallet_id: walletId },
      })
      .then(({ bt_token }) => {
        return Promise.resolve(bt_token)
      })
  },

  async topupWallet(
    {
      state: {
        wallet: { id: walletId },
      },
      dispatch,
      rootGetters,
    },
    { payload }
  ) {
    // TODO: we're reusing the user topup endpoint. May need to change once backend is ready.
    const { id: userId } = rootGetters['auth/user']

    try {
      const { top_up_amount: amount } = await http.post(
        endpoints.TOPUP(userId),
        {
          ...payload,
          manage: true,
        },
        {
          params: { wallet_id: walletId },
        }
      )
      // TODO: can we avoid the refetch here if BE returns enough wallet data (available_balance etc)
      await dispatch('fetchWallet', walletId)
      return amount
    } catch (error) {
      return Promise.reject(error)
    }
  },

  getBankTransferDetailsWallet({
    state: {
      wallet: { id: walletId },
    },
  }) {
    return http.get(endpoints.BANK_TRANSFER, {
      params: { wallet_id: walletId },
    })
  },

  requestInvoiceWallet(
    {
      state: {
        wallet: { id: walletId },
      },
    },
    data
  ) {
    return http.post(endpoints.STRIPE_INVOICES, data, {
      params: { wallet_id: walletId },
    })
  },

  fetchWorkspaceInvitations({ commit }, workspaceId) {
    return http
      .get(endpoints.INVITATIONS_WORKSPACE(workspaceId), {
        params: {
          status: 'INVITED',
        },
      })
      .then(({ results: invitations }) => {
        commit(SET_INVITATIONS, invitations)
      })
  },

  getLocalCurrencyDetailsWallet({
    state: {
      wallet: { id: walletId },
    },
    commit,
  }) {
    return http
      .get(endpoints.LOCAL_BANK_TRANSFERS_CURRENCIES, {
        params: { wallet_id: walletId },
      })
      .then(data => {
        const details = data.currencies[0]
        commit(SET_LOCAL_CURRENCY_DETAILS, details)
        return Promise.resolve(details)
      })
  },

  getTransferConvertedAmountWallet(
    {
      state: {
        wallet: { id: walletId },
        localCurrencyCode: toCurrency,
      },
    },
    amount
  ) {
    return http
      .get(endpoints.CONVERT_LOCAL_BANK_TRANSFER_AMOUNT, {
        params: {
          amount,
          to_currency: toCurrency,
          wallet_id: walletId,
        },
      })
      .then(({ amount }) => {
        return Promise.resolve(amount)
      })
  },

  createLocalBankTransferWallet(
    {
      state: {
        wallet: { id: walletId },
      },
    },
    data
  ) {
    data.wallet_id = walletId
    return http.post(endpoints.LOCAL_BANK_TRANSFERS, data)
  },

  requestRefund({ commit }, { id, ...patch }) {
    return http.post(endpoints.WALLET_REFUND(id), patch).then(() => {
      commit(UPDATE_WALLET_BALANCE, 0)
    })
  },

  moveStudy({ dispatch }, { studyId, projectId }) {
    return http
      .patch(endpoints.STUDY(studyId), { project: projectId })
      .then(() => {
        dispatch('researcher/studies/removeFromList', studyId, { root: true })
      })
  },

  updateUserRoles(
    {
      commit,
      getters,
      state: {
        workspace: { id: workspaceId },
      },
    },
    { id, roles }
  ) {
    // optimistically commit before we get an API response, given there is no loading state
    const existingRoles = getters.getUserById(id)?.roles
    commit(UPDATE_USER_ROLES, { id, roles })
    return http
      .post(endpoints.WORKSPACE_USER_PERMISSIONS(workspaceId), {
        user: id,
        roles,
      })
      .catch(() => {
        // correct our optimism
        commit(UPDATE_USER_ROLES, { id, roles: existingRoles })
      })
  },

  removeUserFromWorkspace({ commit, state: { workspace } }, userId) {
    return http
      .delete(endpoints.WORKSPACE_USER(workspace.id, userId))
      .then(() => {
        commit(REMOVE_USER, userId)
      })
  },

  fetchQuote({ commit }, quoteId) {
    return http.get(endpoints.QUOTE(quoteId)).then(quote => {
      commit(SET_QUOTE, quote)
    })
  },
  openNextOnboardingStep({ commit }, shouldOpenNextOnboardingStep) {
    commit(SET_OPEN_NEXT_ONBOARDING_STEP, shouldOpenNextOnboardingStep)
  },
}

const getters = {
  workspaces: ({ workspaces }) => workspaces,
  workspace: ({ workspace }) => workspace,
  workspaceId: ({ workspace }) => workspace?.id,
  workspaceWallet: ({ workspace }) => workspace?.wallet,
  wallet: ({ wallet }) => wallet,
  loadingWallet: ({ loadingWallet }) => loadingWallet,
  walletAddress: ({ wallet }) => wallet?.address,
  walletCountry: ({ wallet }) => wallet?.address?.country,
  walletId: ({ wallet }) => wallet?.id,
  walletAvailableBalance: ({ wallet }) => wallet?.available_balance,
  walletInPaymentsBeta: ({ wallet }) => wallet?.in_payments_beta,
  walletCurrencyCode: ({ wallet }) => wallet?.currency_code,
  hasWalletAddress: ({ wallet }) => {
    const walletAddress = wallet?.address
    if (!walletAddress) return false
    return (
      !isEmpty(walletAddress) &&
      !Object.values(walletAddress).every(addressValue => addressValue === null)
    )
  },
  projects: ({ projects }) => projects,
  getProjectById:
    ({ projects }) =>
    projectId =>
      projects?.find(({ id }) => id === projectId),
  project: ({ project }) => project,
  users: ({ workspace }) => {
    return workspace.users || []
  },
  currentUser: (_state, getters, _rootState, rootGetters) =>
    getters.getUserById(rootGetters['auth/user']?.id),
  getUserById:
    ({ workspace }) =>
    userId =>
      workspace.users?.find(({ id }) => id === userId),
  invoices: ({ invoices }) => invoices,
  invitations: ({ invitations }) => invitations,
  quote: ({ quote }) => quote,
  isTrialWorkspace: ({ isTrialWorkspace }) => isTrialWorkspace,
  isCloudMarketplaceWorkspace: ({ isCloudMarketplaceWorkspace }) =>
    isCloudMarketplaceWorkspace,
  isWorkspaceAdminServed: ({ workspace }) => workspace.is_admin_served,
  openNextOnboardingStep: ({ openNextOnboardingStep }) =>
    openNextOnboardingStep,
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
