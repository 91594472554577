export default [
  {
    path: 'researcher/workspaces/:workspaceId/settings',
    name: 'workspaces.settings',
    component: () => import('@/views/researcher/workspaces/Settings.vue'),
  },
  {
    path: 'researcher/workspaces/:workspaceId/settings/tokens',
    name: 'workspaces.settings.tokens',
    component: () => import('@/views/researcher/integrations/Tokens.vue'),
  },
  {
    path: 'researcher/workspaces/:workspaceId/settings/tokens/create',
    name: 'workspaces.settings.token.create',
    component: () => import('@/views/researcher/integrations/TokenCreate.vue'),
  },
]
