import { trackStructEvent } from '@/integrations/snowplow'

export const trackViewCouponExpiringAlert = () => {
  trackStructEvent({
    category: 'researcher_coupon_alert',
    label: 'free_credits_are_expiring',
    action: 'view',
    property: 'week_before_alert',
  })
}

export const trackDismissCouponExpiringAlert = () => {
  trackStructEvent({
    category: 'researcher_coupon_alert',
    label: 'close_alert',
    action: 'click',
    property: 'week_before_alert',
  })
}

export const trackViewNewCouponAlert = () => {
  trackStructEvent({
    category: 'researcher_coupon_alert',
    label: 'free_credits_will_be_applied',
    action: 'view',
    property: 'new_coupon_alert',
  })
}

export const trackDismissNewCouponAlert = () => {
  trackStructEvent({
    category: 'researcher_coupon_alert',
    label: 'close_alert',
    action: 'click',
    property: 'new_coupon_alert',
  })
}
