<template>
  <transition name="fade">
    <div
      v-if="(message || $slots.default) && !dismissed"
      data-testid="message"
      class="message-wrapper"
    >
      <div :class="['message', type, variant, { floating }]" role="status">
        <span class="type-icon">
          <component
            :is="typeIcon"
            v-if="showTypeIcon"
            data-testid="type-icon"
          />
          <slot v-else name="icon" />
        </span>

        <!-- `message` is a HTML string, so safe to ignore here -->
        <!-- eslint-disable vue/no-v-html -->
        <span v-if="message" class="message-text" v-html="message" />
        <!-- eslint-enable vue/no-v-html -->
        <span v-else class="message-text">
          <slot />
        </span>

        <DSIconButton
          v-if="dismissible && storageId"
          :aria-label="$t('dismiss_message')"
          :hide-tooltip="true"
          data-testid="dismiss-button"
          size="medium"
          @click="dismissMessage"
        >
          <DSIconClose class="dismiss-icon" />
        </DSIconButton>
      </div>
    </div>
  </transition>
</template>

<i18n lang="yaml">
en:
  dismiss_message: Dismiss message
</i18n>

<!-- TODO: Migrate to <script setup lang="ts"> -->
<!-- eslint-disable vue/component-api-style -->
<!-- eslint-disable vue/block-lang -->
<script>
// TODO: move to DS?
import {
  DSIconButton,
  DSIconClose,
  DSIconExclamationCircle,
  DSIconCheckCircle,
  DSIconInfoCircle,
} from '@prolific-oss/design-system'
import {
  checkIfMessagePreviouslyDismissed,
  setStoredDismissedMessage,
} from '@/utils/dismissed_messages'

export default {
  name: 'PMessage',

  components: {
    DSIconButton,
    DSIconClose,
    DSIconExclamationCircle,
    DSIconCheckCircle,
    DSIconInfoCircle,
  },

  props: {
    message: {
      type: [String, Boolean],
      default: false,
    },

    dismissible: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: 'info',
      validator: value =>
        ['info', 'success', 'warning', 'error'].includes(value),
    },

    variant: {
      type: String,
      default: 'banner',
      validator: value => ['banner', 'attention-box'].includes(value),
    },

    storageType: {
      type: String,
      default: 'local',
      validator: value => ['local', 'session'].includes(value),
    },

    storageId: {
      type: [Boolean, String],
      default: false,
    },

    floating: {
      type: Boolean,
      default: false,
    },

    showTypeIcon: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['dismiss', 'message-dismissed'],

  data() {
    return {
      dismissed: false,
    }
  },

  computed: {
    typeIcon() {
      switch (this.type) {
        case 'warning':
          return DSIconExclamationCircle
        case 'error':
          return DSIconExclamationCircle
        case 'success':
          return DSIconCheckCircle
        default:
          return DSIconInfoCircle
      }
    },
  },

  created() {
    this.hideMessageIfPreviouslyDismissed()
    this.hideMessageAfterTimeOut()
  },

  methods: {
    dismissMessage() {
      this.dismissed = true
      this.$emit('dismiss')
      setStoredDismissedMessage(this.storageId, this.storageType)
    },

    hideMessageIfPreviouslyDismissed() {
      const messagePreviouslyDismissed = checkIfMessagePreviouslyDismissed(
        this.storageId,
        this.storageType
      )
      if (messagePreviouslyDismissed) {
        this.dismissed = true
        this.$emit('message-dismissed', true)
      } else {
        this.$emit('message-dismissed', false)
      }
    },

    hideMessageAfterTimeOut() {
      if (!this.dismissible && this.floating) {
        setTimeout(() => (this.dismissed = true), 2500)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.message-wrapper {
  display: block;

  @include breakpoint($md) {
    display: flex;
    justify-content: center;
  }
}

.message {
  font-size: $font-size-base;

  // variants
  &.banner {
    background-color: $background-color-base;
    border-left: 4px solid $border-color-primary;
    color: $text-color-soft;
    width: 100%;
    padding: $space-4 $space-5 $space-4 $space-4;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    border-bottom: $border-width-base solid $border-color-list-item;

    :deep(a) {
      display: inline-flex; // prevent underline extending past link text
      text-decoration: underline;
      font-weight: $font-weight-600;
    }

    .type-icon {
      font-size: $font-size-6;
      color: $text-color-primary;
      margin-right: $space-3;
      position: relative;
    }

    .message-text {
      margin-right: $space-4;
      font-weight: 500;
    }

    &.error {
      border-left-color: $color-danger-400;

      // not in design system
      background-color: #fdf4f4;

      .type-icon {
        color: $color-danger-400;
      }
    }

    &.warning {
      border-left-color: $color-alarm-400;

      // not in design system
      background-color: #fdf9f3;

      .type-icon {
        color: $color-alarm-400;
      }
    }

    &.success {
      // not in design system
      border-left-color: #007431;
      background-color: #f2f8f5;

      .type-icon {
        color: #007431;
      }
    }
  }

  &.attention-box {
    display: flex;
    align-items: flex-start;
    border-radius: $border-radius-8;
    padding: $space-4;
    gap: $space-3;
    flex: 1;

    .type-icon {
      font-size: $font-size-5;
      line-height: 1.2em;
    }

    .message-text {
      align-self: center;
    }

    &.info {
      background: $background-color-primary-softest;

      .type-icon {
        color: $color-primary;
      }
    }

    &.error {
      background: $background-color-danger-softest;

      .type-icon {
        color: $color-danger;
      }
    }

    &.warning {
      background: $background-color-alarm-softest;

      .type-icon {
        color: $color-alarm;
      }
    }

    &.success {
      background: $background-color-success-softest;

      .type-icon {
        color: $color-success;
      }
    }
  }

  &.floating {
    position: fixed;
    top: 0;
    right: 0;
    bottom: initial;
    width: calc(100% - #{$space-4});
    max-width: 100%;
    border-radius: $border-radius-4;
    border: $border-width-base solid $border-color-base;
    box-shadow: $box-shadow-heavy;
    z-index: 999999;
    margin: 10px;

    @include breakpoint($md) {
      top: initial;
      bottom: 10px;
      right: 10px;
      width: 100%;
      max-width: 650px;
    }
  }

  :deep(.ds-icon-button-wrapper) {
    margin-left: auto;
  }

  .dismiss-icon {
    // not in design system
    color: #444f5f;
  }
}
</style>
