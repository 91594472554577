import axios, { type AxiosInstance } from 'axios'
import configureInterceptors from './interceptors'

let accessDetailsHttpClient: AxiosInstance

export const getAccessDetailsHttpClient = (token: string) => {
  if (!accessDetailsHttpClient) {
    accessDetailsHttpClient = axios.create()

    configureInterceptors(accessDetailsHttpClient, false)
  }

  accessDetailsHttpClient.defaults.headers.common.Authorization = `Bearer ${token}`

  return accessDetailsHttpClient
}
