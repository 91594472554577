import FingerprintJS from '@fingerprintjs/fingerprintjs'

export const getFingerprint = async () => {
  // eslint-disable-next-line import/no-named-as-default-member
  const fpPromise = FingerprintJS.load()
  const fp = await fpPromise
  const { visitorId, confidence, components } = await fp.get()

  const detail = Object.keys(components).reduce((acc, key) => {
    if (key !== 'canvas') {
      acc[key] = components[key].value
    }
    return acc
  }, {})

  detail['confidence'] = confidence.score

  return {
    vid: visitorId,
    detail: btoa(JSON.stringify(detail)),
  }
}
