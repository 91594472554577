import store from '@/store'

export const refetchWorkspaceFromStudyIfNecessary = async () => {
  const studyWorkspaceId = store.getters['researcher/studies/study'].workspace
  const activeWorkspaceId = store.getters['researcher/workspaces/workspace'].id
  if (studyWorkspaceId !== activeWorkspaceId) {
    await store.dispatch(
      'researcher/workspaces/fetchWorkspace',
      studyWorkspaceId
    )
  }
}

export const refetchWorkspaceFromProjectIfNecessary = async () => {
  // if the project's workspace is different to the one in state, refetch
  const projectWorkspaceId =
    store.getters['researcher/workspaces/project'].workspace
  const activeWorkspaceId = store.getters['researcher/workspaces/workspace'].id
  if (projectWorkspaceId !== activeWorkspaceId) {
    await store.dispatch(
      'researcher/workspaces/fetchWorkspace',
      projectWorkspaceId
    )
  }
}

export const redirectToInitialWorkspace = next => {
  // No active workspace at this point. Use the first one
  const workspaceId = store.getters['researcher/workspaces/workspaces']?.[0]?.id

  if (!workspaceId) {
    return next({
      name: 'workspaces.none',
      replace: true,
    })
  }

  next({
    name: 'workspaces.projects',
    params: {
      workspaceId,
    },
    replace: true,
  })
}
