import { normalize } from '@/utils/normalize'

export const types = {
  SET_SINGLE: 'SET_SINGLE',
  CLEAR_SINGLE: 'CLEAR_SINGLE',
  SET_LIST: 'SET_LIST',
  APPEND_LIST: 'APPEND_LIST',
  ADD: 'ADD',
  UPDATE: 'UPDATE',
  REPLACE: 'REPLACE',
  REMOVE_FROM_LIST: 'REMOVE_FROM_LIST',
  DELETE: 'DELETE',
}

export default function ({ entity = 'item', entities = 'items' } = {}) {
  const listName = `${entities}List`

  return {
    state: {
      [entity]: {},
      [entities]: {},
      [listName]: [],
    },

    mutations: {
      [types.SET_SINGLE](state, item) {
        const normalized = normalize(item, entity)
        for (const _entity in normalized.entities) {
          if (_entity === entity) {
            state[entity] = normalized.entities[_entity][normalized.result]
          } else {
            state[_entity] = normalized.entities[_entity]
          }
        }
      },

      [types.CLEAR_SINGLE](state) {
        state[entity] = {}
      },

      [types.SET_LIST](state, list) {
        if (list && list.length > 0) {
          const normalized = normalize(list, entities)
          for (const _entity in normalized.entities) {
            state[_entity] = normalized.entities[_entity]
          }
          state[listName] = normalized.result
        } else {
          state[entities] = {}
          state[listName] = []
        }
      },

      [types.APPEND_LIST](state, list) {
        if (list && list.length > 0) {
          const normalized = normalize(list, entities)
          for (const _entity in normalized.entities) {
            for (const id in normalized.entities[_entity]) {
              state[_entity][id] = normalized.entities[_entity][id]
            }
          }
          state[listName].push(...normalized.result)
        }
      },

      [types.ADD](state, item) {
        const normalized = normalize(item, entity)
        state[entities][item.id] =
          normalized.entities[entity][normalized.result]
        state[listName].unshift(item.id)
      },

      [types.UPDATE](state, { id, ...data }) {
        const _item = state[entity]
        if (state?.[entities]?.[id]) {
          state[entities][id] = Object.assign(state[entities][id], data)
        }
        if (_item && _item.id === id) {
          state[entity] = Object.assign(_item, data)
        }
      },

      [types.REPLACE](state, item) {
        const _item = state[entity]
        const { id } = item
        if (state?.[entities]?.[id]) {
          state[entities][id] = item
        }
        if (_item && _item.id === item.id) {
          state[entity] = item
        }
      },

      [types.REMOVE_FROM_LIST](state, id) {
        const _entities = state[entities]
        if (_entities && _entities[id]) {
          delete _entities[id]
          const index = state[listName].findIndex(itemId => itemId === id)
          state[listName].splice(index, 1)
        }
      },

      [types.DELETE](state, id) {
        const _entities = state[entities]
        const _item = state[entity]
        if (_entities && _entities[id]) {
          delete _entities[id]
          const index = state[listName].findIndex(itemId => itemId === id)
          state[listName].splice(index, 1)
        }
        if (_item && _item.id === id) {
          state[entity] = {}
        }
      },
    },

    getters: {
      all: state => state[listName].map(id => state[entities][id]),
      byId: state => id => state[entities][id],
    },
  }
}
