import flattenDeep from 'lodash/flattenDeep'

// study statuses
export const ACTIVE = 'ACTIVE'
export const COMPLETED = 'COMPLETED'
export const DRAFT = 'DRAFT'
export const PAUSED = 'PAUSED'
export const PUBLISHING = 'PUBLISHING'
export const SCHEDULED = 'SCHEDULED'

// submission statuses
export const APPROVED = 'APPROVED'
export const PARTIALLY_APPROVED = 'PARTIALLY APPROVED'
export const AWAITING_REVIEW = 'AWAITING REVIEW'
export const PROCESSING = 'PROCESSING'
export const REJECTED = 'REJECTED'
export const RESERVED = 'RESERVED'
export const RETURNED = 'RETURNED'
export const TIMED_OUT = 'TIMED-OUT'
export const SCREENED_OUT = 'SCREENED OUT'
export const UNKNOWN = 'UNKNOWN'

// invoice statuses
export const DRAFT_INVOICE = 'DRAFT'
export const SENT_INVOICE = 'SENT'
export const VIEWED_INVOICE = 'VIEWED'
export const APPROVED_INVOICE = 'APPROVED'
export const PARTIAL_INVOICE = 'PARTIAL'
export const PAID_INVOICE = 'PAID'
export const UNPAID_INVOICE = 'UNPAID'
export const OVERDUE_INVOICE = 'OVERDUE'
export const PENDING_INVOICE = 'PENDING'
export const VOIDED_INVOICE = 'VOID'
export const REFUNDED_INVOICE = 'REFUNDED'

const studyStatuses = {
  ACTIVE,
  COMPLETED,
  DRAFT,
  PAUSED,
  PUBLISHING,
  SCHEDULED,
}

const submissionStatuses = {
  APPROVED,
  AWAITING_REVIEW,
  PROCESSING,
  REJECTED,
  RESERVED,
  RETURNED,
  TIMED_OUT,
  SCREENED_OUT,
  UNKNOWN,
}

const invoiceStatuses = {
  DRAFT_INVOICE,
  SENT_INVOICE,
  VIEWED_INVOICE,
  APPROVED_INVOICE,
  PARTIAL_INVOICE,
  PAID_INVOICE,
  UNPAID_INVOICE,
  OVERDUE_INVOICE,
  PENDING_INVOICE,
  VOIDED_INVOICE,
  REFUNDED_INVOICE,
}

export const all = {
  ...studyStatuses,
  ...submissionStatuses,
  ...invoiceStatuses,
}

/**
 * CASE-INSENSITIVE
 * Checks if a status matches
 * EG: ACTIVE === 'active' = true
 */
export function is(status, matches) {
  if (
    status &&
    typeof status === 'object' &&
    Object.prototype.hasOwnProperty.call(status, 'status')
  ) {
    status = status.status
  }
  return flattenDeep([matches]).reduce((acc, match) => {
    return acc || new RegExp(`^${match}$`, 'i').test(status)
  }, false)
}

export const isStatus = is

// study statuses
export function isActive(status) {
  return is(status, ACTIVE)
}

export function isCompleted(status) {
  return is(status, COMPLETED)
}

export function isDraft(status) {
  return is(status, DRAFT)
}

export function isPaused(status) {
  return is(status, PAUSED)
}

export function isPublished(status) {
  return !is(status, [DRAFT, PUBLISHING, SCHEDULED])
}

export function isPublishing(status) {
  return is(status, PUBLISHING)
}

export function isScheduled(status) {
  return is(status, SCHEDULED)
}

export function isLive(status) {
  return is(status, [ACTIVE, PAUSED, AWAITING_REVIEW])
}

// submission statuses
export function isApproved(status) {
  return is(status, APPROVED)
}

export function isPartiallyApproved(status) {
  return is(status, PARTIALLY_APPROVED)
}

export function isAwaitingReview(status) {
  return is(status, AWAITING_REVIEW)
}

export function isProcessing(status) {
  return is(status, PROCESSING)
}

export function isRejected(status) {
  return is(status, REJECTED)
}

export function isReserved(status) {
  return is(status, RESERVED)
}

export function isReturned(status) {
  return is(status, RETURNED)
}

export function isTimedOut(status) {
  return is(status, TIMED_OUT)
}

export function isScreenedOut(status) {
  return is(status, SCREENED_OUT)
}

export function isUnknown(status) {
  return is(status, UNKNOWN)
}

// invoice statuses
export function isDraftInvoice(status) {
  return is(status, DRAFT_INVOICE)
}

export function isSentInvoice(status) {
  return is(status, SENT_INVOICE)
}

export function isViewedInvoice(status) {
  return is(status, VIEWED_INVOICE)
}

export function isApprovedInvoice(status) {
  return is(status, APPROVED_INVOICE)
}

export function isPartialInvoice(status) {
  return is(status, PARTIAL_INVOICE)
}

export function isPaidInvoice(status) {
  return is(status, PAID_INVOICE)
}

export function isUnpaidInvoice(status) {
  return is(status, UNPAID_INVOICE)
}

export function isOverdueInvoice(status) {
  return is(status, OVERDUE_INVOICE)
}

export function isPendingInvoice(status) {
  return is(status, PENDING_INVOICE)
}

export function isVoidedInvoice(status) {
  return is(status, VOIDED_INVOICE)
}

export function isRefundedInvoice(status) {
  return is(status, REFUNDED_INVOICE)
}
