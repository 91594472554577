import type { Module } from 'vuex'
import { http, endpoints } from '@/api'
import type { Batch, Dataset, UnitOfDataSection } from '@/api/types'
import { DatasetStore } from '@/utils/dataset-store'
import download from '@/utils/download'
import store from '../../index'

export type DraftState = {
  datasetFile: File | null
  batchName: string | null
  instructions: string[]
  datasetPreview: UnitOfDataSection[] | null
}

export type State = {
  hydrated: boolean
  batches: Batch[]
  batch: Batch | null
  datasetId: string | null
  draft: DraftState
}

const SET_BATCHES = 'SET_BATCHES'
const SET_BATCH = 'SET_BATCH'
const SET_DRAFT_STATE = 'SET_DRAFT_STATE'
const SET_DATASET_ID = 'SET_DATASET_ID'
const SET_HYDRATED_STATE = 'SET_HYDRATED_STATE'

export default {
  namespaced: true,

  state: {
    hydrated: false,
    batches: [],
    batch: null,
    datasetId: null,
    draft: {
      batchName: null,
      datasetFile: null,
      instructions: [],
      datasetPreview: null,
    },
  } satisfies State,

  mutations: {
    [SET_BATCHES](state, batches: Batch[]) {
      state.batches = batches.sort(
        (a, b) => Date.parse(b.created_at) - Date.parse(a.created_at)
      )
    },

    [SET_BATCH](state, batch: Batch) {
      state.batch = batch
    },

    [SET_DRAFT_STATE](state, draftState: Partial<DraftState>) {
      state.draft = {
        ...state.draft,
        ...draftState,
      }
    },

    [SET_DATASET_ID](state, datasetId: string) {
      state.datasetId = datasetId
    },

    [SET_HYDRATED_STATE](state, hydratedState: Partial<DraftState> | null) {
      state.draft = {
        ...state.draft,
        ...hydratedState,
      }
      state.hydrated = true
    },
  },

  actions: {
    async fetchBatches({ commit, rootGetters }) {
      const batches = await http.get<{ results: Batch[] }>(
        endpoints.DCTOOL_BATCHES,
        {
          params: {
            workspace_id: rootGetters['researcher/workspaces/workspaceId'],
          },
        }
      )
      commit(SET_BATCHES, batches.results)
    },

    async downloadDatasetExample() {
      const data = await http.get(endpoints.DCTOOL_DATASETS_EXAMPLE)
      download(data, 'task_builder_example_dataset', 'text/csv')
    },

    async createDataset(
      { commit, rootGetters },
      {
        name,
      }: {
        name: string
      }
    ) {
      const dataset = await http.post<Dataset>(endpoints.DCTOOL_DATASETS, {
        workspace_id: rootGetters['researcher/workspaces/workspaceId'],
        name,
      })
      commit(SET_DATASET_ID, dataset.id)
    },

    async uploadDataset(
      _,
      {
        datasetId,
        file,
      }: {
        datasetId: string
        file: File
      }
    ) {
      await http.post(
        endpoints.DCTOOL_DATASET_UPLOAD(datasetId),
        {
          files: [file],
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
    },

    async createBatch(
      { commit, rootGetters },
      {
        name,
        datasetId,
      }: {
        name: string
        datasetId: string
      }
    ) {
      const batch = await http.post<Dataset>(endpoints.DCTOOL_BATCHES, {
        workspace_id: rootGetters['researcher/workspaces/workspaceId'],
        name,
        dataset_id: datasetId,
      })
      commit(SET_BATCH, batch)
    },

    async persistDraftStateLocally(
      { commit, rootGetters },
      {
        batchName,
        file,
        preview,
      }: {
        batchName: string
        file: File
        preview: UnitOfDataSection[]
      }
    ) {
      const userId = rootGetters['auth/userId']
      const store = new DatasetStore()
      await store.set(userId, { file, preview, batchName })

      commit(SET_DRAFT_STATE, {
        batchName,
        datasetFile: file,
        datasetPreview: preview,
      })
    },

    async clearDraft({ commit, rootGetters }) {
      const userId = rootGetters['auth/userId']
      const store = new DatasetStore()
      await store.delete(userId)

      commit(SET_DRAFT_STATE, {
        batchName: null,
        datasetFile: null,
        instructions: [],
        datasetPreview: null,
      })
    },

    async hydrateState({ commit, rootGetters }) {
      const userId = rootGetters['auth/userId']

      if (!userId) {
        return
      }
      const store = new DatasetStore()
      const localDataset = await store.get(userId)

      commit(SET_HYDRATED_STATE, {
        batchName: localDataset?.batchName ?? null,
        datasetFile: localDataset?.file ?? null,
        datasetPreview: localDataset?.preview ?? null,
      })
    },

    saveBatch() {
      // Implementation pending
    },
  },

  getters: {
    batches: ({ batches }) => batches,
    batch: ({ batch }) => batch,
    batchName: state => {
      if (!state.draft.batchName && !state.hydrated) {
        store.dispatch('researcher/aiTaskBuilder/hydrateState')
      }
      return state.draft.batchName
    },
    instructions: state => {
      return state.draft.instructions
    },
    batchId: ({ batch }) => batch?.id,
    datasetId: ({ datasetId }) => datasetId,
    datasetFile: state => {
      if (!state.draft.datasetFile && !state.hydrated) {
        store.dispatch('researcher/aiTaskBuilder/hydrateState')
      }
      return state.draft.datasetFile
    },
    datasetPreview: state => {
      if (!state.draft.datasetPreview && !state.hydrated) {
        store.dispatch('researcher/aiTaskBuilder/hydrateState')
      }
      return state.draft.datasetPreview
    },
  },
} satisfies Module<State, unknown>
