import { chainValidators, createValidator } from '@/utils/validators'

// regex checks
// based on: https://docs.hyperwallet.com/content/api/v4/resources/users/create
// checks if string contains just special characters e.g. '###'
const isOnlySpecialCharacters = val => /^[^a-zA-Z0-9]+$/.test(val || '')

// checks if string contains just numeric characters e.g. '123'
const isOnlyNumbers = val => /^[0-9]+$/.test(val || '')

// Checks if a string contains any special characters (excluding spaces)
const hasSpecialCharacters = str => /[^a-zA-Z0-9\s]/.test(str)

// Checks if a string contains only spaces
const hasOnlySpaces = val => /^\s+$/.test(val || '')

// checks if string contains at least one word with a minimum length of 3 characters e.g. 'Abc'
const isContainingFullWord = val => /[a-zA-Z]{3,}/.test(val || '')

// Checks if a string contains name characters
const hasValidNameCharacters = val => /^[A-Za-z ',.-]+$/.test(val || '')

// Checks if a string does not contain only valid name special characters
const hasOnlyValidNameSpecialCharacters = val => /^[',\-.]+$/.test(val || '')

// checks if string is a valid address line, allowing specific special characters and symbols e.g. '120 Bremner Blvd.'
const isAddressLine = val => /^[a-zA-Z0-9\s#'() ,./:;°-]*$/.test(val || '')

// checks if string is a valid city name, allowing specific special characters and symbols e.g. 'Toronto'
const isCity = val => /^[a-zA-Z&'().\-\s]+$/.test(val || '')

// checks if string is a valid state or province name, allowing specific special characters and symbols e.g. 'Ontario'
const isStateProvince = val => /^[a-zA-Z&'().\-\s]+$/.test(val || '')

// checks if string is a valid postal code, allowing alphanumeric characters and hyphens e.g. 'M5V 3L9'
const isPostalCode = val => /^[a-zA-Z0-9\s-]*$/.test(val || '')

const isGBPostalCode = val =>
  /^([A-Z][A-HJ-Y]?\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/.test(val || '')

// Checks if string is a valid Canadian postal code, allowing alphanumeric characters and hyphens e.g. 'M5V 3L9'
const isCanadianPostalCode = val =>
  /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(val || '')

// checks if string is a valid US zip code, allowing both 5-digit and 9-digit formats e.g. '12345' or '12345-6789'
const isUSZipCode = val => /^\d{5}(?:[-\s]\d{4})?$/.test(val || '')

/**
 ### Form input validators
 */
export const notOnlySpecialCharacters = createValidator((val, name) => [
  val && !isOnlySpecialCharacters(val),
  'Please enter more than just special characters.',
  `${name} must be a valid address input`,
])

export const notOnlyNumbers = createValidator((val, name) => [
  val && !isOnlyNumbers(val),
  'Please enter more than just numbers.',
  `${name} must be a valid address input`,
])

export const notOnlySpaces = createValidator((val, name) => [
  val && !hasOnlySpaces(val),
  'Please enter more than just spaces.',
  `${name} must be a valid input`,
])

export const containsFullWord = createValidator((val, name) => [
  val && isContainingFullWord(val),
  'Please complete this field fully.',
  `${name} must be a valid address input`,
])

export const notOnlyValidNameSpecialCharacters = createValidator(
  (val, name) => [
    val && !hasOnlyValidNameSpecialCharacters(val),
    'Please enter more than just valid special characters.',
    `${name} must be a valid input`,
  ]
)

export const participantName = chainValidators(
  notOnlySpaces,
  createValidator((val, name) => [
    hasValidNameCharacters(val || ''),
    "Please enter a valid name. Letters, spaces, and the following characters are allowed: ',-.",
    `${name} must be valid`,
  ]),
  notOnlyValidNameSpecialCharacters
)

export const addressLine = chainValidators(
  notOnlySpecialCharacters,
  notOnlyNumbers,
  containsFullWord,
  createValidator((val, name) => [
    val && isAddressLine(val),
    "Please only enter valid characters. Letters, spaces, and the following symbols are allowed: #'(),./:;°-",
    `${name} must be a valid address input`,
  ])
)

export const addressLine2 = createValidator((val, name) => {
  let isValid = true
  let message = null

  // have to do it this way because field is optional
  if (isInvalidInput(val)) {
    isValid = true
  } else if (!isAddressLine(val)) {
    isValid = false
    message =
      "Please only enter valid characters. Letters, spaces, and the following symbols are allowed: #'(),./:;°-"
  } else if (isOnlySpecialCharacters(val)) {
    isValid = false
    message = 'Please enter more than just special characters.'
  } else if (!isContainingFullWord(val)) {
    isValid = false
    message = 'Please provide a complete address.'
  }
  return [isValid, message, `${name} must be a valid address input`]
})

export const city = chainValidators(
  notOnlySpecialCharacters,
  containsFullWord,
  createValidator((val, name) => [
    val && isCity(val),
    "Please only enter valid characters. Letters, spaces, and the following symbols are allowed: &'()-.",
    `${name} must be a valid city input`,
  ])
)

export const stateProvince = chainValidators(
  notOnlySpecialCharacters,
  containsFullWord,
  createValidator((val, name) => [
    val && isStateProvince(val),
    "Please only enter valid characters. Letters, spaces, and the following symbols are allowed: &'()-.",
    `${name} must be a valid address input`,
  ])
)

export const postalCode = chainValidators(
  createValidator((val, name) => [
    // minimum length is based on the codes here: https://en.wikipedia.org/wiki/List_of_postal_codes
    val && isPostalCode(val) && val.length >= 2,
    'Please only enter valid characters. Letters, numbers, and the following symbols are allowed: -',
    `${name} must be a valid address input`,
  ])
)

export const USZipCode = createValidator((val, name) => [
  val && isUSZipCode(val),
  'Please enter a valid US ZIP code.',
  `${name} must be a valid address input`,
])

/**
 ### General validators for validation without form inputs
 */
export const isInvalidInput = val => !val || val === ''

export const isValidName = name => {
  return (
    !isInvalidInput(name) &&
    !hasOnlySpaces(name) &&
    !hasOnlyValidNameSpecialCharacters(name) &&
    hasValidNameCharacters(name)
  )
}

export const isValidAddressLine = val => {
  return (
    !isInvalidInput(val) && !isOnlySpecialCharacters(val) && val.length >= 3
  )
}

export const isValidAddressLine2 = val => {
  return !isOnlySpecialCharacters(val) && !isOnlyNumbers(val)
}

export const isValidCity = val => {
  return (
    !isInvalidInput(val) &&
    !isOnlySpecialCharacters(val) &&
    !isOnlyNumbers(val) &&
    isCity(val) &&
    val.length <= 50
  )
}

export const isValidStateProvince = val => {
  return (
    !isInvalidInput(val) &&
    !isOnlySpecialCharacters(val) &&
    !isOnlyNumbers(val) &&
    !hasSpecialCharacters(val) &&
    isStateProvince(val) &&
    val.length >= 2 &&
    val.length <= 50
  )
}

export const isValidPostalCode = (country, val) => {
  if (
    !isInvalidInput(val) &&
    !isOnlySpecialCharacters(val) &&
    val.length <= 16
  ) {
    const formattedCountry = country.toLowerCase()

    if (
      formattedCountry === 'united states of america' ||
      formattedCountry === 'united states'
    ) {
      return isUSZipCode(val)
    } else if (formattedCountry === 'united kingdom') {
      return isGBPostalCode(val)
    } else if (formattedCountry === 'canada') {
      return isCanadianPostalCode(val)
    } else {
      return isPostalCode(val)
    }
  }

  return false
}
