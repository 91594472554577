import axios, {
  type AxiosInstance,
  type AxiosResponse,
  isAxiosError,
} from 'axios'
import endpoints from '@/api/endpoints'
import type {
  Credential,
  CredentialPool,
  CredentialPoolIdResponse,
} from '@/api/types/credentials/Credential'

enum Method {
  GET = 'get',
  POST = 'post',
  DELETE = 'delete',
  PATCH = 'patch',
}

export class CredentialsServiceError extends Error {
  errorType:
    | 'AUTHORIZATION_ERROR'
    | 'NETWORK_ERROR'
    | 'POOL_CONTAINS_DUPLICATES_ERROR'
    | 'REQUEST_VALIDATION_ERROR'
    | 'UNKNOWN_ERROR'

  /**
   * Maps a HTTP status code returned from the Credentials service into one of a union of strings.
   */
  static _mapApiStatusCodeToErrorType(
    status?: number
  ): CredentialsServiceError['errorType'] {
    switch (status) {
      case 0:
        return 'NETWORK_ERROR'
      case 400:
        return 'REQUEST_VALIDATION_ERROR'
      case 401:
      case 403:
        return 'AUTHORIZATION_ERROR'
      case 409:
        return 'POOL_CONTAINS_DUPLICATES_ERROR'
      default:
        return 'UNKNOWN_ERROR'
    }
  }

  /**
   * Constructs a consistent error message format for the CredentialsServiceError class.
   * Takes an optional AxiosResponse argument that is used to construct the message if available.
   */
  static _getErrorMessage(response?: AxiosResponse) {
    return response
      ? `Credentials Service responded with status ${response.status}: ${response.data}`
      : 'Network error on request to Credentials Service'
  }

  constructor(msg: string, status?: number) {
    super(msg)
    this.errorType =
      CredentialsServiceError._mapApiStatusCodeToErrorType(status)
  }
}

/**
 * A client class for interacting with the credentials API.
 * This client handles CRUD operations related to study credentials.
 */
export class CredentialsClient {
  private http: AxiosInstance

  /**
   * Initializes a new instance of the Client class.
   * Sets up the axios instance with required headers and base URL.
   */
  constructor(token: string) {
    this.http = axios.create({
      baseURL: import.meta.env['VUE_APP_CREDENTIALS_API'],
      headers: {
        Authorization: `Bearer ${token}`,
        'x-api-key': import.meta.env['VUE_APP_STUDY_CREDENTIALS_API_KEY'],
      },
    })
  }

  /**
   * Sends an HTTP request.
   * @param {Method} method - The HTTP method to use.
   * @param {string} studyId - The study ID.
   * @param {any} [data] - The data to be sent in the request body (optional).
   * @param {string} url - The url to be sent to (optional).
   * @returns {Promise} Resolves with the server's response or rejects with an error.
   */
  private async request<T extends object>(
    method: Method,
    studyId: string,
    data?: T,
    url?: string
  ): Promise<AxiosResponse> {
    url = url ?? endpoints.STUDY_CREDENTIALS(studyId)

    return await this.http[method](url, data).catch(error =>
      this.handleError(error)
    )
  }

  /**
   * Handles errors that occur during an HTTP request.
   * @param {any} error - The error object.
   * @throws {CredentialsServiceError} Throws an error with a description based on the error type.
   */
  private handleError(error: unknown): never {
    if (!isAxiosError(error)) {
      throw new CredentialsServiceError('An unknown error occurred.')
    }

    throw new CredentialsServiceError(
      CredentialsServiceError._getErrorMessage(error.response),
      error.response?.status
    )
  }

  /**
   * Retrieves the credentials for a credential pool by ID.
   * @param {string} credentialPoolId - The credential pool ID.
   * @returns {Promise} Resolves with the server's response of type CredentialList or rejects with an error.
   */
  async getCredentialPool(credentialPoolId: string): Promise<CredentialPool> {
    return (
      await this.request(
        Method.GET,
        credentialPoolId,
        undefined,
        `${endpoints.CREDENTIAL_POOLS(credentialPoolId)}`
      )
    )?.data
  }

  /**
   * Deletes the credentials for a given credential pool ID.
   * @param {string} credentialPoolId - The pool ID.
   * @returns {Promise<void>} Does not return a value, rejects with an error if .
   */
  async deleteCredentialPool(credentialPoolId: string): Promise<void> {
    await this.request(
      Method.DELETE,
      credentialPoolId,
      undefined,
      `${endpoints.CREDENTIAL_POOLS(credentialPoolId)}`
    )
  }

  /**
   * Creates a new credential pool
   * @param {string} credentials - The credentials to create.
   * @param {string} workspaceId - The id of the workspace the credential pool is created in.
   * @returns {Promise} Resolves with the server's response  or rejects with an error.
   */
  async createCredentialPool(
    credentials: string,
    workspaceId: string
  ): Promise<CredentialPoolIdResponse> {
    return (
      await this.request(
        Method.POST,
        '',
        {
          credentials,
          workspaceId,
        },
        '/credentials'
      )
    )?.data
  }

  /**
   * Updates credentials for a given credential pool
   * @param {string} credentialPoolId - The credential pool ID.
   * @param {string} credentials - The credentials to update.
   * @returns {Promise} Resolves with the server's response of type CredentialPoolIdResponse or rejects with an error.
   */
  async updateCredentialPool(
    credentialPoolId: string,
    credentials: string
  ): Promise<CredentialPoolIdResponse> {
    return (
      await this.request(
        Method.PATCH,
        credentialPoolId,
        { credentials },
        `${endpoints.CREDENTIAL_POOLS(credentialPoolId)}`
      )
    )?.data
  }

  /**
   * Redeems credentials for a given credential pool ID and submission ID.
   *
   * @todo
   * For backwards compatibility with Study Credentials implementation this
   * just returns the `authenticationDetails` object of the credential,
   * which is where username and password can be found, but we probably want to
   * facilitate returning the entire credential when we remove the old Credsv1 stuff.
   *
   * @param {string} credentialPoolId - The credential pool ID.
   * @param {string} submissionId - The submission ID.
   * @returns {Promise} Resolves with the server's response of type Credential or rejects with an error.
   */
  async redeemCredentialFromPool(
    credentialPoolId: string,
    submissionId: string
  ): Promise<Credential['authenticationDetails']> {
    return (
      await this.request(
        Method.PATCH,
        credentialPoolId,
        { submissionId },
        `${endpoints.CREDENTIAL_POOLS(credentialPoolId)}/redeem`
      )
    )?.data?.authenticationDetails
  }

  /**
   * Retrieves a CSV string of the credential usage for a given study
   *
   * @param credentialPoolId - The ID of the credential pool.
   * @returns {Promise} Resolves with a CSV-formatted string containing a report of the credential usage for the study.
   */
  async getReportFromPool(credentialPoolId: string): Promise<string> {
    return (
      await this.request(
        Method.GET,
        credentialPoolId,
        undefined,
        `/credentials/${credentialPoolId}/report`
      )
    ).data
  }
}
