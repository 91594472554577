import type { Module } from 'vuex'
import { http, endpoints } from '@/api'

export enum CashoutErrors {
  PARTICIPANT_CASHOUT_EMAIL_NOT_SET = 'PARTICIPANT_CASHOUT_EMAIL_NOT_SET',
  PARTICIPANT_CASHOUT_EMAIL_NOT_VERIFIED = 'PARTICIPANT_CASHOUT_EMAIL_NOT_VERIFIED',
  PARTICIPANT_CASHOUT_ACCOUNT_UNVERIFIED = 'PARTICIPANT_CASHOUT_ACCOUNT_UNVERIFIED',
  PARTICIPANT_CASHOUT_ADDRESS_NOT_SET = 'PARTICIPANT_CASHOUT_ADDRESS_NOT_SET',
  PARTICIPANT_CASHOUT_DOB_NOT_SET = 'PARTICIPANT_CASHOUT_DOB_NOT_SET',
  PARTICIPANT_CASHOUT_MIN_BALANCE_NOT_MET = 'PARTICIPANT_CASHOUT_MIN_BALANCE_NOT_MET',
  PARTICIPANT_IN_CASHOUT_COOL_OFF = 'PARTICIPANT_IN_CASHOUT_COOL_OFF',
  PARTICIPANT_HAS_UNCLAIMED_PAYMENT = 'PARTICIPANT_HAS_UNCLAIMED_PAYMENT',
  PARTICIPANT_HAS_CASHOUT_PENDING = 'PARTICIPANT_HAS_CASHOUT_PENDING',
  PARTICIPANT_INVALID_CASHOUT_ADDRESS_FOR_NO_STATUS_PAYEE = 'PARTICIPANT_INVALID_CASHOUT_ADDRESS_FOR_NO_STATUS_PAYEE',
  PARTICIPANT_TRANSFER_METHODS_NOT_FOUND = 'PARTICIPANT_TRANSFER_METHODS_NOT_FOUND',
  PARTICIPANT_FROZEN_PAYEE_STATUS = 'PARTICIPANT_FROZEN_PAYEE_STATUS',
  PARTICIPANT_PAYEE_NO_STATUS = 'PARTICIPANT_PAYEE_NO_STATUS',
  PARTICIPANT_CASHOUT_DISABLED_INTERNAL_ERROR = 'PARTICIPANT_CASHOUT_DISABLED_INTERNAL_ERROR',
  PARTICIPANT_DE_ACTIVATED_PAYEE_STATUS = 'PARTICIPANT_DE_ACTIVATED_PAYEE_STATUS',
}

export type State = {
  cashoutStatus: CashoutErrors[]
}

export const types = {
  SET_CASHOUT_STATUS: 'SET_CASHOUT_STATUS',
}

export default {
  namespaced: true,

  state: {
    cashoutStatus: [],
  } as State,

  mutations: {
    [types.SET_CASHOUT_STATUS](state: State, cashoutStatus: CashoutErrors[]) {
      state.cashoutStatus = cashoutStatus
    },
  },

  actions: {
    async fetchCashoutStatus({ commit }, userId: string) {
      try {
        const response: { cashout_errors: CashoutErrors[] } = await http.get(
          endpoints.payments.PARTICIPANT_CASHOUT_STATUS(userId)
        )
        commit(types.SET_CASHOUT_STATUS, response?.cashout_errors || [])
      } catch (error) {
        Promise.reject(error)
      }
    },
  },

  getters: {
    cashoutStatus: (state: State) => state.cashoutStatus,
  },
} satisfies Module<State, unknown>
