import { computed } from 'vue'
import { type RouteLocationNormalizedLoaded } from 'vue-router'
import { trackStructEvent } from '@/integrations/snowplow'
import router from '@/router'
import store from '@/store'
import { Action, Category, Label, Property } from './constants'

const getUserType = (): 'Researcher' | 'Participant' => {
  const user = computed(() => store.getters['auth/user'])
  return user.value?.user_type
}

const isFromSettings = (route: RouteLocationNormalizedLoaded): boolean => {
  return route?.path.includes('general') ?? false
}

interface TrackEventParams {
  category: Category
  label: Label
  property: Property
}

const trackEvent = ({ category, label, property }: TrackEventParams): void => {
  trackStructEvent({
    category,
    action: Action.CLICK,
    label,
    property,
  })
}

export const trackBannerEvent = (
  label: Label.MORE_INFO | Label.CLOSE_BANNER
): void => {
  const userType = getUserType()
  const category =
    userType === 'Researcher'
      ? Category.RESEARCHER_MFA_BANNER
      : Category.PARTICIPANT_MFA_BANNER

  trackEvent({ category, label, property: Property.MFA_BANNER })
}

export const trackModal = (
  label:
    | Label.TURN_ON_BUTTON
    | Label.ENABLE_BUTTON
    | Label.CANCEL_BUTTON
    | Label.CLOSE_MODAL,
  isRegistration = false
): void => {
  const currentRoute = router.currentRoute.value

  const isSettings = isFromSettings(currentRoute)
  const userType = getUserType()

  if (isRegistration && label === Label.ENABLE_BUTTON) {
    trackSignUp(label)
  } else {
    const categoryMap = {
      Researcher: {
        settings: Category.RESEARCHER_ACCOUNT_SETTINGS,
        banner: Category.RESEARCHER_MFA_BANNER,
      },
      Participant: {
        settings: Category.PARTICIPANT_ACCOUNT_SETTINGS,
        banner: Category.PARTICIPANT_MFA_BANNER,
      },
    }

    const settingsType = isSettings ? 'settings' : 'banner'
    const category =
      categoryMap[userType]?.[settingsType] ?? Category.PARTICIPANT_MFA_BANNER

    const property =
      label === Label.TURN_ON_BUTTON
        ? Property.SECURITY_MFA
        : Property.MFA_MODAL

    trackEvent({ category, label, property })
  }
}

export const trackSignUp = (
  label: Label.ENABLE_BUTTON | Label.SKIP_BUTTON
): void => {
  const userType = getUserType()
  const category =
    userType === 'Researcher'
      ? Category.RESEARCHER_REGISTER
      : Category.PARTICIPANT_REGISTER

  trackEvent({ category, label, property: Property.PROTECT_ACCOUNT_MFA })
}

export const trackMfaRemoval = (
  label:
    | Label.REMOVE_MFA_BUTTON
    | Label.CANCEL_BUTTON
    | Label.CLOSE_MODAL
    | Label.REMOVE_BUTTON
): void => {
  const userType = getUserType()
  const category =
    userType === 'Researcher'
      ? Category.RESEARCHER_ACCOUNT_SETTINGS
      : Category.PARTICIPANT_ACCOUNT_SETTINGS

  const property =
    label === Label.REMOVE_BUTTON ? Property.SECURITY_MFA : Property.MFA_MODAL

  trackEvent({ category, label, property })
}
