export class DecryptionError extends Error {
  override name = 'DecryptionError'
}
export class EncryptionError extends Error {
  override name = 'EncryptionError'
}

/**
 * Encrypts a string value using AES-GCM encryption
 * @param key - The key to use for encryption
 * @param contents - The string to encrypt
 * @returns Promise<string> - Base64 encoded encrypted string with IV prepended
 */
export async function encrypt(key: string, contents: string): Promise<string> {
  try {
    if (!key) {
      throw new Error('Encryption requires a key')
    }

    const keyBuffer = await crypto.subtle.digest(
      'SHA-256',
      new TextEncoder().encode(key)
    )

    const cryptoKey = await crypto.subtle.importKey(
      'raw',
      keyBuffer,
      'AES-GCM',
      false,
      ['encrypt']
    )

    const iv = crypto.getRandomValues(new Uint8Array(12))

    const encodedContent = new TextEncoder().encode(contents)
    const encryptedContent = await crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        iv,
      },
      cryptoKey,
      encodedContent
    )

    const resultBuffer = new Uint8Array(iv.length + encryptedContent.byteLength)
    resultBuffer.set(iv, 0)
    resultBuffer.set(new Uint8Array(encryptedContent), iv.length)

    return btoa(
      new Uint8Array(resultBuffer).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ''
      )
    )
  } catch (error) {
    throw new EncryptionError(
      `Encryption failed: ${
        error instanceof Error ? error.message : 'Unknown error'
      }`
    )
  }
}

/**
 * Decrypts an AES-GCM encrypted string
 * @param key - The key to use for encryption
 * @param encryptedData - A string to be decrypted
 * @returns Promise<string> - The decrypted string
 */
export async function decrypt(
  key: string,
  encryptedData: string
): Promise<string> {
  try {
    const keyBuffer = await crypto.subtle.digest(
      'SHA-256',
      new TextEncoder().encode(key)
    )

    const cryptoKey = await crypto.subtle.importKey(
      'raw',
      keyBuffer,
      'AES-GCM',
      false,
      ['decrypt']
    )

    const encryptedArray = new Uint8Array(
      atob(encryptedData)
        .split('')
        .map(char => char.charCodeAt(0))
    )

    const iv = encryptedArray.slice(0, 12)
    const encryptedContent = encryptedArray.slice(12)

    const decryptedContent = await crypto.subtle.decrypt(
      {
        name: 'AES-GCM',
        iv,
      },
      cryptoKey,
      encryptedContent
    )

    return new TextDecoder().decode(decryptedContent)
  } catch (error) {
    throw new DecryptionError(
      `Decryption failed: ${
        error instanceof Error ? error.message : 'Unknown error'
      }`
    )
  }
}
