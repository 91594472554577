import { http, endpoints } from '@/api'
import rollbar from '@/integrations/rollbar'
import { prepareSurveyResponseForPost } from '@/utils/survey'

export const types = {
  SET_SURVEY: 'SET_SURVEY',
}

const state = {
  survey: {},
}

const mutations = {
  [types.SET_SURVEY](state, survey) {
    state.survey = survey
  },
}

const actions = {
  async fetch({ commit }, id) {
    try {
      const data = await http.get(endpoints.SURVEY(id))

      const {
        date_created,
        date_modified,
        is_deleted,
        researcher_id,
        ...partsOfSurveyWeCareAbout
      } = data
      commit(types.SET_SURVEY, partsOfSurveyWeCareAbout)
      return partsOfSurveyWeCareAbout
    } catch (err) {
      rollbar.error(`Error while fetching short survey ${id}: ${err}`, err)
      throw err
    }
  },

  reset({ commit }) {
    commit(types.SET_SURVEY, {})
  },

  submitResponse({ getters, rootGetters }, formData) {
    const participantId = rootGetters['auth/currentUser'].id
    const submissionId = rootGetters['participant/submissions/submission'].id
    const survey = getters.survey
    const response = prepareSurveyResponseForPost(
      formData,
      survey,
      submissionId,
      participantId
    )

    return http.post(endpoints.SURVEY_RESPONSES(survey._id), response)
  },
}

const getters = {
  survey: ({ survey }) => survey,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
