import axios from 'axios'
import configureInterceptors from './interceptors'

let authHttpClient

export const getAuthHttpClient = token => {
  if (!authHttpClient) {
    authHttpClient = axios.create({
      baseURL: import.meta.env['VUE_APP_NEW_AUTH_API'],
    })

    configureInterceptors(authHttpClient, false)
  }

  if (token) {
    authHttpClient.defaults.headers.common.Authorization = `Bearer ${token}`
  }

  return authHttpClient
}
