/// <reference types="pendo-io-browser" />
import { createProxy } from './helpers/createProxy'

const pendo = createProxy<pendo.Pendo>({ name: 'pendo' })

// TODO: type from api
type User = {
  id: string
  user_type: 'researcher' | 'participant'
  currency_code: 'GBP' | 'USD'
  country: string
  date_joined: string
  experimental_group: number
}

export const initialisePendo = (user: User) => {
  const {
    id,
    user_type,
    currency_code,
    country,
    date_joined,
    experimental_group,
  } = user

  if (id) {
    pendo.initialize({
      visitor: {
        id,
        user_type: user_type && user_type.toLowerCase(),
        currency_code,
        country,
        date_joined,
        experimental_group,
      },
      guides: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore the type is wrong
        disabled: import.meta.env['VUE_APP_MODE'] !== 'production',
      },
    })
  }
}

export default pendo
