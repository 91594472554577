import { createRouter as newCreateRouter, createWebHistory } from 'vue-router'
import AuthenticatedLayout from '@/components/layout/Authenticated.vue'
import store from '@/store'
import { beforeEach, afterEach } from './hooks'
import routes from './routes'
import messages from './routes/messages'
import participant from './routes/participant'
import researcher from './routes/researcher'

const createRouter = () =>
  newCreateRouter({
    history: createWebHistory(),
    routes,
  })

const router = createRouter()

if (import.meta.env.NODE_ENV !== 'test') {
  router.beforeEach(beforeEach)
  router.afterEach(afterEach)
}

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

// used in unit tests & here
export function getUserTypeSpecificRoutes(userType) {
  return [
    {
      path: '/',
      component: AuthenticatedLayout,
      props: { userType },
      children: [
        ...messages,
        ...(userType === 'researcher' ? researcher : participant),
      ],
      async beforeEnter(to, from, next) {
        if (userType === 'researcher') {
          await handleSetResearcherData()
        }

        next()
      },
    },
  ]
}

export function addUserTypeSpecificRoutes(userType) {
  getUserTypeSpecificRoutes(userType).forEach(item => router.addRoute(item))
}

export async function handleSetResearcherData() {
  const requiredDataPromises = [
    store.dispatch('researcher/workspaces/fetchWorkspaces', '?limit=50'),
    store.dispatch('researcher/studies/fetchHasStudies'),
  ]

  const activeWorkspaceId = store.getters['researcher/workspaces/workspace']?.id

  if (activeWorkspaceId) {
    // no need to fetch a single workspace its already in state
    await Promise.all(requiredDataPromises)
    return
  }

  const currentProjectId = store.getters['auth/user']?.current_project_id

  if (currentProjectId) {
    await Promise.all([
      ...requiredDataPromises,
      store.dispatch(
        'researcher/workspaces/fetchCurrentWorkspaceByProjectId',
        currentProjectId
      ),
    ])

    return
  }

  await Promise.all(requiredDataPromises)

  const workspaces = store.getters['researcher/workspaces/workspaces']
  const latestWorkspaceId = workspaces?.[workspaces.length - 1]?.id

  if (latestWorkspaceId) {
    await store.dispatch(
      'researcher/workspaces/fetchWorkspace',
      latestWorkspaceId
    )
  }

  return
}

export default router
