import { isEmpty } from 'lodash'
import store from '@/store'
import notifier from '@/utils/notifier'

/**
 * SIDENOTE: once this is unflagged, we may be able to reduce some duplication
 * of the path prefix by setting most (if not all) of these as child routes of ./finance?
 */
const featureFlaggedRoutes = [
  {
    path: 'researcher/workspaces/:workspaceId/finance/add-money',
    name: 'workspaces.finance.add_money',
    component: () =>
      import('@/views/researcher/workspaces/finance/AddMoney.vue'),
  },
  {
    path: 'researcher/workspaces/:workspaceId/finance/add-money/credit-card',
    name: 'workspaces.finance.credit_card',
    component: () =>
      import('@/views/researcher/workspaces/finance/CreditCard.vue'),
  },
  {
    path: 'researcher/workspaces/:workspaceId/finance/add-money/credit-card/receipt',
    alias: [
      'researcher/workspaces/:workspaceId/finance/invoices/request/receipt',
      'researcher/workspaces/:workspaceId/finance/add-money/paypal/receipt',
    ],
    name: 'workspaces.finance.receipt',
    component: () =>
      import('@/views/researcher/workspaces/finance/Receipt.vue'),
    props: route => {
      return {
        default: true,
        amount: route.meta.amount,
        invoice: route.meta.invoice,
      }
    },
    beforeEnter(to, from, next) {
      /**
       * This will be set on the previous route before navigating, can't seem to
       * pass meta properties in $router.push
       */
      const { amount, invoice } = from.meta

      if (!amount) {
        return next({
          name: invoice
            ? 'workspaces.finance.request_invoice'
            : 'workspaces.finance.credit_card',
          params: { workspaceId: to.params.workspaceId },
        })
      }

      /**
       * Set on the "to" route so that the values can be accessed in the `props`
       * function and passed as props to the component
       */
      to.meta.amount = amount
      to.meta.invoice = invoice

      next()
    },
  },
  {
    path: 'researcher/workspaces/:workspaceId/finance/add-money/bank-transfer',
    name: 'workspaces.finance.bank_transfer',
    component: () =>
      import('@/views/researcher/workspaces/finance/BankTransfer.vue'),
  },
  {
    path: 'researcher/workspaces/:workspaceId/finance/add-money/bank-transfer/next-steps',
    name: 'workspaces.finance.next_steps',
    component: () =>
      import('@/views/researcher/workspaces/finance/NextSteps.vue'),
    props: route => {
      return {
        default: true,
        bankTransferDetails: route.meta.bankTransferDetails,
        decimalPlaces: route.meta.decimalPlaces,
      }
    },
    beforeEnter(to, from, next) {
      /**
       * This will be set on the previous route before navigating, can't seem to
       * pass meta properties in $router.push
       */
      const { bankTransferDetails, decimalPlaces } = from.meta

      if (!bankTransferDetails) {
        return next({
          name: 'workspaces.finance.bank_transfer',
          params: { workspaceId: to.params.workspaceId },
        })
      }

      /**
       * Set on the "to" route so that the values can be accessed in the `props`
       * function and passed as props to the component
       */
      to.meta.bankTransferDetails = bankTransferDetails
      to.meta.decimalPlaces = decimalPlaces

      next()
    },
  },
  {
    path: 'researcher/workspaces/:workspaceId/finance/invoices/request',
    name: 'workspaces.finance.request_invoice',
    component: () =>
      import('@/views/researcher/workspaces/finance/RequestInvoice.vue'),
  },
  {
    path: 'researcher/workspaces/:workspaceId/finance/invoices/request/sent',
    name: 'workspaces.finance.invoice_sent',
    component: () =>
      import('@/views/researcher/workspaces/finance/InvoiceSent.vue'),
  },
  {
    path: 'researcher/workspaces/:workspaceId/finance/invoices/:id/pay',
    name: 'workspaces.finance.pay_invoice',
    async beforeEnter(to, from, next) {
      try {
        await store.dispatch('researcher/workspaces/fetchQuote', to.params.id)
        const quote = store.getters['researcher/workspaces/quote']
        const walletId = store.getters['researcher/workspaces/walletId']
        if (quote.wallet !== walletId) {
          await store.dispatch(
            'researcher/workspaces/fetchWallet',
            quote.wallet
          )
        }
        next()
      } catch (error) {
        const errorMessage =
          'We’re working on a solution. Contact support if you have any questions.'
        notifier.error(errorMessage, {
          title: 'Problem loading invoice',
          duration: 0,
        })

        const previousRoute = from.name
          ? from.name
          : 'workspaces.finance.invoices'
        next({ name: previousRoute })
      }
    },
    component: () =>
      import('@/views/researcher/workspaces/finance/PayInvoice.vue'),
  },
  {
    path: 'researcher/workspaces/:workspaceId/finance/invoices/:id/pay/credit-card',
    name: 'workspaces.finance.pay_invoice.credit_card',
    component: () =>
      import('@/views/researcher/workspaces/finance/CreditCard.vue'),
    props: route => {
      return {
        default: true,
        quote: route.meta.quote,
      }
    },
    beforeEnter(to, from, next) {
      /**
       * This will be set on the previous route before navigating, can't seem to
       * pass meta properties in $router.push
       */
      const { quote } = from.meta

      /**
       * Set on the "to" route so that the values can be accessed in the `props`
       * function and passed as props to the component
       */
      to.meta.quote = quote

      next()
    },
  },
]

featureFlaggedRoutes.forEach(
  route => (route.meta = { paymentsFeatureFlag: true })
)

const routes = [
  {
    path: 'researcher/workspaces/:workspaceId/finance/invoices',
    name: 'workspaces.finance.invoices',
    component: () => import('@/views/researcher/workspaces/Invoices.vue'),
  },
  // Redirect from /pay-invoice/:id => /researcher/workspaces/finance/pay-invoice/:id
  {
    path: 'pay-invoice/:id',
    name: 'workspaces.redirect.finance.pay_invoice',
    beforeEnter(to, from, next) {
      const workspaceId =
        store.getters['researcher/workspaces/workspaces']?.[0].id
      from.meta.noRefetch = true
      next({
        name: 'workspaces.finance.pay_invoice',
        params: {
          workspaceId,
          id: to.params.id,
        },
      })
    },
  },
]

const redirectToFinancesIfNoAccess = (_to, _from, next) => {
  const isUserEmailVerified = store.getters['auth/isEmailVerified']
  const isUserPendingApproval = store.getters['auth/isPendingApproval']
  if (!isUserEmailVerified || isUserPendingApproval) {
    return next({
      name: 'workspaces.finance',
    })
  } else {
    return next()
  }
}

export const addFnsBeforeEnter = (routes, fns) => {
  routes.forEach(route => {
    const originalBeforeEnter = route.beforeEnter
    route.beforeEnter = [
      ...fns,
      ...(originalBeforeEnter ? [originalBeforeEnter] : []),
    ]
    return route
  })
  return routes
}

// TODO: remove after all researcher wallets have been migrated to new payments flow
const redirectToFeatureUnderDevelopmentIfNoAccess = async (
  _to,
  _from,
  next
) => {
  const wallet = store.getters['researcher/workspaces/wallet']
  const walletId = store.getters['researcher/workspaces/workspaceWallet']
  const needToFetchWallet = isEmpty(wallet) && walletId
  if (needToFetchWallet) {
    await store.dispatch('researcher/workspaces/fetchWallet', walletId)
  }
  const walletInPaymentsBeta = await store.getters[
    'researcher/workspaces/walletInPaymentsBeta'
  ]
  if (!walletInPaymentsBeta) {
    return next({ name: 'feature-under-development' })
  } else {
    return next()
  }
}

addFnsBeforeEnter(routes, [redirectToFinancesIfNoAccess])
addFnsBeforeEnter(featureFlaggedRoutes, [
  redirectToFinancesIfNoAccess,
  redirectToFeatureUnderDevelopmentIfNoAccess,
])

export default [
  {
    path: 'researcher/workspaces/:workspaceId/finance',
    name: 'workspaces.finance',
    beforeEnter(to, from, next) {
      store.commit('researcher/workspaces/RESET_QUOTE')
      next()
    },
    component: () =>
      import('@/views/researcher/workspaces/WorkspaceFinance.vue'),
  },
  // Only used to redirect legacy finance routes to finance page
  {
    path: 'researcher/workspaces/finance',
    name: 'workspaces.redirect.finance',
    beforeEnter(to, from, next) {
      const workspaceId =
        store.getters['researcher/workspaces/workspaces']?.[0].id
      from.meta.noRefetch = true
      next({
        name: 'workspaces.finance',
        params: {
          workspaceId,
        },
      })
    },
  },
  ...routes,
  ...featureFlaggedRoutes,
]
