import { http, endpoints } from '@/api'
import createBlankSurvey from '@/api/blank_survey'
import rollbar from '@/integrations/rollbar'
import {
  getSurveyAsFormState,
  getSurveyFromFormState,
  prepareSurveyForPost,
} from '@/utils/survey'

export const types = {
  SET_SURVEY: 'SET_SURVEY',
  SET_SURVEY_STATE_IDLE: 'SET_SURVEY_STATE_IDLE',
  SET_SURVEY_STATE_LOADING: 'SET_SURVEY_STATE_LOADING',
  SET_SURVEY_STATE_SAVING: 'SET_SURVEY_STATE_SAVING',

  SET_SUMMARY: 'SET_SUMMARY',
  SET_SUMMARY_STATE_IDLE: 'SET_SUMMARY_STATE_IDLE',
  SET_SUMMARY_STATE_LOADING: 'SET_SUMMARY_STATE_LOADING',
}

const states = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  SAVING: 'SAVING',
}

/**
 * NOTE: if this loading/data pattern works well, maybe it can be extracted
 * into a common helper a la https://www.npmjs.com/package/redux-saga-routines
 * albeit less complicated/tedious.
 */
const state = {
  survey: {
    state: states.IDLE,
    data: {},
  },
  summary: {
    state: states.IDLE,
    data: {},
  },
}

const mutations = {
  [types.SET_SURVEY](state, survey) {
    state.survey.data = survey
  },
  [types.SET_SURVEY_STATE_IDLE](state) {
    state.survey.state = states.IDLE
  },
  [types.SET_SURVEY_STATE_LOADING](state) {
    state.survey.state = states.LOADING
  },
  [types.SET_SURVEY_STATE_SAVING](state) {
    state.survey.state = states.SAVING
  },
  [types.SET_SUMMARY](state, summary) {
    state.summary.data = summary
  },
  [types.SET_SUMMARY_STATE_IDLE](state) {
    state.summary.state = states.IDLE
  },
  [types.SET_SUMMARY_STATE_LOADING](state) {
    state.summary.state = states.LOADING
  },
}

const actions = {
  create({ commit }) {
    const survey = createBlankSurvey()
    commit(types.SET_SURVEY, survey)
    return survey
  },

  async fetch({ commit }, id) {
    commit(types.SET_SURVEY_STATE_LOADING)
    try {
      const data = await http.get(endpoints.SURVEY(id))

      const {
        date_created,
        date_modified,
        is_deleted,
        researcher_id,
        ...partsOfSurveyWeCareAbout
      } = data
      commit(types.SET_SURVEY, partsOfSurveyWeCareAbout)
      return partsOfSurveyWeCareAbout
    } catch (err) {
      rollbar.error(`Error while fetching short survey ${id}: ${err}`, err)
      throw err
    } finally {
      commit(types.SET_SURVEY_STATE_IDLE)
    }
  },

  async fetchSummary({ commit }, id) {
    commit(types.SET_SUMMARY_STATE_LOADING)
    const response = await http.get(endpoints.SURVEY_RESPONSES_SUMMARY(id))
    commit(types.SET_SUMMARY_STATE_IDLE)
    commit(types.SET_SUMMARY, response)
    return response
  },

  async save({ rootGetters, commit }, { surveyFormState, studyName }) {
    const researcherId = rootGetters['auth/user']?.id

    commit(types.SET_SURVEY_STATE_SAVING)
    const surveyResponse = await http.post(
      endpoints.SURVEYS,
      prepareSurveyForPost(
        getSurveyFromFormState(surveyFormState),
        researcherId,
        studyName
      )
    )
    const {
      date_created,
      date_modified,
      is_deleted,
      researcher_id,
      ...partsOfSurveyWeCareAbout
    } = surveyResponse
    const formattedSurvey = partsOfSurveyWeCareAbout
    commit(types.SET_SURVEY_STATE_IDLE)
    commit(types.SET_SURVEY, formattedSurvey)
    return formattedSurvey
  },
}

const getters = {
  survey: ({ survey }) => survey.data ?? {},
  surveyIsLoading: ({ survey }) => survey.state === states.LOADING,
  surveyAsFormState: (_state, getters) => {
    return getSurveyAsFormState(getters.survey)
  },

  summary: ({ summary }) => summary.data ?? {},
  summaryIsLoading: ({ summary }) => summary.state === states.LOADING,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
