import { endpoints, http } from '@/api'
import { SURVEY_BUILDER_APP_KEY } from '@/constants'
import store from '@/store'
import {
  refetchWorkspaceFromProjectIfNecessary,
  refetchWorkspaceFromStudyIfNecessary,
} from './_helpers'

export default [
  {
    path: 'researcher/workspaces/projects/:projectId/new-study',
    name: 'workspaces.studies.new',
    component: () =>
      import('@/views/researcher/studies/CreateStudyForm/CreateStudyForm.vue'),
    async beforeEnter(to, from, next) {
      await store.dispatch('researcher/studies/create')
      await store.dispatch('researcher/filters/fetchFilters')
      await store.dispatch(
        'researcher/participantGroups/fetchParticipantGroups'
      )
      if (
        store.getters['researcher/workspaces/project'].id ===
        to.params.projectId
      ) {
        return next()
      }
      // fetch the project
      try {
        await store.dispatch(
          'researcher/workspaces/fetchProject',
          to.params.projectId
        )
        await refetchWorkspaceFromProjectIfNecessary()
        next()
      } catch (error) {
        next()
      }
    },
  },
  {
    path: 'researcher/workspaces/studies/:id',
    name: 'workspaces.studies.edit',
    component: () =>
      import('@/views/researcher/studies/CreateStudyForm/CreateStudyForm.vue'),
    async beforeEnter(to, from, next) {
      if (from.name === 'workspaces.studies.new') {
        // don't refetch on save
        return next()
      }
      try {
        await store.dispatch('researcher/studies/fetch', to.params.id)

        // TMP: redirect ongoing and fixed price studies to api only view
        const study = store.state.researcher.studies.study

        if (study?.is_ongoing_study || study?.is_fixed_price_study) {
          return next({ name: 'researcher.api-only-feature' })
        }

        await refetchWorkspaceFromStudyIfNecessary()
        await store.dispatch('researcher/filters/fetchFilters')
        await store.dispatch(
          'researcher/participantGroups/fetchParticipantGroups'
        )
        next()
      } catch (error) {
        next()
      }
    },
  },
  {
    path: 'researcher/workspaces/studies/:id',
    name: 'workspaces.studies.study',
    props: true,
    component: async () => {
      await store.state?.auth?.launchDarklySetUserPromise
      return import('@/views/researcher/studies/Study.vue')
    },

    async beforeEnter(to, from, next) {
      let study

      try {
        study = await store.dispatch('researcher/studies/fetch', to.params.id)
        await refetchWorkspaceFromStudyIfNecessary()
      } catch {
        // noop
      }

      if (study?.is_trial_study) {
        if (to.name === 'workspaces.studies.trial-overview') {
          return next()
        }

        return next({
          name: 'workspaces.studies.trial-overview',
          params: { id: study?.id },
        })
      }

      const [, initialCounts] = await Promise.all([
        store.state?.auth?.launchDarklySetUserPromise,
        http.get(endpoints.STUDY_SUBMISSIONS_COUNTS(to.params.id)),
      ])

      to.params.initialCounts = initialCounts

      return next()
    },
    children: [
      {
        path: 'submissions',
        name: 'workspaces.studies.submissions',
        component: async () => {
          await store.state?.auth?.launchDarklySetUserPromise
          return import('@/views/researcher/studies/SubmissionList.vue')
        },
      },
      {
        path: 'responses',
        name: 'workspaces.studies.responses',
        component: () =>
          import('@/views/researcher/studies/SurveyResponses.vue'),
        props: _route => ({
          study: store.state.researcher.studies.study,
        }),
        beforeEnter: (to, from, next) => {
          const study = store.state.researcher.studies.study
          if (study.external_app === SURVEY_BUILDER_APP_KEY) {
            store.dispatch('researcher/surveys/fetchSummary', study.external_id)
            return next()
          }

          // we don't record responses for non-survey builder studies
          return next({
            name: 'workspaces.studies.submissions',
            params: { id: study.id },
          })
        },
      },
    ],
  },
  {
    path: 'researcher/workspaces/studies/:id/trial-overview',
    name: 'workspaces.studies.trial-overview',
    component: () =>
      import('@/views/researcher/studies/FreeTrialStudy/TrialOverview.vue'),
    beforeEnter: async (to, from, next) => {
      let study = store.state.researcher.studies.study

      if (!study.id) {
        try {
          study = await store.dispatch('researcher/studies/fetch', to.params.id)
          await refetchWorkspaceFromStudyIfNecessary()
        } catch {
          // noop
        }
      }

      if (!study.is_trial_study) {
        return next({
          name: 'workspaces.studies.submissions',
          params: { id: study.id },
        })
      }

      return next()
    },
  },
]
