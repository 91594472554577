import store from '@/store'

export default [
  {
    path: 'researcher/workspaces/:workspaceId/bring-participants',
    name: 'workspaces.bring-participants',
    component: () =>
      import('@/views/researcher/workspaces/byop/BringParticipants.vue'),
  },
  {
    path: 'researcher/workspaces/:workspaceId/bring-participants/new',
    name: 'workspaces.bring-participants.new',
    component: () =>
      import('@/views/researcher/workspaces/byop/BringParticipantsForm.vue'),
    async beforeEnter(to, from, next) {
      const participantGroups =
        store.getters['researcher/participantGroups/participantGroups']

      const screenersForCampaigns =
        store.getters['researcher/campaigns/screenersForCampaigns']

      const promises = []
      // Fetch participant groups if not already fetched
      if (!participantGroups?.length) {
        promises.push(
          store.dispatch('researcher/participantGroups/fetchParticipantGroups')
        )
      }

      // Fetch campaign screeners if not already fetched
      if (!screenersForCampaigns?.length) {
        promises.push(
          store.dispatch('researcher/campaigns/fetchScreenersForCampaigns')
        )
      }

      await Promise.all(promises)

      next()
    },
  },
  {
    path: 'researcher/workspaces/:workspaceId/bring-participants/:byopId',
    name: 'workspaces.bring-participant',
    component: () =>
      import('@/views/researcher/workspaces/byop/BringParticipant.vue'),
    async beforeEnter(to, from, next) {
      await store.dispatch(
        'researcher/campaigns/fetchCampaign',
        to.params.byopId
      )

      next()
    },
  },
]
