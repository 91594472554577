export default function (
  routeName = '',
  activeStudyId: string | null = null,
  isDCToolStudy = false
) {
  const isNotStudyRoute = routeName !== 'study'
  /**
   * If the activeStudyId is the onboarding study,
   * we don't want to redirect, since the user needs to access the onboarding journey
   * If the study is a DC Tool study,
   * we don't want to redirect, since the user needs to access the DC Tool
   */
  const hasActiveStudyId =
    activeStudyId && activeStudyId !== 'onboarding' && !isDCToolStudy
  const isNotMessagesRoute = !routeName.match(/messages/)
  // If the route is submissions.complete, we don't want to redirect, since the user needs to complete the submission
  const isNotSubmissionsCompleteRoute = routeName !== 'submissions.complete'

  return !!(
    isNotStudyRoute &&
    hasActiveStudyId &&
    isNotMessagesRoute &&
    isNotSubmissionsCompleteRoute
  )
}
