import { isAxiosError } from 'axios'
import { endpoints } from '@/api'
import { getAuthHttpClient } from '@/api/auth'

export const createEnrollmentTicket = async (
  userId: string,
  accessToken: string
) => {
  const client = getAuthHttpClient(accessToken)
  let ticketUrl: string | null = null

  try {
    const url = endpoints['new-auth'].CREATE_ENROLLMENT_TICKET(userId)
    const { data } = await client.post(
      url,
      {},
      { useGlobalErrorHandling: false }
    )
    ticketUrl = data.ticketUrl ?? null
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error
    }
  }

  return ticketUrl
}
