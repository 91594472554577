import { isAxiosError } from 'axios'
import type { Module } from 'vuex'
import { http, endpoints } from '@/api'
import type { Campaign, FilterOptionDetailed } from '@/api/types'
import { DEFAULT_LIST_PAGE_SIZE } from '@/constants'

export type State = {
  campaignsOnPage: Campaign[]
  loadingCampaigns: boolean
  totalCampaignCount: number
  campaignSelected: Campaign | null
  // Screeners available when creating a campaign
  normalizedScreeners: {
    [key: string]: FilterOptionDetailed
  }
  // List of screener ids available when creating a campaign
  screenersIds: string[]
  loadingScreenersForCampaigns: boolean
}

const SET_CAMPAIGNS = 'SET_CAMPAIGNS'
const SET_LOADING_CAMPAIGNS = 'SET_LOADING_CAMPAIGNS'
const SET_CAMPAIGN_COUNT = 'SET_CAMPAIGN_COUNT'
const SET_CAMPAIGN = 'SET_CAMPAIGN'
const SET_SCREENERS_FOR_CAMPAIGNS = 'SET_SCREENERS_FOR_CAMPAIGNS'
const SET_LOADING_SCREENERS_FOR_CAMPAIGNS =
  'SET_LOADING_SCREENERS_FOR_CAMPAIGNS'

export default {
  namespaced: true,

  state: {
    campaignsOnPage: [],
    loadingCampaigns: false,
    totalCampaignCount: 0,
    campaignSelected: null,
    normalizedScreeners: {},
    screenersIds: [],
    loadingScreenersForCampaigns: false,
  } as State,

  mutations: {
    [SET_CAMPAIGNS](state: State, campaigns: Campaign[]) {
      state.campaignsOnPage = campaigns
    },

    [SET_LOADING_CAMPAIGNS](state: State, loading: boolean) {
      state.loadingCampaigns = loading
    },

    [SET_CAMPAIGN_COUNT](state: State, count: number) {
      state.totalCampaignCount = count
    },

    [SET_CAMPAIGN](state: State, campaign: Campaign) {
      state.campaignSelected = campaign
    },

    [SET_LOADING_SCREENERS_FOR_CAMPAIGNS](state: State, loading: boolean) {
      state.loadingScreenersForCampaigns = loading
    },

    [SET_SCREENERS_FOR_CAMPAIGNS](
      state: State,
      screeners: FilterOptionDetailed[]
    ) {
      state.normalizedScreeners = {}
      state.screenersIds = []

      // Normalize screeners by filter_id for easy access { filter_id: screener }
      for (const screener of screeners) {
        state.normalizedScreeners[screener.filter_id] = screener
        state.screenersIds.push(screener.filter_id)
      }
    },
  },

  actions: {
    setLoadingCampaigns({ commit }, loading: boolean) {
      commit(SET_LOADING_CAMPAIGNS, loading)
    },

    setLoadingScreenersForCampaigns({ commit }, loading: boolean) {
      commit(SET_LOADING_SCREENERS_FOR_CAMPAIGNS, loading)
    },

    clearSelectedCampaign({ commit }) {
      commit(SET_CAMPAIGN, null)
    },

    async fetchScreenersForCampaigns({ commit, dispatch, rootGetters }) {
      dispatch('setLoadingScreenersForCampaigns', true)

      const screeners = await http.get<{ results: FilterOptionDetailed[] }>(
        endpoints.FILTERS,
        {
          params: {
            detailed: true,
            workspace_id: rootGetters['researcher/workspaces/workspaceId'],
            filter_by: 'participant',
          },
        }
      )

      commit(SET_SCREENERS_FOR_CAMPAIGNS, screeners.results)
      dispatch('setLoadingScreenersForCampaigns', false)
    },

    async fetchCampaigns({ commit, dispatch, rootGetters }, page = 1) {
      // Clear selected campaign when fetching campaigns, since the user is not on the campaign page
      dispatch('clearSelectedCampaign')
      dispatch('setLoadingCampaigns', true)

      try {
        const campaigns = await http.get<{
          results: Campaign[]
          meta: { count: number }
        }>(endpoints.CAMPAIGNS, {
          params: {
            workspace_id: rootGetters['researcher/workspaces/workspaceId'],
            limit: DEFAULT_LIST_PAGE_SIZE,
            offset: (page - 1) * DEFAULT_LIST_PAGE_SIZE,
          },
          useGlobalErrorHandling: false,
        })
        commit(SET_CAMPAIGNS, campaigns.results)
        commit(SET_CAMPAIGN_COUNT, campaigns.meta.count)
      } finally {
        dispatch('setLoadingCampaigns', false)
      }
    },

    async fetchCampaignCount({ commit, rootGetters }) {
      try {
        const campaigns = await http.get<{
          results: Campaign[]
          meta: { count: number }
        }>(endpoints.CAMPAIGNS, {
          params: {
            workspace_id: rootGetters['researcher/workspaces/workspaceId'],
            limit: 1,
          },
          useGlobalErrorHandling: false,
        })
        commit(SET_CAMPAIGN_COUNT, campaigns.meta.count)
      } catch (error) {
        // Campaigns will 404 in production if the user is not eligible, it will 403 locally with debug mode (hence both checks)
        if (
          isAxiosError(error) &&
          [404, 403].includes(error.response?.status ?? 500)
        ) {
          commit(SET_CAMPAIGN_COUNT, 0)
        }
        throw error
      }
    },

    resetCampaignCount({ commit }) {
      commit(SET_CAMPAIGN_COUNT, null)
    },

    async fetchCampaign({ commit }, byopId: string) {
      const campaign = await http.get<Campaign>(endpoints.CAMPAIGN(byopId))
      commit(SET_CAMPAIGN, campaign)
    },

    async stopCampaign({ commit, state }, byopId: string) {
      const campaign = await http.post<Campaign>(
        endpoints.CAMPAIGN_STOP(byopId)
      )

      // If campaign selected, update it
      if (state.campaignSelected) {
        commit(SET_CAMPAIGN, campaign)
      }

      // Otherwise, update the list of campaigns
      else {
        const updatedCampaigns = state.campaignsOnPage.map(c => {
          if (c.id === campaign.id) {
            return campaign
          }
          return c
        })
        commit(SET_CAMPAIGNS, updatedCampaigns)
      }
    },
  },

  getters: {
    campaigns: ({ campaignsOnPage }) => campaignsOnPage,
    loadingCampaigns: ({ loadingCampaigns }) => loadingCampaigns,
    campaignCount: ({ totalCampaignCount }) => totalCampaignCount,
    campaign: ({ campaignSelected }) => campaignSelected,
    screenersForCampaigns: ({ normalizedScreeners, screenersIds }) => {
      const screeners: FilterOptionDetailed[] = []
      for (const id of screenersIds) {
        const screener = normalizedScreeners[id]
        if (screener) {
          screeners.push(screener)
        }
      }
      return screeners
    },
    normalizedScreenersForCampaigns: ({ normalizedScreeners }) =>
      normalizedScreeners,
  },
} satisfies Module<State, unknown>
