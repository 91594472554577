import {
  MAX_CONCURRENT_SUBMISSIONS_DEFAULT,
  MAX_CONCURRENT_SUBMISSIONS_UNLIMITED,
  MAX_SUBMISSIONS_PER_PARTICIPANT_DEFAULT,
  MAX_SUBMISSIONS_PER_PARTICIPANT_UNLIMITED,
} from '@/constants/submissions-config'

/**
 * Returns the maximum amount of submissions a participant can make to the study.
 * @returns -1 if unlimited, defaults to 1 if not set
 */
export const getMaxSubmissionsPerParticipant = (
  study: StudyWithSubmissionsConfig
) => {
  return (
    study?.submissions_config?.max_submissions_per_participant ??
    MAX_SUBMISSIONS_PER_PARTICIPANT_DEFAULT
  )
}

/**
 * Returns the maximum amount of concurrent submissions a participant can make to the study.
 * @returns -1 if unlimited, defaults to unlimited if not set
 */
export const getMaxConcurrentSubmissions = (
  study: StudyWithSubmissionsConfig
) => {
  return (
    study?.submissions_config?.max_concurrent_submissions ??
    MAX_CONCURRENT_SUBMISSIONS_DEFAULT
  )
}

/**
 * Checks if participant is within a studies max submission limit.
 */
export const isParticipantWithinSubmissionLimit = (
  study: StudyWithSubmissionsConfig,
  numberOfSubmissionsMade: number
) => {
  const maxSubmissions = getMaxSubmissionsPerParticipant(study)
  return (
    maxSubmissions === MAX_SUBMISSIONS_PER_PARTICIPANT_UNLIMITED ||
    numberOfSubmissionsMade < maxSubmissions
  )
}

/**
 * Determines if a study allows multiple submissions by a participant.
 */
export const isStudyMultiSubmission = (study: StudyWithSubmissionsConfig) => {
  const maxSubmissions = getMaxSubmissionsPerParticipant(study)
  return (
    maxSubmissions > 1 ||
    maxSubmissions === MAX_SUBMISSIONS_PER_PARTICIPANT_UNLIMITED
  )
}

/**
 * Determines if a study allows unlimited submissions by a participant.
 */
export const isStudyUnlimitedMultiSubmission = (
  study: StudyWithSubmissionsConfig
) => {
  const maxSubmissions = getMaxSubmissionsPerParticipant(study)
  return maxSubmissions === MAX_SUBMISSIONS_PER_PARTICIPANT_UNLIMITED
}

/**
 * Determines if a study has limited concurrent submissions by participants.
 **/
export const isStudyThroughputOptimised = (
  study: StudyWithSubmissionsConfig
) => {
  const maxConcurrentSubmissions = getMaxConcurrentSubmissions(study)
  return maxConcurrentSubmissions !== MAX_CONCURRENT_SUBMISSIONS_UNLIMITED
}

/**
 * Determines if a study allows unlimited concurrent submissions by participants.
 */
export const isStudyUnlimitedConcurrentSubmissions = (
  study: StudyWithSubmissionsConfig
) => {
  const maxConcurrentSubmissions = getMaxConcurrentSubmissions(study)
  return maxConcurrentSubmissions === MAX_CONCURRENT_SUBMISSIONS_UNLIMITED
}

export type StudyWithSubmissionsConfig = Record<string, unknown> & {
  submissions_config?: {
    max_submissions_per_participant?: number
    max_concurrent_submissions?: number
  }
}
