<template>
  <figure class="image-container">
    <img
      :src="src"
      :style="imageStyle"
      :alt="alt"
      @error="$emit('error', $event)"
    />
  </figure>
</template>

<!-- TODO: Migrate to <script setup lang="ts"> -->
<!-- eslint-disable vue/component-api-style -->
<!-- eslint-disable vue/block-lang -->
<script>
export default {
  name: 'PImage',

  props: {
    src: {
      type: [String, Object],
      required: true,
    },

    width: {
      type: Number,
      default: 200,
    },

    height: {
      type: Number,
      default: 200,
    },

    responsive: {
      type: Boolean,
      required: false,
      default: true,
    },

    alt: {
      type: String,
      default: '',
    },
  },

  emits: ['error'],

  computed: {
    imageStyle() {
      if (!this.responsive) {
        return {
          ...(this.width !== null ? { width: this.width + 'px' } : {}),
          ...(this.height !== null ? { height: this.height + 'px' } : {}),
        }
      }
      return {
        width: 'calc((100vw / 1200) * ' + this.width + ')',
        height: 'calc((100vw / 1200) * ' + this.height + ')',
        maxWidth: this.width + 'px',
        maxHeight: this.height + 'px',
        minWidth: (2 * this.width) / 3 + 'px',
        minHeight: (2 * this.height) / 3 + 'px',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.image-container {
  line-height: 0;
}
</style>
