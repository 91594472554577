/*
  A simple feature toggle that can be used to turn on and off features.
  It can check if a feature is enabled by calling isFeatureToggled(flagName).
  This allows use to use featureToggles while a user is not logged in yet.
*/

export const FLAG_COOKIE_PREFIX = 'flags_'
const FLAG_COOKIE_REGEX = /^flags_(?!.+_ver\d{1,2}$)/

// Set cookie based flags based on query params
export const setCookieBasedFlags = query => {
  if (query.featureToggle) {
    // This extracts only valid feature toggles from the URL.
    //Example: onBoarding:1,anotherFlag:0 will return ['onBoarding:1', 'anotherFlag:0']
    const FEATURE_NAME_VALUE_REGEX = /([\w-]*?:\d)/
    const extractFeatureToggleValuePairs = values =>
      values.match(new RegExp(FEATURE_NAME_VALUE_REGEX, 'gmi')) || []

    const flags = extractFeatureToggleValuePairs(query.featureToggle)

    // Set cookie for each feature toggle
    flags.forEach(valuePair => {
      const { 0: featureName, 1: flagValue } = valuePair.split(':')
      const cookieName = `${FLAG_COOKIE_PREFIX}${featureName}`

      document.cookie = `${cookieName}=${flagValue}; path=/`
    })
  }
}

// Gets all cookies from the document and returns them as an object
export const getAllCookies = cookies =>
  cookies.split(';').reduce((cookies, currentCookie) => {
    const [name, value] = currentCookie.split('=')
    return {
      ...cookies,
      [name.trim()]: value,
    }
  }, {})

// Returns enabled cookies
export const getActiveFeatures = cookies => {
  if (Object.keys(cookies).length) {
    // Returns an object with flagName:value pairs that match the 'flags_' prefix and rmoves the prefix 'flags_'
    const cookieFeatures = Object.keys(cookies).reduce(
      (features, currentCookie) => {
        if (!currentCookie.match(FLAG_COOKIE_REGEX)) {
          return features
        }

        const value = cookies[currentCookie]

        return {
          ...features,
          [currentCookie.replace(FLAG_COOKIE_PREFIX, '')]: parseInt(value, 10),
        }
      },
      {}
    )

    // Return an array with all feature flags that are enabled in the cookies
    return Object.keys(cookieFeatures).reduce(
      (activeFeatures, currentFeature) => {
        if (cookieFeatures[currentFeature] === 1) {
          return [...activeFeatures, currentFeature]
        }
        return activeFeatures
      },
      []
    )
  }
}

export const isCookieFeatureToggled = feature => {
  const cookies = getAllCookies(document.cookie)
  const activeFeatures = getActiveFeatures(cookies)

  return activeFeatures.includes(feature)
}
