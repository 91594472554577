import type { CompletionCode } from '@/api/types'
import { MANUALLY_REVIEW } from '../constants/completionCodeActions'
import { COMPLETED } from '../constants/completionCodeTypes'
import { createCompletionCode } from './createCompletionCode'

export const createNewCompletionCodeObject = ({
  code = createCompletionCode(),
  code_type = COMPLETED,
  actions = [
    {
      action: MANUALLY_REVIEW,
    },
  ],
}: Partial<CompletionCode> = {}): CompletionCode => ({
  code,
  code_type,
  actions,
})
