import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { trackBannerEvent } from '@/analytics/mfa'
import { Label } from '@/analytics/mfa/constants'
import { isMfaEnabled } from '@/utils/auth'
import { StateManager } from '../utils'

export default () => {
  const { getters } = useStore()

  const stateManager = new StateManager()
  const isBannerVisible = ref(stateManager.shouldShowBanner())
  const mfaEnrollments = computed(() => getters['auth/mfa/mfaEnrollments'])
  const user = computed(() => getters['auth/user'])
  const isResearcher = user.value.user_type.toLowerCase() === 'researcher'

  const shouldShowBanner = computed(
    () =>
      isMfaEnabled() &&
      isBannerVisible.value &&
      mfaEnrollments.value?.length === 0 &&
      isResearcher
  )

  watch(
    mfaEnrollments,
    newMfaEnrollments => {
      if (newMfaEnrollments.length > 0) {
        stateManager.remove()
        isBannerVisible.value = false
      }
    },
    { immediate: true }
  )

  const handleBannerClose = () => {
    stateManager.update()
    isBannerVisible.value = false
    trackBannerEvent(Label.CLOSE_BANNER)
  }

  return {
    shouldShowBanner,
    handleBannerClose,
  }
}
