import store from '@/store'
import { isParticipantMfaRecoveryEnabled } from '@/utils/auth'

export default [
  {
    path: '',
    component: () => import('@/components/layout/ParticipantOnboarding.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/auth/common/CenteredLayout.vue'),
        children: [
          {
            path: 'participant/onboarding',
            name: 'auth.participant.onboarding',
            component: () =>
              import('@/views/auth/participant/onboarding/Onboarding.vue'),
            meta: {
              isPublic: true,
              forceAuth: true,
            },
          },
          {
            path: 'participant/onboarding/register',
            name: 'auth.participant.onboarding.register',
            component: () =>
              import(
                '@/views/auth/participant/onboarding/Register/Register.vue'
              ),
            meta: {
              isPublic: true,
              forceAuth: true,
              isOnboardingStep: true,
            },
            props: true,
          },
          {
            path: 'participant/onboarding/id-verification',
            name: 'auth.participant.onboarding.id_verification',
            component: () =>
              import(
                '@/views/auth/participant/onboarding/IdVerification/IdVerification.vue'
              ),
            meta: {
              isOnboardingStep: true,
            },
          },
          {
            path: 'participant/onboarding/complete',
            name: 'auth.participant.onboarding.complete',
            component: () =>
              import('@/views/auth/participant/onboarding/Complete.vue'),
          },
          {
            path: 'participant/onboarding/error',
            name: 'auth.participant.onboarding.error',
            component: () =>
              import(
                '@/views/auth/participant/onboarding/VerificationError.vue'
              ),
          },
        ],
      },

      // Onboarding study routes, do not use centered layout
      {
        path: '/participant/onboarding/onboarding-study',
        name: 'auth.participant.onboarding.onboarding_study',
        component: () =>
          import('@/views/auth/participant/onboarding/OnboardingStudy.vue'),
        meta: {
          isOnboardingStep: true,
          hideNav: true,
        },
      },
      {
        path: '/onboarding-study/start',
        name: 'auth.participant.onboarding.onboarding_study.start',
        beforeEnter(to, from, next) {
          if (store.getters['auth/user'].active_study_id !== 'onboarding') {
            next({ name: 'home' })
          } else {
            next()
          }
        },
        component: () =>
          import(
            '@/views/auth/participant/onboarding/InHouseOnboardingStudy.vue'
          ),
        meta: {
          hideNav: true,
        },
      },
      // using onboarding layout and nav to prevent access to anything while a user is sent through onboarding IDV
      {
        path: 'participant/recovery',
        name: 'auth.participant.recovery',
        beforeEnter(to, from, next) {
          if (
            isParticipantMfaRecoveryEnabled() &&
            store.getters['auth/mfa/isInMfaRecovery']
          ) {
            return next()
          } else {
            return next({ name: 'home' })
          }
        },
        component: () => import('@/components/mfa/AccountRecovery.vue'),
      },
    ],
  },
]
