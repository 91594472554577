export const MAX_SUBMISSIONS_PER_PARTICIPANT_DEFAULT = 1
export const MAX_SUBMISSIONS_PER_PARTICIPANT_UNLIMITED = -1

export const MAX_CONCURRENT_SUBMISSIONS_UNLIMITED = -1
export const MAX_CONCURRENT_SUBMISSIONS_DEFAULT =
  MAX_CONCURRENT_SUBMISSIONS_UNLIMITED

// List of URLs for which the maximum concurrent submissions is disabled
export const MAX_CONCURRENT_SUBMISSIONS_DISABLED_URLS = [
  'surveymonkey.com',
  'qualtrics.com',
  'docs.google.com',
  'alchemer.com',
  'typeform.com',
  'research.sc',
]
