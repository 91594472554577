import shortid from 'shortid'

/**
 * Converts a Survey into a SurveyAsFormState. This is generally
 * called when setting initial values for a form.
 *
 * @param {Survey} survey A survey as is provided by API / Vuex
 * @returns {SurveyAsFormState | null} A survey that can be used as final-form state
 */
export const getSurveyAsFormState = survey => {
  if (!survey) return null
  if (!Object.hasOwnProperty.call(survey, 'sections')) return null
  if (!Array.isArray(survey.sections) || !survey.sections[0]) return null
  if (
    !Object.hasOwnProperty.call(survey.sections[0], 'questions') ||
    !Array.isArray(survey.sections[0].questions)
  )
    return null

  const questions = survey.sections[0].questions.reduce(
    (questionFields, question, qIdx) => {
      const answers =
        question.answers?.reduce((answerFields, answer, aIdx) => {
          return {
            ...answerFields,
            [answer.id]: {
              id: answer.id,
              value: answer.value,
              order: aIdx,
            },
          }
        }, {}) ?? {}
      return {
        ...questionFields,
        [question.id]: {
          id: question.id,
          title: question.title,
          type: question.type,
          order: qIdx,
          answers,
          answers_count: Object.keys(answers).length,
        },
      }
    },
    {}
  )

  return {
    questions,
    questions_count: Object.keys(questions).length,
  }
}

/**
 * Removes all section/question ids from a survey, adds the researcher ID and
 * optionally overrides the survey & section titles (e.g. with the name of the study).
 *
 * @param {Survey} survey
 * @param {string} researcherId
 * @param {string | undefined} titleOverride
 * @returns {NewSurvey}
 */
export const prepareSurveyForPost = (survey, researcherId, titleOverride) => {
  return {
    researcher_id: researcherId,
    title: titleOverride ?? survey.title ?? '',
    sections:
      survey.sections?.map(section => ({
        title: titleOverride ?? section.title ?? '',
        questions:
          section.questions?.map(question => ({
            title: question.title,
            type: question.type,
            answers:
              question.answers?.map(answer => ({ value: answer.value })) ?? [],
          })) ?? [],
      })) ?? [],
  }
}

/**
 * Converts a SurveyAsFormState into a Survey. This is generally called
 * when preparing form values to be submitted to the API.
 *
 * @param {SurveyAsFormState} surveyFormState A survey as is modelled in final-form
 * @param {Survey | undefined} existingSurvey A survey that has already been fetched from the API (e.g. draft)
 * @returns {Survey} A survey that can be used with API / Vuex
 */
export const getSurveyFromFormState = (
  surveyFormState,
  existingSurvey = {}
) => {
  return {
    _id: existingSurvey._id,
    title: existingSurvey.title ?? '',
    sections: [
      {
        ...(existingSurvey.sections?.[0] ?? { title: '' }),
        questions: Object.entries(surveyFormState?.questions ?? {})
          .map(([id, question]) => ({
            id,
            title: question.title,
            type: question.type,
            order: question.order,
            answers: Object.entries(question.answers ?? {})
              .map(([id, answer]) => ({
                id,
                value: answer.value,
                order: answer.order,
              }))
              .sort((a, b) => a.order - b.order)
              .map(({ order, ...answer }) => answer),
          }))
          .sort((a, b) => a.order - b.order)
          .map(({ order, ...question }) => question),
      },
    ],
  }
}

/**
 * Converts survey response form values into a format accepted by the API.
 * Single answers are converted to an array, and answer ids are added.
 *
 * @param {SurveyResponseAsFormState} response A survey response as is modelled in final-form
 * @param {Survey} survey A survey that has been fetched from the API
 * @param {string} submissionId The current active submission
 * @param {string} participantId The current participant
 * @returns {SurveyResponse}
 */
export const prepareSurveyResponseForPost = (
  response,
  survey,
  submissionId,
  participantId
) => {
  /**
   * @type SurveyResponse['sections']
   */
  const sections = survey.sections.map(section => ({
    section_id: section.id,
    questions: section.questions.map(question => {
      const answers = Array.isArray(response?.[question.id])
        ? response[question.id]
        : response?.[question.id]
        ? [response[question.id]]
        : []

      return {
        question_id: question.id,
        question_title: question.title,
        answers: answers.map(answer => {
          return {
            answer_id: answer.id,
            value: answer.value,
          }
        }),
      }
    }),
  }))

  return {
    participant_id: participantId,
    submission_id: submissionId,
    sections,
  }
}

/**
 * Duplicates a survey question from the survey form state. New ids will be given
 * to the question and its answers.
 *
 * @param {SurveyQuestionAsFormState} question The question to be duplicated
 * @returns {SurveyQuestionAsFormState}
 */
export const duplicateQuestionInForm = question => {
  const newId = shortid.generate()
  const newAnswers = Object.values(question.answers ?? {}).reduce(
    (answers, answer) => {
      const newAnswerId = shortid.generate()
      return {
        ...answers,
        [newAnswerId]: { id: newAnswerId, value: answer.value },
      }
    },
    {}
  )

  return {
    ...question,
    id: newId,
    answers: newAnswers,
    answers_count: Object.keys(newAnswers).length,
  }
}

/**
 * Survey question from API response or Vuex.
 * @typedef {{
 *   id?: string;
 *   title: string;
 *   type: "single" | "multiple";
 *   answers: {
 *     id?: string;
 *     value: string;
 *   }[];
 * }} SurveyQuestion
 */

/**
 * Survey from API response or Vuex.
 * @typedef {{
 *    _id?: string;
 *    title: string;
 *    sections: {
 *      id?: string;
 *      title: string;
 *      questions: SurveyQuestion[];
 *    }[];
 * }} Survey
 *
 * Survey that can be POST-ed to the API
 * @typedef {{
 *    researcher_id: string;
 *    title: string;
 *    sections: {
 *      title: string;
 *      questions: {
 *        title: string;
 *        type: string;
 *        answers: {
 *          value: string;
 *        }[];
 *      }[];
 *    }[];
 * }} NewSurvey
 *
 * Survey question that can be used with final-form. This is used because vue-final-form
 * doesn't work well with arrays of objects.
 * @typedef {{
 *   id: string;
 *   title: string;
 *   type: string,
 *   answers: Record<string, {
 *     id: string;
 *     value: string;
 *     order: number;
 *   }>;
 * }} SurveyQuestionAsFormState
 *
 * Survey that can be used with final-form. This is used because vue-final-form
 * doesn't work well with arrays of objects.
 * @typedef {{
 *    questions: Record<string, SurveyQuestionAsFormState>;
 * }} SurveyAsFormState
 *
 * Response that can be submitted to the API.
 * @typedef {{
 *    participant_id: string;
 *    submission_id: string;
 *    sections: {
 *      section_id: string;
 *      questions: {
 *        question_id: string;
 *        answers: { answer_id: string; value: string; }[];
 *      }[];
 *    }[];
 * }} SurveyResponse
 *
 * Response as provided by final-form.
 * @typedef {
 *  Record<string, string | string[]>
 * } SurveyResponseAsFormState
 */
