export function createCompletionCode() {
  // generates 8 char random alphanumeric string
  return (
    'C' +
    Math.ceil(Date.now() * Math.random() * 100)
      .toString(36)
      .toUpperCase()
      .slice(0, 7)
  )
}
