import { trackStructEvent } from '@/integrations/snowplow'

export const trackError = (
  code: number,
  message: string,
  error_code?: number
) => {
  error_code = error_code ?? 0 // Set default value if not provided

  trackStructEvent({
    category: 'error',
    action: code.toString(),
    label: message,
    property: error_code.toString(),
  })
}
