export const DATA_COLLECTION_TASK_BUILDER = 'DC_TOOL'

export const TASK_BUILDER_TERMS_PARTICIPANT_URL =
  'https://prolific.notion.site/Participant-Terms-700436ee4d6141b2a22335ea7e450baf'
export const TASK_BUILDER_TERMS_RESEARCHER_URL =
  'https://prolific.notion.site/Researcher-Terms-7787f102f0c541bdbe2c04b5d3285acb'

export const TYPE_MULTIPLE_CHOICE = 'multiple_choice'
export const TYPE_FREE_TEXT = 'free_text'

export const BATCH_STATUS_ERROR = 'ERROR'
export const BATCH_STATUS_PROCESSING = 'PROCESSING'
export const BATCH_STATUS_READY = 'READY'
export const BATCH_STATUS_UNINITIALISED = 'UNINITIALISED'
export const BATCH_STATUSES = [
  BATCH_STATUS_ERROR,
  BATCH_STATUS_PROCESSING,
  BATCH_STATUS_READY,
  BATCH_STATUS_UNINITIALISED,
] as const

export const DATASET_STATUS_ERROR = 'ERROR'
export const DATASET_STATUS_PROCESSING = 'PROCESSING'
export const DATASET_STATUS_READY = 'READY'
export const DATASET_STATUS_UNINITIALISED = 'UNINITIALISED'
export const DATASET_STATUSES = [
  DATASET_STATUS_ERROR,
  DATASET_STATUS_PROCESSING,
  DATASET_STATUS_READY,
  DATASET_STATUS_UNINITIALISED,
] as const
