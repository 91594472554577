import { createProxy } from './helpers/createProxy'

// TODO: typed manually as couldn't find any published types
type MethodParams =
  | 'setPath'
  | 'trackPageView'
  | 'identify'
  | 'trackEvent'
  | 'revokeCookieConsent'
  | 'addPrivacyConsentListener'
  | 'doNotTrack'
  | 'addIdentityListener'
  | 'setContentType'
  | 'refreshPageHandlers'

type PushParams = [MethodParams, (string | object)?]

type Hubspot = {
  push: (callParam: PushParams) => void
}

const hubspot = createProxy<Hubspot>({
  name: '_hsq',
  klaroConsentName: 'hubspot',
})

export default hubspot
