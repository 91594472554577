import { translate } from '@/i18n'
import rollbar from '../integrations/rollbar'
import braintreeErrorCodes from './braintreeErrorCodes.json'
import notifier from './notifier'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const generateClientErrorMessage = (error: any): string | null => {
  const failureCode = error.detail?.failure_code || error.details?.failure_code

  // Handle 3DS errors. Add the error code if it exists
  if (error?.code === 'THREE_D_SECURE_FAILED') {
    const translated = translate('payments.errors.topups.three_d_secure_failed')
    return failureCode ? `${translated} #${failureCode}` : translated
  }

  // Handle 3DS lookup errors. Add the error code if it exists
  if (error?.code === 'THREEDS_LOOKUP_VALIDATION_ERROR') {
    const originalErrorMessage =
      error.details?.originalError?.details?.originalError?.error?.message
    if (originalErrorMessage === 'Unsupported card type') {
      const translated = translate(
        'payments.errors.topups.unsupported_card_error'
      )
      return failureCode ? `${translated} #${failureCode}` : translated
    }
  }

  return null
}

const generateServerErrorMessage = (failureCode: number): string | null => {
  const errorCode = braintreeErrorCodes.find(
    errorCode =>
      errorCode.code === failureCode ||
      (Array.isArray(errorCode.code) && errorCode.code.includes(failureCode))
  )
  const translatedMessage =
    translate(`payments.errors.topups.${errorCode?.message}`) || null
  return translatedMessage ? `${translatedMessage} #${failureCode}` : null
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleTopupErrors = (error: any): void => {
  rollbar.error(error, error.details || error.detail) // Send error to rollbar

  const genericErrorMessage = translate(
    'payments.errors.topups.generic_topup_error'
  )
  let errorMessage = generateClientErrorMessage(error)

  if (!errorMessage) {
    const code = error.detail?.failure_code || error.details?.failure_code

    if (code) {
      // Handle specific code errors in range
      if (code >= 2109 && code <= 2999) {
        const translated = translate(
          'payments.errors.topups.transaction_rejected_by_bank_error'
        )
        errorMessage = `${translated} #${code}`
      } else {
        errorMessage = generateServerErrorMessage(parseInt(code))
      }
    }
    if (!errorMessage) {
      errorMessage = code
        ? `${genericErrorMessage} #${code}`
        : genericErrorMessage
    }
  }

  notifier.error(errorMessage, { duration: 0 })
}

type Balance = {
  currency: string
  amount: number
}

export const getParticipantBalanceInGBP = (balances: Balance[]): number => {
  const balance = balances?.find(b => b.currency === 'GBP')
  return balance?.amount || 0
}
