import { datadogRum } from '@datadog/browser-rum'
import { getUnixTime, parseISO } from 'date-fns'
import * as LDClient from 'launchdarkly-js-client-sdk'
import camelCase from 'lodash/camelCase'
import { reactive } from 'vue'
import rollbar from '@/integrations/rollbar'
import { isDesktop, isMobile, isTablet } from '@/utils/device'
import type { WorkspaceProduct } from '@/utils/workspace-product'
import fallbackVariations from './fallback-variations'

const { VUE_APP_LD_CLIENT_SIDE_ID: clientSideID } = import.meta.env
const state = reactive<{ flags: LDClient.LDFlagSet }>({ flags: {} })

let launchDarklyClient: LDClient.LDClient

/**
 * Sets both raw and camelCase flags. This means we can access flags like
 * `flags['Some_raw_flag_2021-09-09']` and `const { someRawFlag2010909 } = flags` etc.
 */
export const setFlag = (key: string, value: LDClient.LDFlagValue) => {
  const keys = [key, camelCase(key)]
  for (const key of keys) {
    state.flags[key] = value
  }
}

export const resetFlags = () => {
  state['flags'] = {}
}

export const initLaunchDarkly = async () => {
  try {
    launchDarklyClient = LDClient.initialize(
      clientSideID,
      {
        kind: CONTEXT_TYPES.USER,
        key: import.meta.env['VUE_APP_LD_USER_KEY'],
        anonymous: true,
      },
      {
        bootstrap: 'localStorage',
        inspectors: [
          {
            type: 'flag-used',
            name: 'dd-inspector',
            method: (key: string, detail: LDClient.LDEvaluationDetail) => {
              datadogRum.addFeatureFlagEvaluation(key, detail.value)
            },
          },
        ],
      }
    )
    await launchDarklyClient.waitForInitialization()
    const rawFlags = launchDarklyClient.allFlags()

    // ensure we init with a clean state
    resetFlags()

    for (const rawFlag in rawFlags) {
      // set fallback variation
      const fallbackVariation = Object.prototype.hasOwnProperty.call(
        fallbackVariations,
        rawFlag
      )
        ? fallbackVariations[rawFlag]
        : null
      setFlag(rawFlag, launchDarklyClient.variation(rawFlag, fallbackVariation))
    }

    launchDarklyClient.on('change', changes => {
      for (const key in changes) {
        setFlag(key, changes[key].current)
      }
    })

    return launchDarklyClient
  } catch (error) {
    // LD failed to initialize. Here we add the try/catch logic to ensure that the
    // promise is fulfilled and doesn't throw the error further up the call stack.
    // Right now, we don't need to do anything with the error other than catch it.
    // This behaviour can be simulated by disabling the local network.
    // All flags will be on off state and promise returned by this function
    // will be fulfilled.
    if (error instanceof Error) {
      rollbar.error(error)
    }
    return null
  }
}

const USER_TYPES = {
  RESEARCHER: 'Researcher',
  PARTICIPANT: 'Participant',
} as const

type UserType = (typeof USER_TYPES)[keyof typeof USER_TYPES]

interface UserProps {
  id: string
  user_type: UserType
  experimental_group: number
  date_joined: string
  researcher_type?: string[]

  // Can contain other properties
  [x: string]: unknown
}

interface WorkspaceProps {
  id: string
  experimental_group: number
  product: WorkspaceProduct

  // Can contain other properties
  [x: string]: unknown
}

interface LDUserContextProps {
  kind: 'user'
  key: string
  userType: UserType
  device?: string
  experimentalGroup: number
  dateJoined: number
  researcherType?: string[]
}

interface LDWorkspaceContextProps {
  kind: 'workspace'
  key: string
  experimentalGroup: number
  product: WorkspaceProduct
}

const initLDUser = (user: UserProps) => {
  const { VUE_APP_MODE } = import.meta.env

  let device

  if (isDesktop()) {
    device = 'desktop'
  } else if (isTablet()) {
    device = 'tablet'
  } else if (isMobile()) {
    device = 'mobile'
  }

  const userContext: LDUserContextProps = {
    kind: CONTEXT_TYPES.USER,
    key: import.meta.env['VUE_APP_LD_USER_KEY'],
    userType: user.user_type,
    device,
    experimentalGroup: user.experimental_group,
    dateJoined: getUnixTime(parseISO(user.date_joined)), // Parse and get unix time
    researcherType: user.researcher_type,
  }

  // Use a unique user id only in deployed environments
  // to avoid increasing our MAU limit in LaunchDarkly in local and testing environments.
  if (
    VUE_APP_MODE === 'production' ||
    VUE_APP_MODE === 'staging' ||
    VUE_APP_MODE === 'origin-staging' ||
    VUE_APP_MODE === 'origin-test' ||
    VUE_APP_MODE === 'gcp_test' ||
    VUE_APP_MODE === 'squad_trust' ||
    VUE_APP_MODE === 'incubator' ||
    VUE_APP_MODE === 'rnc' ||
    VUE_APP_MODE === 'redpandas' ||
    VUE_APP_MODE === 'payments' ||
    VUE_APP_MODE === 'yellow' ||
    VUE_APP_MODE === 'blue' ||
    VUE_APP_MODE === 'orange' ||
    VUE_APP_MODE === 'red' ||
    VUE_APP_MODE === 'green'
  ) {
    userContext.key = user.id
  }

  return userContext
}

export const CONTEXT_TYPES = {
  USER: 'user',
  WORKSPACE: 'workspace',
} as const

type LDContextType = (typeof CONTEXT_TYPES)[keyof typeof CONTEXT_TYPES]

// this enables us to add or set an LD context
const setLDContext = async (
  kind: LDContextType,
  payload: LDUserContextProps | LDWorkspaceContextProps
) => {
  if (!launchDarklyClient || !launchDarklyClient.getContext) {
    return
  }
  const currentContext = launchDarklyClient.getContext()
  let newContext
  // TODO: strip `'kind' in currentContext` once LDUser is removed from SDK
  if ('kind' in currentContext && currentContext.kind === 'multi') {
    newContext = {
      ...currentContext,
      [kind]: {
        ...payload,
      },
    }
  } else {
    // If single context, check if it needs inserted
    if ('kind' in currentContext && currentContext.kind !== kind) {
      // Insert
      newContext = {
        kind: 'multi',
        [currentContext.kind]: currentContext,
        [kind]: payload,
      }
    } else {
      // Replace
      newContext = payload
    }
  }

  try {
    await launchDarklyClient.identify(newContext)
  } catch (error) {
    if (error instanceof Error) {
      rollbar.error(error)
    }
  }
}

export const setLDUser = (user: UserProps) =>
  setLDContext(CONTEXT_TYPES.USER, initLDUser(user))

export const setLDWorkspace = (workspace: WorkspaceProps) =>
  setLDContext(CONTEXT_TYPES.WORKSPACE, {
    kind: CONTEXT_TYPES.WORKSPACE,
    key: workspace.id,
    experimentalGroup: workspace.experimental_group,
    product: workspace.product,
  })

export const flags = () => ({ ...state.flags })

export const featureIsEnabled = (flag: string) => !!state.flags[flag]

export const getFlagValue = (flag: string) => state.flags[flag]
