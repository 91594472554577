import parseISO from 'date-fns/parseISO'

/**
 * Compares 2 strings and orders them alphabetically
 */
export function sortByText(a, b) {
  if (a < b) {
    return -1
  }
  if (a > b) {
    return 1
  }
  return 0
}

/**
 * Compares 2 dates and orders them chronologically
 */
export function sortByDate(a, b) {
  const date_a = parseISO(a)
  const date_b = parseISO(b)
  return date_a - date_b
}

/**
 * Compares 2 numbers and orders them numerically
 */
export function sortByNumber(a, b) {
  return a - b
}

/**
 * Gets the correct key on the study based on the sorting option
 * The reward and average_reward_per_hour keys are dynamic based on the participant's home currency, and are nested in the study object
 */
export function getStudyKeyForSortOption(sortOption, participantHomeCurrency) {
  const sortOptionsKeyMap = {
    reward: `study_reward_by_currency.${participantHomeCurrency}.amount`,
    average_reward_per_hour: `study_average_reward_per_hour_by_currency.${participantHomeCurrency}.amount`,
    published_at: 'published_at',
    average_completion_time: 'average_completion_time',
  }

  return sortOptionsKeyMap[sortOption]
}

/**
 * Returns the study value based on the key/field provided for the sorting option
 */
export function getStudyValueForSortOption(
  study,
  sortOption,
  participantHomeCurrency
) {
  const keyOnStudy = getStudyKeyForSortOption(
    sortOption,
    participantHomeCurrency
  )

  // Split the keyOnStudy by '.' to get all the nested paths
  // E.g study_reward_by_currency.GBP.amount will be split into ['study_reward_by_currency', 'GBP', 'amount']
  const nestedPaths = keyOnStudy.split('.')

  // Reduce the array of nested paths to get the final value
  // E.g. ['study_reward_by_currency', 'GBP', 'amount'] will return study.study_reward_by_currency.GBP.amount
  return nestedPaths.reduce((obj, field) => obj?.[field], study)
}
