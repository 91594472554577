import app from './app'
import auth from './auth'
import global from './global'
import messages from './messages'
import oidc from './oidc'
import participant from './participant'
import researcher from './researcher'

const modules = {
  auth,
  global,
  app,
  researcher,
  participant,
  messages,
  oidc,
}

export default modules
