import { ref } from 'vue'
import { Label, trackBannerEvent, trackModal } from '@/analytics/mfa'

export default () => {
  const shouldShowModal = ref(false)
  const isLoading = ref(false)

  const handleModalAction = (value: boolean) => {
    shouldShowModal.value = value
    if (value) {
      trackBannerEvent(Label.MORE_INFO)
    } else {
      trackModal(Label.CLOSE_MODAL)
    }
  }

  return {
    shouldShowModal,
    isLoading,
    handleModalOpen: () => handleModalAction(true),
    handleModalClose: () => handleModalAction(false),
  }
}
