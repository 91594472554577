import { featureIsEnabled } from '@/integrations/launchDarkly'
import { FLAG_SSG_SCREENING_TABS } from '@/integrations/launchDarkly/active-flags'
import store from '@/store'

export default [
  {
    path: 'researcher/workspaces/:workspaceId/screener-sets',
    name: 'workspaces.filter-sets',
    component: () => import('@/views/researcher/workspaces/FilterSets.vue'),
    async beforeEnter(to, from, next) {
      const promises = [
        store.dispatch(
          'researcher/filters/fetchFilterSets',
          to.params.workspaceId
        ),
      ]

      // Only fetch filters if they haven't been fetched yet
      if (
        !Object.keys(store.state.researcher.filters.normalizedFilters).length
      ) {
        promises.push(store.dispatch('researcher/filters/fetchFilters'))
      }

      await Promise.all(promises)
      next()
    },
  },
  {
    // TODO: check URL scheme. If we want it to be more like projects,
    // we'll need to put backlinks to the workspace in the filter set response.
    path: 'researcher/workspaces/:workspaceId/screener-sets/new',
    name: 'workspaces.filter-sets.new',
    component: () => import('@/views/researcher/workspaces/FilterSet.vue'),
    async beforeEnter(to, from, next) {
      if (
        !Object.keys(store.state.researcher.filters.normalizedFilters).length
      ) {
        await store.dispatch('researcher/filters/fetchFilters')
      }
      next()
    },
  },
  {
    path: 'researcher/workspaces/:workspaceId/screener-sets/find',
    name: 'workspaces.filter-sets.find',
    component: () => import('@/views/researcher/workspaces/FindAudience.vue'),
    async beforeEnter(to, from, next) {
      if (!featureIsEnabled(FLAG_SSG_SCREENING_TABS)) {
        return next({ name: 'error.404' })
      }

      if (
        !Object.keys(store.state.researcher.filters.normalizedFilters).length
      ) {
        await store.dispatch('researcher/filters/fetchFilters')
      }
      next()
    },
  },
  {
    // TODO: check URL scheme. If we want it to be more like projects,
    // we'll need to put backlinks to the workspace in the filter set response.
    path: 'researcher/workspaces/:workspaceId/screener-sets/:filterSetId',
    name: 'workspaces.filter-set',
    component: () => import('@/views/researcher/workspaces/FilterSet.vue'),
    async beforeEnter(to, from, next) {
      const promises = [
        store.dispatch('researcher/filters/fetchFilterSet', {
          filterSetId: to.params.filterSetId,
        }),
      ]

      if (
        !Object.keys(store.state.researcher.filters.normalizedFilters).length
      ) {
        promises.push(store.dispatch('researcher/filters/fetchFilters'))
      }

      await Promise.all(promises)

      next()
    },
  },
]
