import type { UnitOfDataSection } from '@/api/types'
import { DecryptionError, decrypt, encrypt } from '@/utils/encryption'
import { sessionStorageGetItem, sessionStorageSetItem } from '@/utils/storage'

interface LocalDataset {
  file: File
  batchName: string
  preview: UnitOfDataSection[]
}

interface SerializedDBDataset {
  file:
    | File
    | {
        content: string
        name: string
        type: string
      }
  preview: string
  batchName: string
}

export class DatasetStore {
  private dbName = 'dataset-store'
  private version = 1
  private storageLabel = 'prolific-aitb-dsid'

  private openDB(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, this.version)

      request.onerror = () => reject(request.error)
      request.onsuccess = () => resolve(request.result)

      request.onupgradeneeded = event => {
        const db = (event.target as IDBOpenDBRequest).result
        if (!db.objectStoreNames.contains('datasets')) {
          db.createObjectStore('datasets')
        }
      }
    })
  }

  private async getStore(
    mode: IDBTransactionMode = 'readonly'
  ): Promise<IDBObjectStore> {
    const db = await this.openDB()
    const transaction = db.transaction('datasets', mode)
    return transaction.objectStore('datasets')
  }

  async set(id: string, dataset: LocalDataset): Promise<void> {
    const key = crypto.randomUUID()
    sessionStorageSetItem(this.storageLabel, key)

    const fileContents = await dataset.file.text()
    const encryptedFileContents = await encrypt(key, fileContents)
    const encryptedFile = new File([encryptedFileContents], dataset.file.name, {
      type: dataset.file.type,
    })

    const encryptedPreview = await encrypt(key, JSON.stringify(dataset.preview))

    const encryptedBatchName = await encrypt(key, dataset.batchName)

    return new Promise((resolve, reject) => {
      this.getStore('readwrite')
        .then(store => {
          const request = store.put(
            {
              batchName: encryptedBatchName,
              file: encryptedFile,
              preview: encryptedPreview,
            },
            id
          )
          request.onerror = () => reject(request.error)
          request.onsuccess = () => resolve()
        })
        .catch(reject)
    })
  }

  get(id: string): Promise<LocalDataset | null> {
    return new Promise((resolve, reject) => {
      const key = sessionStorageGetItem(this.storageLabel)

      if (key === null) {
        return resolve(null)
      }

      this.getStore()
        .then(store => {
          const request = store.get(id)
          request.onerror = () => reject(request.error)
          request.onsuccess = async () => {
            const dataset = request.result as SerializedDBDataset | undefined

            if (!dataset) {
              return resolve(null)
            }

            try {
              const contents =
                dataset.file instanceof File
                  ? await dataset.file.text()
                  : dataset.file.content

              const decryptedContents = await decrypt(key as string, contents)

              const decryptedFile = new File(
                [decryptedContents],
                dataset.file.name,
                { type: dataset.file.type }
              )

              const decryptedPreview = await decrypt(
                key as string,
                dataset.preview
              )

              const decryptedBatchName = await decrypt(
                key as string,
                dataset.batchName
              )

              resolve({
                file: decryptedFile,
                preview: JSON.parse(decryptedPreview),
                batchName: decryptedBatchName,
              })
            } catch (error) {
              if (error instanceof DecryptionError) {
                await this.delete(id)
                sessionStorageSetItem(this.storageLabel, '')
              }
              reject(error)
            }
          }
        })
        .catch(reject)
    })
  }

  delete(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getStore('readwrite')
        .then(store => {
          const request = store.delete(id)
          request.onerror = () => reject(request.error)
          request.onsuccess = () => resolve()
        })
        .catch(reject)
    })
  }

  getAllKeys(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      this.getStore()
        .then(store => {
          const request = store.getAllKeys()
          request.onerror = () => reject(request.error)
          request.onsuccess = () =>
            resolve(Array.from(request.result as string[]))
        })
        .catch(reject)
    })
  }
}
