// Message/Notification
import { DSButton } from '@prolific-oss/design-system'
import {
  ElMessageBox as MessageBox,
  ElNotification as Notification,
} from 'element-plus'
import { upperFirst } from 'lodash'
import { h, createApp } from 'vue'
import Actions from '@/components/form/Actions.vue'
import i18n, { translate } from '@/i18n'
import store from '@/store'
import sanitize from '@/utils/sanitize'

const addMessageType =
  type =>
  (message, options = {}) => {
    const defaultOptions = {
      duration: 3000,
      title: translate(`notifications.${type}`),
    }

    // notifications are sometimes echoed from the
    // API responses, which can sometimes on occasion
    // contain text that originates from user input.
    // so sanitizing to mimimize xss risk
    if (options.dangerouslyUseHTMLString) {
      message = sanitize(message, {
        allowedTags: ['a', 'p', 'br'],
        allowedAttrs: ['href', 'target'],
      })
    }

    return Notification({
      type,
      message,
      ...defaultOptions,
      ...options,
    })
  }

function customComponent(component, opts = {}) {
  const { on, ...rest } = opts

  const events = {}
  let notification = { close: () => {} }
  if (on) {
    Object.keys(on).forEach(key => {
      const event = `on${upperFirst(key)}`
      events[event] = function (...args) {
        on[key](notification, ...args)
      }
    })
  }

  const div = document.createElement('div')
  const app = createApp(component, events)
  app.use(store)
  app.use(i18n)
  app.mount(div)

  const instance = app._instance
  const vnode = instance.vnode
  const appContext = app._context

  notification = Notification(
    {
      ...rest,
      message: vnode,
    },
    appContext
  )
  return notification
}

// Sugar for creating notifications with buttons in them. Use like:
// notifier.withButton({ buttonText: 'Got it!', message: 'Hello', title: 'My title' })
function withButton(opts = {}) {
  const options = {
    duration: 0,
    title: 'Success',
    type: 'success',
    buttonText: 'OK',
    showClose: false,
    customClass: 'notification-with-button',
    on: {
      close: notification => {
        notification.close()
      },
    },
    ...opts,
  }

  const buttonComponent = {
    render() {
      return h('div', [
        h('div', undefined, { default: () => options.message }),
        h(Actions, [
          h(
            DSButton,
            { variant: 'secondary', onClick: () => this.$emit('close') },
            {
              default: () => options.buttonText,
            }
          ),
        ]),
      ])
    },
  }

  return customComponent(buttonComponent, options)
}

function withProceedAndDismissButtons(opts = {}) {
  const { on, ...restOptions } = opts
  const options = {
    duration: 10000,
    title: 'Warning',
    type: 'warning',
    buttonProceedText: 'OK',
    buttonDismissText: 'Dismiss',
    showClose: false,
    customClass: 'notification-with-button-and-dismiss',
    on: {
      dismiss: notification => {
        notification.close()
      },
      proceed: notification => {
        notification.close()
      },
      ...on,
    },
    ...restOptions,
  }

  const buttonComponent = {
    render() {
      return h('div', [
        h('div', options.message),
        h(Actions, [
          h(
            DSButton,
            { variant: 'secondary', onClick: () => this.$emit('dismiss') },
            options.buttonDismissText
          ),
          h(
            DSButton,
            {
              onClick: () => this.$emit('proceed'),
            },
            options.buttonProceedText
          ),
        ]),
      ])
    },
  }

  return customComponent(buttonComponent, options)
}

const customSuccess = (options = {}) => {
  return Notification({
    type: 'success',
    duration: 10000,
    showClose: true,
    ...options,
  })
}

const customError = (options = {}) => {
  return Notification({
    type: 'error',
    duration: 10000,
    showClose: true,
    ...options,
  })
}

export default {
  message: Notification,
  notification: Notification,
  alert: MessageBox.alert,
  prompt: MessageBox.prompt,
  confirm: MessageBox.confirm,
  box: MessageBox,

  error: addMessageType('error'),
  success: addMessageType('success'),
  info: addMessageType('info'),
  warning: addMessageType('warning'),
  component: customComponent,
  withButton,
  withProceedAndDismissButtons,
  customSuccess,
  customError,
}
