// TODO: Break this file down - it's currently too big
import accounting from 'accounting'
import { formatInTimeZone } from 'date-fns-tz'
import dateFnsFormat from 'date-fns/format'
import camelCase from 'lodash/camelCase'
import get from 'lodash/get'
import isNumber from 'lodash/isNumber'
import mapKeys from 'lodash/mapKeys'
import padStart from 'lodash/padStart'
import snakeCase from 'lodash/snakeCase'

export function customError(message, meta = {}) {
  const error = new Error(message)
  Object.assign(error, meta)
  return error
}

/**
 * Maps each value to a new value using the
 * passed-in function
 * @param  {Object}   obj the object to map
 * @param  {Function} fn  the mapping function
 * @return {Object}       the new object
 */
function mapObject(obj, fn) {
  return Object.keys(obj).reduce((res, key) => {
    res[key] = fn(obj[key], key)
    return res
  }, {})
}

/**
 * Deep map all leaf values using the
 * passed-in function
 * @param  {Object}   obj the object to map
 * @param  {Function} fn  the mapping function
 * @return {Object}       the new object
 */
export function deepMap(obj, fn) {
  const deepMapper = (val, key) => {
    return typeof val === 'object' && val !== null && val !== undefined
      ? deepMap(val, fn)
      : fn(val, key)
  }
  if (Array.isArray(obj)) {
    return obj.map(deepMapper)
  }
  if (obj instanceof Date) {
    return fn(obj)
  }
  if (typeof obj === 'object') {
    return mapObject(obj, deepMapper)
  }
  return obj
}

/**
 * Simply returns the data property of the
 * passed-in object (useful in promise-chains)
 * @param  {Object} obj the object
 * @return {Object}     the data property object
 */
export const getData = obj => get(obj, 'data')

/**
 * Converts a template + data into a rendered string
 * @param  {Function} template the template
 * @param  {Object} data     the data
 * @return {String}          the rendered string
 */
export function templateString(template, data) {
  return template(data)
}

/**
 * Returns the current URL
 * @return {String} the current URL
 */
export function getUrl() {
  const port = window.location.port
  return (
    window.location.protocol +
    '//' +
    window.location.hostname +
    (port ? ':' + port : '')
  )
}

function getParentNode(element) {
  if (element.nodeName === 'HTML') {
    return element
  }
  return element.parentNode || element.host
}

function getStyleComputedProperty(element, property) {
  if (element.nodeType !== 1) {
    return []
  }
  // NOTE: 1 DOM access here
  const css = getComputedStyle(element, null)
  return property ? css[property] : css
}

/**
 * Finds nearest scrolling parent of an element
 * @param  {HTMLElement} element the element
 * @return {HTMLElement} the nearest scrolling parent
 */
export function getScrollParent(element) {
  // Return body, `getScroll` will take care to get the correct `scrollTop` from it
  if (!element) {
    return document.body
  }

  switch (element.nodeName) {
    case 'HTML':
    case 'BODY':
      return element.ownerDocument.body
    case '#document':
      return element.body
  }

  // Firefox want us to check `-x` and `-y` variations as well
  const { overflow, overflowX, overflowY } = getStyleComputedProperty(element)
  if (/(auto|scroll|overlay)/.test(overflow + overflowY + overflowX)) {
    return element
  }

  return getScrollParent(getParentNode(element))
}

/**
 * Checks if the argument is a dom element
 * @param  {Object} element the object to test
 * @return {Boolean} whether the object is a dom element
 */
export function isDomElement(element) {
  return element && typeof element === 'object' && 'nodeType' in element
}

/**
 * Smooth-scrolls the page to a specific element
 * @param  {String} className the element's className
 * @return {undefined}
 */
export function scrollToElement(
  className,
  { behavior = 'smooth', block = 'start' } = {}
) {
  const element = isDomElement(className)
    ? className
    : document.querySelector(className)
  if (element) {
    element.scrollIntoView({ behavior, block })
  }
}

/**
 * Smooth-scrolls element to a specific location
 * @param  {String} className the element's className
 * @return {undefined}
 */
export function scrollTo(
  className,
  { left = 0, top = 0, behavior = 'smooth' } = {}
) {
  const element = isDomElement(className)
    ? className
    : document.querySelector(className)
  if (element) {
    element.scrollTo({ left, top, behavior })
  }
}

/**
 * Is this object a function?
 * @param  {Object}  obj The object to test
 * @return {Boolean}
 */
export function isFunction(obj) {
  return !!(obj && obj.constructor && obj.call && obj.apply)
}

/**
 * Is this object a string?
 * @param  {Object}  obj The object to test
 * @return {Boolean}     [description]
 */
export function isString(obj) {
  return obj instanceof String || typeof obj === 'string'
}

/**
 * Converts a comma-separated text string into and array of values, ignoring linebreaks and quoted values
 *  * e.g.
 *   input:
 *     a,b,"x, y"
 *     c,d
 *   output:
 *     [ 'a', 'b', '"x', 'y"', 'c', 'd' ]
 * @param  {String} text The text to convert
 * @return {Array}      The converted array
 */
export function csvToArray(text) {
  if (!text) {
    return []
  }
  return text
    .trim()
    .split(/[\r\n,\s]+/)
    .map(item => item.trim())
    .filter(item => item !== '')
}

/**
 * Converts a csv text string into a two-dimensional array, respecting line-breaks and quoted values
 * e.g.
 *   input:
 *     a,b,"x, y"
 *     c,d
 *   output:
 *     [ [ 'a', 'b', 'x, y' ], [ 'c', 'd' ] ]
 * @param  {String} text The text to convert
 * @return {Array}      The converted array
 */
export function csvToArray2d(text) {
  const objPattern = new RegExp(
    // Delimiters.
    '(\\,|\\r?\\n|\\r|^)' +
      // Quoted fields.
      '(?:"([^"]*(?:""[^"]*)*)"|' +
      // Standard fields.
      '([^"\\,\\r\\n]*))',
    'gi'
  )

  const arrData = [[]]
  let arrMatches = null
  while ((arrMatches = objPattern.exec(text))) {
    const strMatchedDelimiter = arrMatches[1]

    if (strMatchedDelimiter.length && strMatchedDelimiter !== ',')
      arrData.push([])

    let strMatchedValue

    if (arrMatches[2]) {
      strMatchedValue = arrMatches[2].replace(new RegExp('""', 'g'), '"')
    } else {
      strMatchedValue = arrMatches[3]
    }

    arrData[arrData.length - 1].push(strMatchedValue.trim())
  }

  return arrData
}

/**
 * Converts an array like [1,2,3,4,5,6] to [[1,2], [3,4], [5,6]]
 * @param  {[type]} arr the array to convert
 * @return {[type]}     the converted array
 */
export function arrayToPairs(arr) {
  const pairs = []
  for (let i = 0; i < arr.length; i += 2) {
    if (arr[i + 1] !== undefined) {
      pairs.push([arr[i], arr[i + 1]])
    } else {
      pairs.push([arr[i]])
    }
  }
  return pairs
}

/**
 * Converts all object keys to snake_case
 * @param  {Object} obj The object to convert
 * @return {Object}     the converted object
 */
export function snakeCaseObject(obj) {
  return mapKeys(obj, (_value, key) => {
    return snakeCase(key)
  })
}

/**
 * Converts all object keys to camelCase
 * @param  {Object} obj The object to convert
 * @return {Object}     the converted object
 */
export function camelCaseObject(obj) {
  return mapKeys(obj, (_value, key) => {
    return camelCase(key)
  })
}

/**
 * Call function n times
 * times(10
 * @param  {[type]} n number of iterations
 * @return {[type]}  nil
 */
export function times(n, fn) {
  for (let i = 0; i < n; i++) {
    fn(i)
  }
}

export function formatDate(date, format = 'dd/MM/yyyy', options) {
  if (typeof date === 'string') {
    date = new Date(date)
  }
  return dateFnsFormat(date, format, options)
}

export function formatInUtc(date, format = 'do MMMM yyyy') {
  return formatInTimeZone(date, 'UTC', format)
}

export function formatNumber(number, precision = 2) {
  return accounting.formatNumber(number, precision)
}

export function formatMoney(amount, precision = 2) {
  amount = amount || 0
  // Round fractional monetary values to the nearest 1p
  return formatNumber(Math.round(amount) / 100, precision)
}

/**
 *
 * @param {String} currencySymbol '€', '$', '£'
 * @param {*} amount
 * @param {*} precision
 * @returns {String} eg. '€1,234.56'
 */
export function formatMoneyWithCurrencySymbol(
  currencySymbol,
  amount,
  precision = 2
) {
  return `${
    currencySymbol.match(/[€$£]/g)
      ? currencySymbol
      : codeToSymbol[currencySymbol]
  }${formatMoney(amount, precision)}`
}

/**
 * For GBP this would be converting pounds to pennies
 * e.g. 1.50 = 150
 * @param amount - amount to be converted into subunits
 */
export function convertMoneyFromBaseUnitToSubunits(amount) {
  return amount * 100
}

/**
 * For GBP this would be converting pennies to pounds
 * e.g. 150 = 1.50
 * @param amount - amount to be converted into base unit
 */
export function convertMoneyFromSubunitsToBaseUnit(amount) {
  return amount / 100
}

export function padTime(time) {
  return padStart(time, 2, '0')
}

export function formatTime(time) {
  if (!isNumber(time)) {
    return ''
  }
  const seconds = Math.trunc(time) % 60
  const minutes = Math.trunc(time / 60) % 60
  const hours = Math.trunc(time / 60 / 60)
  return `${padTime(hours)}:${padTime(minutes)}:${padTime(seconds)}`
}

export function findComponentsByName(vm, name) {
  const components = []
  vm.$children.forEach(child => {
    if (child.$options.name === name) {
      components.push(child)
    }
    components.push(...findComponentsByName(child, name))
  })
  return components
}

export function getUniqueObjectsInArrayByKey(array, key) {
  return [...new Map(array.map(item => [item[key], item])).values()]
}

export const codeToSymbol = {
  GBP: '£',
  USD: '$',
  EUR: '€',
}

export const codeToUnicode = {
  GBP: '\xA3',
  USD: '\x24',
  EUR: '\xAC',
}

/**
 * Format a number to a currency string by currency code
 * @param {String} currencyCode 'GBP', 'USD', 'EUR'
 * @param {Number} amount 500 = 5.00
 * @param {Number} decimalPlaces (Optional) 2 = 5.00
 * @returns {String} e.g. '£5.00'
 */
export function formatMoneyWithLocalCurrencySymbol(
  currencyCode,
  amount,
  decimalPlaces = 2
) {
  return new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'narrowSymbol',
  }).format(Math.round(amount) / 10 ** decimalPlaces)
}

/**
 * Create a list from array of strings
 * locale is the language code eg. 'en-GB' and can be set for i18n with navigator.language
 * @param {Array} list Array of strings
 * @param {String} [locale] (Optional): Default 'en'
 * @param {Object} [options] (Optional): https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/ListFormat/ListFormat#parameters
 * @returns {String} eg. '1st, 2nd, 3rd, 4th, and 5th'
 */
export function createStringListFromArray(list, locale = 'en', options = {}) {
  return new Intl.ListFormat(locale, options).format(list)
}

/**
 * Deconstruct a name into first and last name
 * @param {String} name
 * @returns {Object}
 */
export function deconstructName(name) {
  const splitName = name.split(' ')
  const lastName = splitName.length > 1 ? splitName[1] : ''
  return {
    firstName: splitName[0],
    lastName,
  }
}

export function focusInput(ref) {
  const elementRef = ref && ref.$refs.input
  if (elementRef) {
    elementRef.focus()
  }
}
/**
 *
 * @param {String} start start date as string: 2021-01-01
 * @param {String} end end date as string: 2021-01-02
 * @returns {Boolean} whether _today_ is between the two dates (inclusive)
 */
export function todayIsBetween(start, end) {
  const [today, startDate, endDate] = [
    new Date(),
    new Date(start),
    new Date(end),
  ].map(date => date.setHours(0, 0, 0, 0))
  return today >= startDate && today <= endDate
}

/**
 * Calculate a percentage of a out of b
 */
export function calculatePercentage(a, b) {
  const percentage = Math.round((a * 100) / b)

  if (Number.isNaN(percentage)) {
    return 0
  }

  return percentage
}

export { createAndFillArray } from './createAndFillArray'

/**
 * @param {Number} offset positive or negative days to offset by
 * @returns {String} the resulting offset date
 * Create a date with an offset from today
 */
export function createDateWithOffset(offset) {
  return new Date(new Date().setDate(new Date().getDate() + offset))
    .toISOString()
    .split('T')[0]
}

/**
 * Extracts error message from error object
 * @param {object} error error
 * @returns {string} error message
 */
export function getErrorMessage(error) {
  return error?.message || error?.detail || 'Unknown error occurred'
}
