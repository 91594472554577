import { parseISO, addDays, addMinutes, isAfter } from 'date-fns'
import Cookies from 'js-cookie'

type State = {
  count: number
  lastDismissed: string | null
  lastEnabled: string | null
}

/**
 * Manages state for a dismissible account protection banner promoting multifactor authentication.
 *
 * This class controls the visibility of the AccountProtectionBanner component based on:
 * 1. The number of times the banner has been dismissed
 * 2. The time elapsed since the last dismissal
 *
 * It uses cookies to persist the banner's state across sessions, ensuring a balanced
 * approach between promoting security and respecting user preferences.
 *
 * @property {string} COOKIE_NAME - Name of the cookie storing banner dismissal state
 * @property {number} MAX_DISMISSALS - Maximum number of times the banner can be dismissed
 * @property {number} SHOW_INTERVAL_MS - Minimum time interval between banner displays (in milliseconds)
 *
 * @example
 * const manager = new StateManager();
 * if (manager.shouldShowBanner()) {
 *   // Render AccountProtectionBanner component
 * }
 *
 * // When user dismisses the banner
 * manager.update();
 */
class StateManager {
  private readonly COOKIE_NAME = 'banner_dismissals'
  private readonly MAX_DISMISSALS = 3
  private readonly SHOW_INTERVAL_DAYS = 7
  private readonly ENABLE_COOLDOWN_MINUTES = 60

  private state: State

  constructor() {
    this.state = this.get()
  }

  private getDefaultState(): State {
    return { count: 0, lastDismissed: null, lastEnabled: null }
  }

  private get(): State {
    const cookieValue = Cookies.get(this.COOKIE_NAME)
    if (!cookieValue) {
      return this.getDefaultState()
    }

    try {
      const parsedState = JSON.parse(cookieValue) as Partial<State>

      return {
        count: parsedState.count ?? 0,
        lastDismissed: parsedState.lastDismissed ?? null,
        lastEnabled: parsedState.lastEnabled ?? null,
      }
    } catch (error) {
      return this.getDefaultState()
    }
  }

  private save(): void {
    Cookies.set(this.COOKIE_NAME, JSON.stringify(this.state), { expires: 365 })
  }

  public update(): void {
    this.state.count += 1
    this.state.lastDismissed = new Date().toISOString()
    this.save()
  }

  public updateLastEnabled(): void {
    this.state.lastEnabled = new Date().toISOString()
    this.save()
  }

  public remove(): void {
    Cookies.remove(this.COOKIE_NAME)
    this.state = this.getDefaultState()
  }

  public shouldShowBanner(): boolean {
    const now = new Date()

    if (this.state.count >= this.MAX_DISMISSALS) {
      return false
    }

    if (this.state.lastEnabled) {
      const lastEnabledDate = parseISO(this.state.lastEnabled)
      const cooldownEndDate = addMinutes(
        lastEnabledDate,
        this.ENABLE_COOLDOWN_MINUTES
      )
      if (isAfter(cooldownEndDate, now)) {
        return false
      }
    }

    if (!this.state.lastDismissed) {
      return true
    }

    const lastDismissedDate = parseISO(this.state.lastDismissed)
    const nextShowDate = addDays(lastDismissedDate, this.SHOW_INTERVAL_DAYS)

    return isAfter(now, nextShowDate)
  }

  public getState(): State {
    return { ...this.state }
  }
}

export default StateManager
