import {
  denormalize as _denormalize,
  normalize as _normalize,
  schema,
} from 'normalizr'
import shortid from 'shortid'
import { deepMap } from '@/utils'

const researcher = new schema.Entity('researcher')

const study = new schema.Entity('study', {
  researcher,
})

const studies = [new schema.Entity('studies')]

const submission = new schema.Entity('submission')

const submissions = [new schema.Entity('submissions')]

const message = new schema.Entity('message')

const messages = [new schema.Entity('messages')]

const questions = [new schema.Entity('questions')]

const categories = [
  new schema.Entity('categories', {
    children: questions,
  }),
]

const schemas = {
  studies,
  study,
  submissions,
  submission,
  message,
  categories,
  messages,
}

// Adds IDs to all Objects if they are missing
export function idize(data) {
  if (data === undefined || data === null || typeof data !== 'object') {
    return data
  }
  if (Array.isArray(data)) {
    return data.map(datum => idize(datum))
  }
  if (
    !Object.prototype.hasOwnProperty.call(data, 'id') ||
    data['id'] === null
  ) {
    data.id = `_${shortid.generate()}`
  }
  return Object.keys(data).reduce((acc, key) => {
    acc[key] = idize(data[key])
    return acc
  }, {})
}

// Removes any IDs that were added using 'idize' method
export function deidize(data) {
  return deepMap(data, (val, key) => {
    if (key === 'id' && val.charAt(0) === '_') {
      // remove any ids we added during the idize
      return null
    }
    return val
  })
}

export function normalize(data, schemaName) {
  if (!Object.prototype.hasOwnProperty.call(schemas, schemaName)) {
    let entity = new schema.Entity(schemaName)
    if (Array.isArray(data)) {
      entity = [entity]
    }
    return _normalize(data, entity)
  }
  return _normalize(data, schemas[schemaName])
}

export function denormalize(schema, { result, entries }) {
  return _denormalize(result, schemas[schema], entries)
}
