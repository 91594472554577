/// <reference types="gtag.js" />
import { createProxy } from './helpers/createProxy'

type Extensions = {
  config(config: ConfigParams): void
  event(eventName: Gtag.EventNames | string, eventParams?: ConfigParams): void
}

type ConfigParams = Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams

const gtag = createProxy<Gtag.Gtag & Extensions>({
  name: 'gtag',
  klaroConsentName: 'google-analytics',
})

gtag.config = function (...args) {
  gtag('config', import.meta.env['VUE_APP_GA_UA_ID'], ...args)
  gtag('config', import.meta.env['VUE_APP_GA_AW_ID'])
}

gtag.event = function (...args) {
  gtag('event', ...args)
}

export default gtag
