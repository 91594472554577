import type { Module } from 'vuex'
import { http, endpoints } from '@/api'
import type { ParticipantReferralCampaign } from '@/api/types'

export type State = {
  campaigns: ParticipantReferralCampaign[]
}

const SET_REFERRAL_CAMPAIGNS = 'SET_REFERRAL_CAMPAIGNS'
const SET_REFERRAL_CAMPAIGN = 'SET_REFERRAL_CAMPAIGN'

export default {
  namespaced: true,

  state: {
    campaigns: [],
  } as State,

  mutations: {
    [SET_REFERRAL_CAMPAIGNS](
      state: State,
      campaigns: ParticipantReferralCampaign[]
    ) {
      state.campaigns = campaigns
    },
    [SET_REFERRAL_CAMPAIGN](
      state: State,
      updatedCampaign: ParticipantReferralCampaign
    ) {
      // Update the campaign in the list of campaigns with the updated campaign
      state.campaigns = state.campaigns.map(campaign => {
        if (campaign.id === updatedCampaign.id) {
          return { ...campaign, ...updatedCampaign }
        }

        return campaign
      })
    },
  },

  actions: {
    async getReferralCampaigns({ commit }) {
      const campaigns = await http.get<{
        results: ParticipantReferralCampaign[]
        meta: { count: number }
      }>(endpoints.PARTICIPANT_REFERRAL_CAMPAIGN)

      if (campaigns.results.length) {
        commit(SET_REFERRAL_CAMPAIGNS, campaigns.results)
      }
    },

    async sendReferrals({ commit }, { campaignId, emails }) {
      const updatedCampaign = await http.patch(
        endpoints.REFERRAL_CAMPAIGN(campaignId),
        {
          invites: emails,
        }
      )

      commit(SET_REFERRAL_CAMPAIGN, updatedCampaign)
    },
  },

  getters: {
    // Get the first referral campaign in the list, which will be the most recent
    referralCampaign: ({ campaigns }) => campaigns[0],

    campaignUnavailable(_state, { referralCampaign }) {
      return !referralCampaign || referralCampaign.status === 'COMPLETE'
    },

    remainingReferrals: (_state, { referralCampaign, campaignUnavailable }) => {
      if (campaignUnavailable) {
        return 0
      }

      return (
        referralCampaign.max_num_participants -
        referralCampaign.number_of_invitations_sent
      )
    },

    totalAwaitingAction: ({ campaigns }) => {
      return campaigns.reduce((total, campaign) => {
        return total + campaign.num_of_awaiting_action_referrals
      }, 0)
    },

    totalSignedUp: ({ campaigns }) => {
      // Calculate total signed up for all campaigns.
      // This means the user has signed up to the waitlist form, and then created an account. User has also passed onboarding
      return campaigns.reduce((total, campaign) => {
        return total + campaign.num_of_successful_signups
      }, 0)
    },

    totalSuccessfulReferrals: ({ campaigns }) => {
      // Calculate total successful referrals for all campaigns
      // Same as total signed up, but user has done the amount ot studies required from the campaign they joined from
      return campaigns.reduce((total, campaign) => {
        return total + campaign.num_of_successful_referrals
      }, 0)
    },

    totalIncompleteReferrals: ({ campaigns }) => {
      // Calculate total incomplete for all campaigns
      // User filled the waitlist form, but didnt got fast tracked cause didnt meet the criteria
      // or user didnt pass onboarding
      return campaigns.reduce((total, campaign) => {
        return total + campaign.num_of_incomplete_referrals
      }, 0)
    },

    totalEarned: ({ campaigns }) => {
      // Calculate total earned from successful referrals for all campaigns
      return campaigns.reduce((total, campaign) => {
        return (
          total +
          campaign.num_of_successful_referrals * campaign.reward_amount_in_pence
        )
      }, 0)
    },
  },
} satisfies Module<State, unknown>
