// import * as Vue from 'vue'
import axios from 'axios'
import { BREAKPOINTS } from '@/constants'
import { initLaunchDarkly } from '@/integrations/launchDarkly'

export const types = {
  SET_ERROR: 'SET_ERROR',
  SET_MESSAGE: 'SET_MESSAGE',
  SET_LOADING: 'SET_LOADING',
  SET_LAUNCHDARKLY_INIT_PROMISE: 'SET_LAUNCHDARKLY_INIT_PROMISE',
  TOGGLE_NAV: 'TOGGLE_NAV',
  SHOW_NAV: 'SHOW_NAV',
  SHOW_ACCOUNT_MENU: 'SHOW_ACCOUNT_MENU',
  SHOW_MESSAGES: 'SHOW_MESSAGES',
  SET_FLEX: 'SET_FLEX',
  SET_VERSION: 'SET_VERSION',
  SET_CLIENT_WIDTH: 'SET_CLIENT_WIDTH',
  HYPERWALLET_ONBOARDING_MODAL_VISIBLE: 'HYPERWALLET_ONBOARDING_MODAL_VISIBLE',
  SET_LONG_LOADING: 'SET_LONG_LOADING',
}

const state = {
  loading: false,
  launchDarklyInitPromise: null,
  showNavDropdown: false,
  showAccountMenu: false,
  error: false,
  maintenance: false,
  messagesVisible: false,
  message: null,
  contentIsFlex: true,
  incident: null,
  version: null,
  clientWidth: window.innerWidth,
  hyperWalletOnboardingModalVisible: false,
  longLoading: false,
}

const mutations = {
  [types.SET_MESSAGE](state, message) {
    state.message = message
  },

  [types.SET_LOADING](state, loading) {
    state.loading = loading
  },

  [types.SET_LONG_LOADING](state, loading) {
    state.longLoading = loading
  },

  [types.SET_LAUNCHDARKLY_INIT_PROMISE](state, promise) {
    state.launchDarklyInitPromise = promise
  },

  [types.TOGGLE_NAV](state) {
    state.showNavDropdown = !state.showNavDropdown
  },

  [types.SHOW_NAV](state, show) {
    state.showNavDropdown = show
  },

  [types.SHOW_ACCOUNT_MENU](state, show) {
    state.showAccountMenu = show
  },

  [types.SET_ERROR](state, value) {
    state.error = value
  },

  [types.SHOW_MESSAGES](state, value) {
    state.messagesVisible = value
  },

  [types.SET_FLEX](state, value) {
    state.contentIsFlex = value
  },

  [types.SET_VERSION](state, value) {
    state.version = value
  },

  [types.SET_CLIENT_WIDTH](state, value) {
    state.clientWidth = value
  },

  [types.HYPERWALLET_ONBOARDING_MODAL_VISIBLE](state, value) {
    state.hyperWalletOnboardingModalVisible = value
  },
}

const actions = {
  setMessage({ commit }, message) {
    commit(types.SET_MESSAGE, message)
  },

  setLongLoading({ commit }, loading) {
    commit(types.SET_LONG_LOADING, loading)
  },

  setLoading({ commit }, loading) {
    return new Promise(resolve => {
      if (!loading) {
        commit(types.SHOW_NAV, false)
        commit(types.SHOW_ACCOUNT_MENU, false)
        setTimeout(() => {
          commit(types.SET_LOADING, false)
          resolve()
        }, 500)
      } else {
        commit(types.SET_ERROR, false)
        commit(types.SET_LOADING, true)
        resolve()
      }
    })
  },

  initLaunchDarkly({ commit }) {
    const launchDarklyInitPromise = initLaunchDarkly()
    commit(types.SET_LAUNCHDARKLY_INIT_PROMISE, launchDarklyInitPromise)
  },

  toggleNav({ commit }) {
    commit(types.TOGGLE_NAV)
  },

  showNav({ commit }, show) {
    commit(types.SHOW_NAV, show)
  },

  showAccountMenu({ commit }, show) {
    commit(types.SHOW_ACCOUNT_MENU, show)
  },

  setError({ commit, dispatch }, value) {
    if (value !== false) {
      dispatch('setLoading', false)
    }
    commit(types.SET_ERROR, value)
  },

  showMessages({ commit }, value) {
    commit(types.SHOW_MESSAGES, value)
  },

  setFlex({ commit }, value) {
    commit(types.SET_FLEX, value)
  },

  checkVersion({ commit, state }) {
    return axios.get('/_check_version').then(res => {
      const { version: currentVersion } = state
      const version = res.data
      commit(types.SET_VERSION, version)
      return currentVersion !== null && currentVersion !== version
    })
  },

  setClientWidth({ commit }, value) {
    commit(types.SET_CLIENT_WIDTH, value)
  },

  showHyperwalletOnboardingModal({ commit }, show) {
    commit(types.HYPERWALLET_ONBOARDING_MODAL_VISIBLE, show)
  },
}

const getters = {
  globalLoading: state => state.loading,
  longLoading: state => state.longLoading,
  isXsWidth: ({ clientWidth }) => clientWidth < BREAKPOINTS.SM,
  isSmallWidth: ({ clientWidth }) =>
    clientWidth >= BREAKPOINTS.sm && clientWidth < BREAKPOINTS.MD,
  isMediumWidth: ({ clientWidth }) =>
    clientWidth >= BREAKPOINTS.md && clientWidth < BREAKPOINTS.LG,
  isLargeWidth: ({ clientWidth }) =>
    clientWidth >= BREAKPOINTS.lg && clientWidth < BREAKPOINTS.XL,
  isXlWidth: ({ clientWidth }) => clientWidth >= BREAKPOINTS.XL,

  isSmallWidthAndUp: ({ clientWidth }) => clientWidth >= BREAKPOINTS.SM,
  isMediumWidthAndUp: ({ clientWidth }) => clientWidth >= BREAKPOINTS.MD,
  isLargeWidthAndUp: ({ clientWidth }) => clientWidth >= BREAKPOINTS.LG,
  hyperWalletOnboardingModalVisible: state =>
    state.hyperWalletOnboardingModalVisible,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
