import type { RumGlobal } from '@datadog/browser-rum'
import { createProxy } from './helpers/createProxy'

const rum = createProxy<RumGlobal>({
  name: 'DD_RUM',
  klaroConsentName: 'datadog-rum',
  readyCallbackName: 'onReady',
})

export default rum
