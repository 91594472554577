import isEmpty from 'lodash/isEmpty'
import kebabCase from 'lodash/kebabCase'
import merge from 'ts-deepmerge'
import {
  PARTICIPANT_TOP_ABOUT_YOU_QUESTIONS_ANSWER_QUESTION,
  PARTICIPANT_TOP_ABOUT_YOU_QUESTIONS_DELETE_QUESTION,
} from '@/analytics/events'
import { endpoints, http } from '@/api'
import { QUESTIONS_TYPES } from '@/constants'
import pendo from '@/integrations/pendo'
import common, { types as commonTypes } from '../../common'
import { types as userTypes } from '../auth'

function extractQuestionsWithSpecificTag(allQuestions, specificTag) {
  const extractedQuestions = []

  for (const questionId in allQuestions) {
    const question = allQuestions[questionId]
    const tagQuestionIndex = question.tags?.findIndex(tag =>
      tag.includes(specificTag)
    )

    if (tagQuestionIndex != null && tagQuestionIndex !== -1) {
      extractedQuestions.push(question)
    }
  }

  return extractedQuestions
}

function sortAscendingBasedOnSpecificTag(questions, specificTag) {
  return questions.sort((a, b) => {
    const tagA = a.tags.find(tag => tag.includes(specificTag))
    const tagB = b.tags.find(tag => tag.includes(specificTag))

    if (tagA > tagB) {
      return 1
    }

    if (tagB > tagA) {
      return -1
    }
  })
}

function moveUnansweredQuestionsToTheEnd(questions) {
  return questions.sort((a, b) => {
    if (a.answer != null) {
      return -1
    }

    if (b.answer !== null) {
      return 1
    }
  })
}

export const types = {
  UPDATE_QUESTION: 'UPDATE_QUESTION',
  DELETE_QUESTION: 'DELETE_QUESTION',
  ADD_QUESTIONS_TO_QUESTIONS_LIST: 'ADD_QUESTIONS_TO_QUESTIONS_LIST',
  REMOVE_QUESTIONS_FROM_QUESTIONS_LIST: 'REMOVE_QUESTIONS_FROM_QUESTIONS_LIST',
  UPDATE_QUESTION_IN_QUESTIONS_LIST: 'UPDATE_QUESTION_IN_QUESTIONS_LIST',
  SKIP_QUESTION_IN_QUESTIONS_LIST: 'SKIP_QUESTION_IN_QUESTIONS_LIST',
  UPDATE_QUESTION_ERROR: 'UPDATE_QUESTION_ERROR',
  UPDATE_SHOW_DYNAMIC_QUESTIONS: 'UPDATE_SHOW_DYNAMIC_QUESTIONS',
  UPDATE_OPEN_DYNAMIC_QUESTIONS: 'UPDATE_OPEN_DYNAMIC_QUESTIONS',
  TOGGLE_OPEN_DYNAMIC_QUESTIONS: 'TOGGLE_OPEN_DYNAMIC_QUESTIONS',
}

const state = {
  categories: {},
  category: {},
  categoriesList: [],
  questions: {},
  questionsList: [],
  isQuestionError: false,
  showDynamicQuestions: false,
  openDynamicQuestions: false,
}

const mutations = {
  [types.UPDATE_QUESTION](state, { id, ...patch }) {
    const questions = { ...state.questions }
    Object.assign(questions[id], patch)
    state['questions'] = questions
  },

  [types.DELETE_QUESTION](state, { id, showDeletedState }) {
    state.categoriesList.forEach(catId => {
      const index = state.categories[catId].children.findIndex(
        itemId => itemId === id
      )
      if (index > -1) {
        if (showDeletedState) {
          state.questions[id].isDeleted = true
        } else {
          state.categories[catId].children.splice(index, 1)
          delete state.questions[id]
        }
      }
    })
  },

  [types.ADD_QUESTIONS_TO_QUESTIONS_LIST](state, questions) {
    const index = state.questionsList.findIndex(({ id }) => id === questions.id)

    if (index === -1) {
      state.questionsList.push(questions)
    } else {
      state.questionsList[index] = questions
    }
  },

  [types.REMOVE_QUESTIONS_FROM_QUESTIONS_LIST](state, questionsId) {
    const index = state.questionsList.findIndex(({ id }) => id === questionsId)

    if (index !== -1) {
      state.questionsList.splice(index, 1)
    }
  },

  [types.UPDATE_QUESTION_ERROR](state, isError) {
    state.isQuestionError = isError
  },

  [types.UPDATE_SHOW_DYNAMIC_QUESTIONS](state, value) {
    state.showDynamicQuestions = value
  },

  [types.UPDATE_OPEN_DYNAMIC_QUESTIONS](state, value) {
    state.openDynamicQuestions = value
  },

  [types.TOGGLE_OPEN_DYNAMIC_QUESTIONS](state) {
    state.openDynamicQuestions = !state.openDynamicQuestions
  },

  [types.SKIP_QUESTION_IN_QUESTIONS_LIST](
    state,
    { dynamicQuestionsId, singleQuestionId }
  ) {
    const index = state.questionsList.findIndex(
      ({ id }) => id === dynamicQuestionsId
    )
    if (index > -1) {
      const questionPosition = state.questionsList[index].questions
        .map(question => question.id)
        .indexOf(singleQuestionId)

      if (questionPosition > -1) {
        state.questionsList[index].questions[questionPosition].isSkipped = true
      }
    }
  },

  [types.UPDATE_QUESTION_IN_QUESTIONS_LIST](
    state,
    { questionsId, questionId, answer }
  ) {
    const index = state.questionsList.findIndex(({ id }) => id === questionsId)

    if (index > -1) {
      const questionPosition = state.questionsList[index].questions
        .map(question => question.id)
        .indexOf(questionId)

      if (questionPosition > -1) {
        state.questionsList[index].questions[questionPosition].answer = answer
      }
    }
  },
}

const actions = {
  subscribe() {},

  unsubscribe() {},

  fetchList({ commit }) {
    // unset any existing state for a single study
    return http.get(endpoints.QUESTIONS).then(data => {
      const categories = data.categories.map(category => {
        const questions = category.children.map(q => ({
          ...q,
          isDeleted: false,
        }))
        return {
          ...category,
          id: kebabCase(category.name),
          children: questions,
        }
      })
      commit(commonTypes.SET_LIST, categories)
    })
  },

  extractOnboardQuestions({ commit, state }) {
    const extractedQuestions = extractQuestionsWithSpecificTag(
      state.questions,
      QUESTIONS_TYPES.ONBOARD
    )

    // ![MKT-682] Hardcoded for now as it's the only category we will be using right now.
    if (extractedQuestions.length > 0) {
      // Sort the questions in the correct order
      const sortedOnboardQuestions = sortAscendingBasedOnSpecificTag(
        extractedQuestions,
        QUESTIONS_TYPES.ONBOARD
      )
      const containsAnswers = sortedOnboardQuestions.filter(
        question => question.answer !== null
      )

      const questions =
        containsAnswers.length > 0
          ? moveUnansweredQuestionsToTheEnd(sortedOnboardQuestions)
          : sortedOnboardQuestions

      const onboardQuestions = {
        id: 0,
        type: QUESTIONS_TYPES.ONBOARD,
        meta: {
          name: 'Questions about you',
          host: 'Prolific',
          description: `All your responses will be used only in accordance with our <a href="https://prolific.notion.site/Privacy-and-Legal-at-Prolific-395a0b3414cd4d84a2557566256e3d58" target="_blank">Participant Privacy Notice</a>. We'll keep your responses secure, and we'll share them with researchers only as relevant to the studies you participate in. We do this using your Prolific ID, so you won't be identified.`,
        },
        questions,
      }

      commit('ADD_QUESTIONS_TO_QUESTIONS_LIST', onboardQuestions)
    }
  },

  removeQuestionsFromQuestionsList({ commit }, questionsId) {
    commit('REMOVE_QUESTIONS_FROM_QUESTIONS_LIST', questionsId)
  },

  fetchDynamicQuestions(
    { commit, rootGetters },
    { questionCount = 0, questionsId = 1 }
  ) {
    const user = rootGetters['auth/currentUser']
    const participantId = user.id

    return http
      .get(endpoints.PARTICIPANTS_QUESTIONS_NEXT(participantId, questionCount))
      .then(data => {
        const { results } = data

        const dynamicQuestions = {
          id: questionsId,
          type: QUESTIONS_TYPES.DYNAMIC,
          meta: {
            name: 'Questions about you',
            host: 'Prolific',
          },
        }

        dynamicQuestions.questions = results.map(question => ({
          ...question,
          isDeleted: false,
          isSkipped: false,
        }))

        commit('ADD_QUESTIONS_TO_QUESTIONS_LIST', dynamicQuestions)
      })
  },

  skipDynamicQuestion(
    { commit, rootGetters },
    { dynamicQuestionsId, singleQuestionId }
  ) {
    const user = rootGetters['auth/currentUser']
    const participantId = user.id

    return http
      .post(
        endpoints.PARTICIPANT_QUESTION_SKIP(participantId, singleQuestionId)
      )
      .then(() => {
        commit('SKIP_QUESTION_IN_QUESTIONS_LIST', {
          dynamicQuestionsId,
          singleQuestionId,
        })
      })
  },

  updateAnswer(
    { commit, state },
    {
      questionsId,
      questionId,
      answer,
      showDeletedState = false,
      isOnboardingQuestion = false,
      isDynamicQuestion = false,
    }
  ) {
    if (
      answer === undefined ||
      ((typeof answer === 'object' || typeof answer === 'string') &&
        !(answer instanceof Date) &&
        isEmpty(answer))
    ) {
      answer = null
    }

    return http
      .post(endpoints.ANSWERS, { answer, question: questionId })
      .then(() => {
        if (answer === null) {
          if (showDeletedState && isOnboardingQuestion) {
            pendo.track(PARTICIPANT_TOP_ABOUT_YOU_QUESTIONS_DELETE_QUESTION, {
              question: state.questions[questionId].question,
            })
          }
          commit(types.DELETE_QUESTION, { id: questionId, showDeletedState })
        } else {
          if (isOnboardingQuestion) {
            pendo.track(PARTICIPANT_TOP_ABOUT_YOU_QUESTIONS_ANSWER_QUESTION, {
              question: state.questions[questionId].question,
              question_answer: answer,
            })
          }

          if (isDynamicQuestion) {
            commit(types.UPDATE_QUESTION_IN_QUESTIONS_LIST, {
              questionsId,
              questionId,
              answer,
            })
          }

          commit(types.UPDATE_QUESTION, { id: questionId, answer })
          commit(
            `auth/${userTypes.DECREMENT_UNANSWERED_QUESTION_COUNT}`,
            undefined,
            { root: true }
          )
        }
      })
  },

  updateQuestionError({ commit }, isError) {
    commit(types.UPDATE_QUESTION_ERROR, isError)
  },

  clearQuestionError({ commit }) {
    commit(types.UPDATE_QUESTION_ERROR, false)
  },

  toggleOpenDynamicQuestions({ commit }) {
    commit(types.TOGGLE_OPEN_DYNAMIC_QUESTIONS)
  },

  updateShowDynamicQuestions({ commit }, value) {
    commit(types.UPDATE_SHOW_DYNAMIC_QUESTIONS, value)
  },

  updateOpenDynamicQuestions({ commit }, value) {
    commit(types.UPDATE_OPEN_DYNAMIC_QUESTIONS, value)
  },
}

const getters = {
  percentCompleted: ({ questions }) => {
    const values = Object.values(questions)
    const total = values.length
    if (total === 0) {
      return 100
    }
    const answered = values.filter(question => question.answer !== null).length
    return Math.floor((answered / total) * 100)
  },

  categories: (state, getters) => {
    return state.categoriesList.map(id => getters.category(id))
  },

  category: state => id => {
    const category = state.categories[id]
    const { name, icon, children } = category
    const questions = children.map(id => state.questions[id])
    const total = questions.length
    const answered = questions.filter(
      question => question.answer !== null
    ).length
    const percentCompleted =
      total === 0 ? 100 : Math.ceil((answered / total) * 100)
    return {
      id,
      name,
      icon,
      answered,
      total,
      questions,
      percentCompleted,
    }
  },

  nextCategory:
    ({ categoriesList }) =>
    id => {
      const index = categoriesList.findIndex(item => item === id)
      return categoriesList[index + 1] || false
    },

  previousCategory:
    ({ categoriesList }) =>
    id => {
      const index = categoriesList.findIndex(item => item === id)
      return categoriesList[index - 1] || false
    },

  questionsList: ({ questionsList }) => questionsList,

  isQuestionError: ({ isQuestionError }) => isQuestionError,

  showDynamicQuestions: ({ showDynamicQuestions }) => showDynamicQuestions,

  openDynamicQuestions: ({ openDynamicQuestions }) => openDynamicQuestions,
}

export default merge(common({ entities: 'categories', entity: 'category' }), {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
})
