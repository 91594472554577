// This will be a central place to route all
// analytics tracking through and forward to
// the various integrations (snowplow etc)
import gtag from '@/integrations/gtag'
import { snowplow } from '@/integrations/snowplow'

function pageData() {
  const { href: url, pathname: path, host } = window.location
  const subdomain = host.split('.prolific.co')[0] // Leaving as .co for now as it will work either way
  return {
    subdomain,
    url,
    path,
  }
}

function page() {
  const { path, url } = pageData()
  gtag.config({
    page_path: path,
    page_location: url,
  })
  snowplow.trackPageView()
}

export default {
  page,
}
