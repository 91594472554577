import { db } from '@/firebase'
import { translate as t } from '@/i18n'
import logger from '@/utils/logger'
import notifier from '@/utils/notifier'
import {
  transformFirebaseMessages,
  transformFirebaseStudyChannelsByAttribute,
} from './transformers'
import type { MessagesGroupedByDate, Group, UpdateStudy } from './types'

export const subscribeToStudy = (
  studyId: string,
  callback: (channels: Group[], categories: Group[]) => void
) => {
  logger.log('📭 StudyMessages :: subscribing to study', {
    studyId,
  })
  return db
    .collection('studies')
    .doc(studyId)
    .collection('channels')
    .onSnapshot(
      snapshot => {
        const channels = snapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        }))
        callback(
          transformFirebaseStudyChannelsByAttribute('participant_id', channels),
          transformFirebaseStudyChannelsByAttribute('category_counts', channels)
        )
      },
      error => {
        logger.error(`🚨 StudyMessages :: ${error.message}`, error)

        if (error.code === 'unavailable') {
          return notifier.error(t('studyMessages.errors.unavailable', {}))
        }

        if (error.code === 'permission-denied') {
          return notifier.error(t('studyMessages.errors.permission_denied', {}))
        }

        return notifier.error(t('studyMessages.errors.general', {}))
      }
    )
}

export const subscribeToChannel = (
  channelId: string,
  studyId: string,
  callback: (messages: MessagesGroupedByDate, totalMessageCount: number) => void
) => {
  logger.log('📭 StudyMessages :: subscribing to channel', {
    channelId,
  })
  return db
    .collection('channels')
    .doc(channelId)
    .collection('messages')
    .where('data.study_id', '==', studyId)
    .orderBy('datetime_created', 'asc')
    .onSnapshot(
      async snapshot => {
        const messageData = await Promise.all(
          snapshot.docs.map(async doc => {
            const message = doc.data()
            const sender = await message['sender']
              .get()
              .then((user: typeof doc) => user.data())

            return {
              ...message,
              id: doc.id,
              sender,
            }
          })
        )
        const messages = transformFirebaseMessages(messageData)
        // the count minus all of the invalid messages that have been filtered out by the transformer
        const count = messages.reduce(
          (total, group) => (total += group.messages.length),
          0
        )
        callback(messages, count)
      },
      error => {
        logger.error(`🚨 StudyMessages :: ${error.message}`, error)
        if (error.code === 'unavailable') {
          return notifier.error(t('studyMessages.errors.unavailable', {}))
        }

        if (error.code === 'permission-denied') {
          return notifier.error(t('studyMessages.errors.permission_denied', {}))
        }

        return notifier.error(t('studyMessages.errors.general', {}))
      }
    )
}

/**
 * Responsible for updating the unread_count for a specific channel on
 * a specific study model.
 *
 * The study model captures all channels where the study was discussed.
 *
 * This should not be used from a participant perspective.
 */
export const setStudyUnreadCount = (
  studyId: string,
  channelId: string,
  count: number
) => {
  logger.log('📭 StudyMessages :: setting study unread count', {
    studyId,
    channelId,
    count,
  })

  const channel = db
    .collection('studies')
    .doc(studyId)
    .collection('channels')
    .doc(channelId)

  channel.get().then(doc => {
    const updateDoc: UpdateStudy = { unread_count: count }
    const channelData = doc.data()

    if (count === 0 && channelData && channelData['category_counts']) {
      const categoryCounts: Record<string, number> = {}

      Object.keys(channelData['category_counts']).forEach(key => {
        categoryCounts[key] = 0
      })

      updateDoc['category_counts'] = categoryCounts
    }

    channel.update(updateDoc).catch(error => {
      logger.error('🚨 StudyMessages :: failed to update study channels', {
        studyId,
        channelId,
        count,
        error,
      })
    })
  })
}

/**
 * Responsible for setting unread_count on all study models discussed in a
 * given channel.
 *
 * e.g. If a researcher and a participant discussed 5 studies in the Message
 * Centre channel, it would count each message per study, and set the study
 * model in Firestore accordingly.
 *
 * This should not be used from a participant perspective.
 */
export const setUnreadCountsForStudies = (
  channelId: string,
  messages: Array<unknown>,
  unreadCount?: number
) => {
  const studyCounts: Record<string, number> = messages.reduce(
    (counts: Record<string, number>, message) => {
      // It's like this because we define messages as an Array<unknown>
      if (
        message &&
        typeof message === 'object' &&
        'data' in message &&
        typeof message.data === 'object' &&
        message.data != null &&
        'study_id' in message.data
      ) {
        const studyId = message.data.study_id as string

        if (studyId) {
          counts[studyId] = unreadCount ?? (counts[studyId] || 0) + 1
        }
      }

      return counts
    },
    {}
  )

  logger.log('📭 StudyMessages :: setting unread count for studies', {
    channelId,
    studyCounts,
    unreadCount,
  })

  Object.entries(studyCounts).forEach(([studyId, count]) => {
    setStudyUnreadCount(studyId, channelId, count)
  })
}

export const getStudy = (studyId: string) => {
  return db.collection('studies').doc(studyId).get()
}
