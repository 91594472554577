import jwtDecode from 'jwt-decode'
import { featureIsEnabled } from '@/integrations/launchDarkly'
import {
  FLAG_EARS_AUTH,
  FLAG_EARS_AUTH_COOKIE_CHECK,
  FLAG_SECT_381_MFA_PHASE_1,
  FLAG_SECT_381_MFA_PHASE_1_PARTICIPANT_REGISTRATION,
  FLAG_SECT_545_MFA_RECOVERY,
} from '@/integrations/launchDarkly/active-flags'
import { isCookieFeatureToggled } from './cookieBasedFeatureFlag'

/**
 * Returns true if a token has already expired. A buffer can be passed if the user wants the function to also check if the token is expiring within the buffer time.
 *
 *
 * @param {string} token The token to check
 * @param {number} [buffer=0] The buffer time in seconds, optional.
 * @return {boolean} true if the token has expired, false if it has not.
 */
export const hasTokenExpired = (token, buffer = 0) => {
  try {
    const { exp } = jwtDecode(token)

    // Remove buffer from expiry date
    const bufferTimeInMs = buffer * 1000

    // Convert expiry date from UNIX to milliseconds and subtract buffer time
    const expiryDate = new Date(new Date(exp * 1000).getTime() - bufferTimeInMs)
    const now = new Date()

    if (now.getTime() > expiryDate.getTime()) {
      return true
    }
    return false
  } catch {
    return true
  }
}

/**
 * Determine if the new authentication system is enabled. It's considered enabled if
 * FLAG_EARS_AUTH is true. When FLAG_EARS_AUTH_COOKIE_CHECK is also true, the
 * cookie feature toggle 'newAuth' must be enabled.
 */
export const isNewAuthEnabled = () =>
  featureIsEnabled(FLAG_EARS_AUTH) &&
  (!featureIsEnabled(FLAG_EARS_AUTH_COOKIE_CHECK) ||
    isCookieFeatureToggled('newAuth'))

export const isMfaEnabled = () => featureIsEnabled(FLAG_SECT_381_MFA_PHASE_1)

export const isParticipantMfaRecoveryEnabled = () =>
  featureIsEnabled(FLAG_SECT_545_MFA_RECOVERY)

export const isMfaShownInParticipantRegistration = () =>
  featureIsEnabled(FLAG_EARS_AUTH) &&
  featureIsEnabled(FLAG_SECT_381_MFA_PHASE_1) &&
  featureIsEnabled(FLAG_SECT_381_MFA_PHASE_1_PARTICIPANT_REGISTRATION)
