import isEmpty from 'lodash/isEmpty'
import omitBy from 'lodash/omitBy'
import union from 'lodash/union'
import merge from 'ts-deepmerge'
import {
  RESEARCHER_COMPLETED_STUDY,
  RESEARCHER_INCREASE_STUDY_PLACES,
} from '@/analytics/events'
import { endpoints, http } from '@/api'
import { CredentialsClient } from '@/api/credentials'
import {
  ACTIVE,
  COMPLETED,
  DRAFT,
  isCompleted,
  isPublished,
  isScheduled,
  isStatus,
  PAUSED,
  PROCESSING,
  PUBLISHING,
} from '@/api/statuses'
import { isRepSample, isSingle, SINGLE } from '@/api/study_type'
import { getFormIdFromUrl } from '@/components/researcher/FreeTrialStudy/studyData'
import {
  DATA_COLLECTION_EXTERNAL,
  DATA_COLLECTION_SURVEY_BUILDER,
  DATA_COLLECTION_TASKFLOW,
  DEFAULT_LIST_PAGE_SIZE,
  MAX_SUBMISSIONS_PER_PARTICIPANT_DEFAULT,
  MIN_REWARD_PER_HOUR_GBP,
  MIN_REWARD_PER_HOUR_USD,
  RECOMMENDED_REWARD_PER_HOUR_GBP,
  RECOMMENDED_REWARD_PER_HOUR_USD,
  SURVEY_BUILDER_APP_KEY,
  CUSTOM_ALLOWLIST_FILTER_ID,
} from '@/constants'
import { translate } from '@/i18n'
import pendo from '@/integrations/pendo'
import pusher from '@/integrations/pusher'
import rollbar from '@/integrations/rollbar'
import rum from '@/integrations/rum'
import router from '@/router'
import common, { types as commonTypes } from '@/store/common'
import download from '@/utils/download'
import { isAccessDetailCollectionUrl } from '@/utils/isAccessDetailCollectionUrl'
import notifier from '@/utils/notifier'
import { setSubmissionsConfigField } from '@/utils/submissions-config-browser'
import { createInitialStudyFormValues } from './utils/createInitialStudyFormValues'
import { createNewCompletionCodeObject } from './utils/createNewCompletionCodeObject'

const replaceUnpublishedWithDraftStatus = studies => {
  // TEMP: replace all UNPUBLISHED statuses with DRAFT client side. Backend still uses UNPUBLISHED
  // we can mutate safely here since it's called directly after api response.
  studies.forEach(study => {
    if (study.status === 'UNPUBLISHED') {
      study.status = 'DRAFT'
    }
  })
}

const getDataCollectionType = (externalApp, url) =>
  externalApp === SURVEY_BUILDER_APP_KEY
    ? DATA_COLLECTION_SURVEY_BUILDER
    : isAccessDetailCollectionUrl(url)
    ? DATA_COLLECTION_TASKFLOW
    : DATA_COLLECTION_EXTERNAL

const transitions = {
  STOP: 'STOP',
  START: 'START',
  PAUSE: 'PAUSE',
  PUBLISH: 'PUBLISH',
  SCHEDULE_PUBLISH: 'SCHEDULE_PUBLISH',
  CANCEL_PUBLISH: 'CANCEL_PUBLISH',
}

export const types = {
  SET_TOTAL: 'SET_TOTAL',
  SET_STUDY_TYPE: 'SET_STUDY_TYPE',
  SET_STRATIFICATION: 'SET_STRATIFICATION',
  SET_TOTAL_COST: 'SET_TOTAL_COST',
  SET_LOADING_TOTAL_COST: 'SET_LOADING_TOTAL_COST',
  SET_ADJUSTMENT_PAYMENT_OPTIONS: 'SET_ADJUSTMENT_PAYMENT_OPTIONS',
  SHOW_TOPUP_HINT: 'SHOW_TOPUP_HINT',
  SET_DATA_COLLECTION: 'SET_DATA_COLLECTION',
  SET_COMPLETION_CODES: 'SET_COMPLETION_CODES',
  ADD_COMPLETION_CODE: 'ADD_COMPLETION_CODE',
  UPDATE_COMPLETION_CODE: 'UPDATE_COMPLETION_CODE',
  REMOVE_COMPLETION_CODE: 'REMOVE_COMPLETION_CODE',
  REMOVE_COMPLETION_CODE_BY_TYPE: 'REMOVE_COMPLETION_CODE_BY_TYPE',
  SET_APPLIED_FILTERS: 'SET_APPLIED_FILTERS',
  APPLY_FILTER: 'APPLY_FILTER',
  REMOVE_FILTER: 'REMOVE_FILTER',
  SET_CREDENTIALS: 'SET_CREDENTIALS',
  SET_CREDENTIAL_POOL_ID: 'SET_CREDENTIAL_POOL_ID',
  SET_ACCESS_DETAILS: 'SET_ACCESS_DETAILS',
  SET_HAS_STUDIES: 'SET_HAS_STUDIES',
  SET_LATEST_ACTIVE: 'SET_LATEST_ACTIVE',
  SET_TRIAL_STUDY_DATA: 'SET_TRIAL_STUDY_DATA',
  SET_ESTIMATED_RECRUITMENT_TIME: 'SET_ESTIMATED_RECRUITMENT_TIME',
  SET_LOADING_ESTIMATED_RECRUITMENT_TIME:
    'SET_LOADING_ESTIMATED_RECRUITMENT_TIME',
}

const pusherEvents = {
  UPDATE_STUDY_STATUS: 'UPDATE_STUDY_STATUS',
}

let pusherChannels = []

const state = {
  total: 0,
  study: {},
  costs: {
    base_cost: 0,
    fees: 0,
    representative_sample_fee: 0,
    rewards: 0,
    total_fees: 0,
    total_cost: 0,
    vat: 0,
    vat_rate: 0,
  },
  studies: {},
  studiesList: [],
  appliedFilters: [],
  studyType: 'SINGLE',
  stratification: {},
  loadingTotalCost: false,
  adjustmentPaymentOptions: [],
  dataCollection: DATA_COLLECTION_EXTERNAL,
  completionCodes: [],
  hasStudies: null,
  trialStudyData: null,
  estimatedRecruitmentTime: {},
  loadingEstimatedRecruitmentTime: false,
}

const mutations = {
  [types.SET_TOTAL](state, total) {
    state.total = total
  },

  [types.SET_STUDY_TYPE](state, studyType) {
    state.studyType = studyType || 'SINGLE'
  },

  [types.SET_STRATIFICATION](state, stratification) {
    state.stratification = stratification || {}
  },

  [types.SET_TOTAL_COST](state, costs) {
    state.costs = costs
  },

  [types.SET_LOADING_TOTAL_COST](state, loading) {
    state.loadingTotalCost = loading
  },

  [types.SET_ADJUSTMENT_PAYMENT_OPTIONS](state, adjustmentPaymentOptions) {
    state.adjustmentPaymentOptions = adjustmentPaymentOptions
  },

  [types.SET_DATA_COLLECTION](state, dataCollection) {
    state.dataCollection = dataCollection
  },

  [types.SET_COMPLETION_CODES](state, completionCodes) {
    state.completionCodes = completionCodes
  },

  [types.ADD_COMPLETION_CODE](state, completionCode) {
    state.completionCodes.push(completionCode)
  },

  [types.UPDATE_COMPLETION_CODE](state, completionCode) {
    const index = state.completionCodes.findIndex(
      ({ code }) => code === completionCode.code
    )
    if (index < 0) {
      return
    }
    const existingCompletionCode = state.completionCodes[index]
    state.completionCodes[index] = {
      ...existingCompletionCode,
      ...completionCode,
    }
  },

  [types.REMOVE_COMPLETION_CODE](state, completionCode) {
    const index = state.completionCodes.findIndex(
      ({ code }) => code === completionCode.code
    )
    if (index < 0) {
      return
    }
    state.completionCodes.splice(index, 1)
  },

  [types.REMOVE_COMPLETION_CODE_BY_TYPE](state, codeType) {
    const index = state.completionCodes.findIndex(
      ({ code_type }) => code_type === codeType
    )
    if (index < 0) {
      return
    }
    state.completionCodes.splice(index, 1)
  },

  [types.SET_APPLIED_FILTERS](state, filters) {
    state.appliedFilters = filters
  },

  [types.APPLY_FILTER](state, filter) {
    const index = state.appliedFilters.findIndex(
      ({ filter_id }) => filter_id === filter.filter_id
    )

    if (index > -1) {
      state.appliedFilters.splice(index, 1, filter)
      return
    }

    state.appliedFilters.push(filter)
  },

  [types.REMOVE_FILTER](state, filterId) {
    const index = state.appliedFilters.findIndex(
      ({ filter_id }) => filter_id === filterId
    )

    if (index > -1) {
      state.appliedFilters.splice(index, 1)
    }
  },

  [types.SET_CREDENTIALS](state, credentials) {
    state.study.credentials = credentials
  },
  [types.SET_CREDENTIAL_POOL_ID](state, credentialPoolId) {
    state.study.credential_pool_id = credentialPoolId
  },
  [types.SET_ACCESS_DETAILS](state, access_details) {
    state.study.access_details = access_details
  },
  [types.SET_HAS_STUDIES](state, hasStudies) {
    state.hasStudies = hasStudies
  },
  [types.SET_TRIAL_STUDY_DATA](state, trialStudyData) {
    state.trialStudyData = trialStudyData
  },
  [types.SET_ESTIMATED_RECRUITMENT_TIME](state, estimatedRecruitmentTime) {
    state.estimatedRecruitmentTime = estimatedRecruitmentTime
  },
  [types.SET_LOADING_ESTIMATED_RECRUITMENT_TIME](state, loading) {
    state.loadingEstimatedRecruitmentTime = loading
  },
}

const actions = {
  downloadExport({ dispatch }, studyId) {
    dispatch('global/setLoading', true, { root: true })
    // force timeout of 300s for long-running export requests
    return http
      .get(endpoints.EXPORT_SUBMISSIONS(studyId), { timeout: 300000 })
      .then(data => {
        download(data, `prolific_export_${studyId}.csv`, 'text/csv')
        dispatch('global/setLoading', false, { root: true })
      })
  },

  /**
   * Calls the Study Credentials service credentials/report endpoint to
   * retrieve a CSV report of the credential usage for the given study.
   *
   * Once the data has been retrieved the action triggers a download on the client
   * by injecting an anchor into the page and programmatically clicking it, where the
   * href of the anchor is an objectURL of the CSV contents.
   *
   * @param {*} defaultActionArguments
   * @param {string} credentialPoolId - ID of the credential pool
   * @returns {Promise<void>}
   */
  downloadCredentialReportViaPool({ dispatch, rootGetters }, credentialPoolId) {
    dispatch('global/setLoading', true, { root: true })

    const token = rootGetters['oidc/oidcAccessToken']
    const client = new CredentialsClient(token)

    return client
      .getReportFromPool(credentialPoolId)
      .then(data => {
        download(
          data,
          `prolific_credential_usage_report_${credentialPoolId}.csv`,
          'text/csv'
        )
      })
      .catch(err =>
        rollbar.error(
          `Error while fetching credential pool report for pool ${credentialPoolId}: ${err}`,
          err
        )
      )
      .finally(() => {
        dispatch('global/setLoading', false, { root: true })
      })
  },

  fetchStratification({ commit }, { places, type }) {
    return http
      .get(endpoints.STRATIFICATION, {
        params: { n: places, study_type: type },
      })
      .then(stratification => {
        commit(types.SET_STRATIFICATION, stratification.results)
      })
  },

  unsubscribeAll() {
    pusherChannels.forEach(id => pusher.unsubscribe(id))
    pusherChannels = []
  },

  subscribe({ commit }, id) {
    const channel = pusher.subscribe(id)
    pusherChannels.push(channel)
    // unsubscribe/unbind first
    channel.unbind()
    channel.bind(
      pusherEvents.UPDATE_STUDY_STATUS,
      ({ status, error: statusError }) => {
        const patch = omitBy(
          { id, status, statusError },
          val => val === undefined
        )
        if (isCompleted(status)) {
          pendo.track(RESEARCHER_COMPLETED_STUDY, { id })
        }
        if (status && status.toUpperCase() === 'UNPUBLISHED') {
          // TEMP: replace all UNPUBLISHED statuses with DRAFT client side
          patch.status = 'DRAFT'
        }
        commit(commonTypes.UPDATE, patch)
      }
    )
  },

  fetchList(
    { commit, dispatch, rootGetters },
    {
      draft = false,
      drafts = false,
      active = false,
      completed = false,
      scheduled = false,
      page = 1,
      page_size = DEFAULT_LIST_PAGE_SIZE,
      projectId,
      signal,
    } = {}
  ) {
    const researcherId = rootGetters['auth/user']?.id
    const params =
      draft || drafts
        ? { published: 0 }
        : active
        ? { live: 1 }
        : completed
        ? { completed: 1 }
        : scheduled
        ? { state: '(SCHEDULED)' }
        : {}
    params.page = page
    params.page_size = page_size
    if (!projectId) {
      // TODO: this might not be needed anymore
      params.researcher = researcherId
    }
    const endpoint = projectId
      ? endpoints.PROJECT_STUDIES(projectId)
      : endpoints.STUDIES

    return http
      .get(endpoint, {
        params,
        signal,
      })
      .then(({ meta, results: studies }) => {
        rum.setGlobalContextProperty('studies_count', studies.length)
        dispatch('unsubscribeAll')
        replaceUnpublishedWithDraftStatus(studies)
        commit(commonTypes.SET_LIST, studies)
        commit(types.SET_TOTAL, meta.count)
        studies.forEach(({ id, status }) => {
          if (!isStatus(status, [DRAFT, COMPLETED])) {
            dispatch('subscribe', id)
          }
        })
        rum.setGlobalContextProperty('studies_count', studies.length)
      })
  },

  fetchHasStudies({ commit, rootGetters }) {
    const userId = rootGetters['auth/user']?.id

    return http
      .get(endpoints.HAS_NONE_DRAFT_STUDIES(userId))
      .then(({ has_studies }) => {
        commit(types.SET_HAS_STUDIES, has_studies)
      })
  },

  setHasStudiesTrue({ commit }) {
    commit(types.SET_HAS_STUDIES, true)
  },

  clearList({ commit, dispatch }) {
    commit(commonTypes.SET_LIST, [])
    commit(types.SET_TOTAL, 0)
    dispatch('unsubscribeAll')
  },

  fetchUncompletedProjectStudies({ commit, dispatch }, { projectId, signal }) {
    return dispatch('fetchProjectStudies', {
      projectId,
      completed: false,
      signal,
    }).then(studies => {
      dispatch('unsubscribeAll')
      replaceUnpublishedWithDraftStatus(studies)
      commit(commonTypes.SET_LIST, studies)
      studies.forEach(({ id, status }) => {
        if (!isStatus(status, [DRAFT])) {
          dispatch('subscribe', id)
        }
      })
      return studies
    })
  },

  fetchCompletedProjectStudies({ commit, dispatch }, { projectId, signal }) {
    return dispatch('fetchProjectStudies', {
      projectId,
      completed: true,
      signal,
    }).then(studies => {
      commit(commonTypes.APPEND_LIST, studies)
      return studies
    })
  },

  fetchProjectStudies(_, { projectId, completed, signal }) {
    return http
      .get(endpoints.PROJECT_STUDIES(projectId), {
        signal,
        params: {
          completed: +completed,
        },
      })
      .then(({ results: studies }) => {
        return studies
      })
  },

  fetchTrialStudyData({ commit }, { projectId }) {
    return http
      .get(endpoints.PROJECT_STUDIES(projectId), {})
      .then(({ results: studies }) => {
        if (studies.length === 0) {
          return null
        }
        const trialStudy = studies.find(
          study => study.is_trial_study && study.status !== 'UNPUBLISHED'
        )

        if (!trialStudy) {
          return null
        }

        const url = trialStudy.external_study_url
        const formId = getFormIdFromUrl(url)
        if (!formId) {
          return null
        }

        const trialStudyData = {
          formId,
          studyId: trialStudy.id,
          status: trialStudy.status,
        }

        commit(types.SET_TRIAL_STUDY_DATA, trialStudyData)
      })
  },

  async fetch({ state, commit, dispatch, rootGetters }, id) {
    const beforePath = router.currentRoute.value.path
    const study = await http.get(endpoints.STUDY(id))
    if (router.currentRoute.value.path !== beforePath) {
      // Don't load study if the route changed between the request/response
      // This fixes an issue where changing a route during a ongoing
      // request can load outdated data
      const error = new Error()
      error.code = 'ROUTE_CHANGED'
      return Promise.reject(error)
    }
    if (study.status?.toUpperCase() === 'UNPUBLISHED') {
      // TEMP: replace all UNPUBLISHED statuses with DRAFT client side
      study.status = 'DRAFT'
    }

    const dataCollection = getDataCollectionType(
      study.external_app,
      study.external_study_url
    )

    if (dataCollection === DATA_COLLECTION_SURVEY_BUILDER) {
      await dispatch('researcher/surveys/fetch', study.external_id, {
        root: true,
      })
    }

    /**
     * @todo - Remove this once the backend can handle task_name's on access deets.
     */
    if (dataCollection === DATA_COLLECTION_TASKFLOW) {
      study.access_details = study.access_details.map((ad, i) => ({
        ...ad,
        task_name: `URL ${i}`,
      }))
    }

    const credentialsRequiredForStudy = study.id && study.has_credentials

    if (credentialsRequiredForStudy) {
      try {
        const token = rootGetters['oidc/oidcAccessToken']
        const client = new CredentialsClient(token)

        const credentialPool = await client
          .getCredentialPool(study.credential_pool_id)
          .then(data =>
            data.credentials.reduce(
              (aggregate, cred) => {
                return {
                  credentials: [
                    ...aggregate.credentials,
                    {
                      username: cred.authenticationDetails.username,
                      password: cred.authenticationDetails.password,
                    },
                  ],
                }
              },
              { credentials: [] }
            )
          )

        study.credentials = credentialPool.credentials
          .map(credential => {
            return `${credential.username},${credential.password}`
          })
          .join('\n')
          .trim()
      } catch (err) {
        rollbar.error(
          `Error while fetching credentials for study ${id}: ${err}`,
          err
        )
        study.credentials = ''
      }
    }

    commit(types.SET_DATA_COLLECTION, dataCollection)
    commit(commonTypes.SET_SINGLE, study)
    commit(types.SET_STUDY_TYPE, study.study_type)
    commit(types.SET_APPLIED_FILTERS, study.filters)
    commit(types.SET_COMPLETION_CODES, study.completion_codes)

    return study
  },

  fetchTotalCost(
    { getters, commit, dispatch },
    { id, reward, total_available_places, status, workspace_id }
  ) {
    const study_type = getters.studyType
    let endpoint = endpoints.STUDY_COST
    let params = { reward, total_available_places, study_type }
    if (workspace_id) {
      params.workspace_id = workspace_id
    }
    if (id && status !== DRAFT) {
      endpoint = endpoints.STUDY_COST_BY_ID(id)
      params = { total_available_places }
    }
    dispatch('setLoadingTotalCost', true)
    return http.post(endpoint, params).then(payload => {
      const total_fees =
        payload.fees + payload.vat + (payload.representative_sample_fee ?? 0)

      commit(types.SET_TOTAL_COST, {
        ...payload,
        total_fees,
      })
      dispatch('setLoadingTotalCost', false)
    })
  },

  setLoadingTotalCost({ commit }, loading) {
    commit(types.SET_LOADING_TOTAL_COST, loading)
  },

  create({ commit, getters, dispatch }) {
    const study = createInitialStudyFormValues()
    const reward = getters.getRecommendedReward(study.estimated_completion_time)
    study.reward = reward

    commit(commonTypes.SET_SINGLE, study)
    commit(types.SET_STUDY_TYPE, 'SINGLE')

    const defaultCompletionCode = createNewCompletionCodeObject()
    commit(types.SET_COMPLETION_CODES, [defaultCompletionCode])

    commit(types.SET_DATA_COLLECTION, DATA_COLLECTION_EXTERNAL)
    commit(types.SET_APPLIED_FILTERS, [])
    dispatch('researcher/surveys/create', null, { root: true })

    return study
  },

  update({ commit, state }, patch) {
    if (!patch.id) {
      const { id } = state.study
      patch.id = id
    }
    commit(commonTypes.UPDATE, patch)
  },

  async save(
    { commit, state, dispatch, rootGetters },
    { formData, confirmation, projectId }
  ) {
    const { id } = state.study
    const {
      survey: surveyFormState,
      survey_pn: privacyNotice,
      data_collection_method: dataCollectionMethod,
      has_accepted_survey_builder_terms,
      submissions_config,
      credentials_option,
      ...study
    } = formData

    if (credentials_option !== undefined) {
      study.has_credentials = credentials_option === 'yes'
    }

    // if the study has an applied filter set, we need to ensure we
    // don't send along any dangling state for filters & study_type
    study.filters = study.filter_set_id ? [] : state.appliedFilters
    study.study_type = study.filter_set_id ? SINGLE : state.studyType
    study.completion_codes = state.completionCodes

    let maxSubmissionsPerParticipant = MAX_SUBMISSIONS_PER_PARTICIPANT_DEFAULT

    switch (dataCollectionMethod) {
      case DATA_COLLECTION_TASKFLOW:
        delete study.external_study_url

        study.access_details = study.access_details.map(
          ({ task_name: _, ...ad }) => ad
        )
        commit(types.SET_ACCESS_DETAILS, study.access_details)
        break

      case DATA_COLLECTION_SURVEY_BUILDER: {
        delete study.access_details
        delete study.access_details_collection_id

        const survey = await dispatch(
          'researcher/surveys/save',
          {
            surveyFormState,
            studyName: study.name,
          },
          { root: true }
        )
        // See: https://github.com/prolific-oss/survey-api/blob/main/docs/adr/0002-the-external-study-url.md
        study.external_study_url = `https://prolific.com/surveys/${survey._id}`
        study.privacy_notice = privacyNotice
        break
      }

      case DATA_COLLECTION_EXTERNAL:
        delete study.access_details
        delete study.access_details_collection_id

        if (isSingle(study.study_type)) {
          maxSubmissionsPerParticipant =
            submissions_config?.max_submissions_per_participant ??
            MAX_SUBMISSIONS_PER_PARTICIPANT_DEFAULT
        }
        break
    }

    setSubmissionsConfigField(
      study,
      'max_submissions_per_participant',
      maxSubmissionsPerParticipant
    )

    setSubmissionsConfigField(
      study,
      'max_concurrent_submissions',
      submissions_config?.max_concurrent_submissions
    )

    const token = rootGetters['oidc/oidcAccessToken']
    const credentialsClient = new CredentialsClient(token)
    const oldCredentialPoolId = state.study.credential_pool_id

    if (oldCredentialPoolId) {
      await credentialsClient
        .deleteCredentialPool(oldCredentialPoolId)
        .catch(error =>
          rollbar.error('Error deleting credential pool: ', error)
        )
    }

    if (study.has_credentials) {
      const workspaceId = rootGetters['researcher/workspaces/workspaceId']
      // @todo - this sort of sanitation of input data should probably live in the client itself
      const removeConsecutiveNewlinesRegex = /\n(?=\n)/g
      const cleanCredentials = study.credentials
        .replace(removeConsecutiveNewlinesRegex, '')
        .trim()

      const { credentialPoolId } = await credentialsClient.createCredentialPool(
        cleanCredentials,
        workspaceId
      )

      commit(types.SET_CREDENTIALS, cleanCredentials)
      commit(types.SET_CREDENTIAL_POOL_ID, credentialPoolId)
      study.credential_pool_id = credentialPoolId
    }

    const headers = {
      ...(confirmation && { 'X-Confirmation-Request': 'True' }),
    }

    let request

    if (id) {
      request = http.patch(endpoints.STUDY(id), study, { headers })
    } else if (projectId) {
      request = http.post(endpoints.PROJECT_STUDIES(projectId), study, {
        headers,
      })
    } else {
      request = http.post(endpoints.STUDIES, study, { headers })
    }

    return await request.then(res => {
      if (!confirmation) {
        if (res.status && res.status.toUpperCase() === 'UNPUBLISHED') {
          // TEMP: replace all UNPUBLISHED statuses with DRAFT client side
          res.status = 'DRAFT'
        }
        commit(commonTypes.SET_SINGLE, res)
        commit(types.SET_DATA_COLLECTION, dataCollectionMethod)
      }
      return res
    })
  },

  setAppliedFilters({ commit }, filters) {
    commit(types.SET_APPLIED_FILTERS, filters)
  },

  applyFilter({ commit }, filter) {
    commit(types.APPLY_FILTER, filter)
  },

  removeFilter({ commit }, filterId) {
    commit(types.REMOVE_FILTER, filterId)
  },

  updateRepresentativeSample({ commit, dispatch }, studyType) {
    commit(types.SET_STUDY_TYPE, studyType)
  },

  setStudyType({ commit }, studyType) {
    commit(types.SET_STUDY_TYPE, studyType)
  },

  async validateParticipantIds({ rootGetters }, ids) {
    const workspaceId = rootGetters['researcher/workspaces/workspaceId']

    const {
      invalid_participant_ids: nonExistentIds,
      us_tax_invalid_participant_ids: usTaxInvalidIds,
    } = await http.post(endpoints.VALIDATE_PARTICIPANT_IDS, {
      workspace_id: workspaceId,
      participant_ids: ids,
    })

    if (nonExistentIds.length || usTaxInvalidIds.length) {
      return Promise.reject({
        nonExistentIds,
        usTaxInvalidIds,
      })
    }

    return Promise.resolve()
  },

  // study id here is taken from the confirmation
  // request, not local state - as there might not be one
  publish({ commit, getters }, id) {
    commit(commonTypes.UPDATE, { id, status: PUBLISHING })
    return http.post(endpoints.STUDY_ACTION(id), {
      action: transitions.PUBLISH,
    })
  },

  schedulePublish({ commit, getters }, id) {
    commit(commonTypes.UPDATE, { id, status: PUBLISHING })
    return http.post(endpoints.STUDY_ACTION(id), {
      action: transitions.SCHEDULE_PUBLISH,
    })
  },

  adjustSchedule({ commit }, { id, publish_at }) {
    return http.patch(endpoints.STUDY(id), { publish_at }).then(() => {
      commit(commonTypes.UPDATE, { id, publish_at })
    })
  },

  cancelSchedule({ dispatch, commit }, id) {
    return dispatch('transition', {
      studyId: id,
      transition: transitions.CANCEL_PUBLISH,
    }).then(() => {
      commit(commonTypes.UPDATE, { id, publish_at: null })
    })
  },

  publishNow({ dispatch, commit }, id) {
    return dispatch('cancelSchedule', id).then(() => {
      dispatch('publish', id).then(() => {
        commit(commonTypes.UPDATE, { id, publish_at: null })
      })
    })
  },

  reallocate(_, id) {
    return http.post(endpoints.REALLOCATE(id))
  },

  // actions performed on both single and list elements
  // therefore, take id from param rather than state
  destroy({ commit }, studyId) {
    return http
      .delete(endpoints.STUDY(studyId))
      .then(() => commit(commonTypes.DELETE, studyId))
  },

  removeFromList({ commit }, studyId) {
    commit(commonTypes.REMOVE_FROM_LIST, studyId)
  },

  duplicate(_, studyId) {
    return http.post(endpoints.DUPLICATE_STUDY(studyId))
  },

  async increasePlaces(
    { commit },
    { id, total_available_places, study_type, access_details }
  ) {
    const { status } = await http.patch(endpoints.STUDY(id), {
      total_available_places,
      access_details,
    })

    pendo.track(RESEARCHER_INCREASE_STUDY_PLACES, {
      total_available_places,
      study_type,
    })

    commit(commonTypes.UPDATE, {
      id,
      total_available_places,
      status,
      access_details,
    })
  },

  async increaseCredentials({ commit, rootGetters }, { id, credentials }) {
    const study = await http.get(endpoints.STUDY(id))

    if (!study.credential_pool_id)
      return Promise.reject(new Error('Study does not have credentials'))

    const token = rootGetters['oidc/oidcAccessToken']
    const client = new CredentialsClient(token)
    const additionalCredentials = credentials.replace(/\n(?=\n)/g, '').trim()

    try {
      return await client.updateCredentialPool(
        study.credential_pool_id,
        additionalCredentials
      )
    } catch (error) {
      rollbar.error(error)
      throw error
    }
  },

  increaseReward({ commit }, { studyId, reward }) {
    return http.patch(endpoints.STUDY(studyId), { reward }).then(() => {
      commit(commonTypes.UPDATE, { id: studyId, reward })
      notifier.success(translate('notifications.increase_reward_success'))
    })
  },

  updateInternalName({ commit }, { id, internal_name }) {
    return http.patch(endpoints.STUDY(id), { internal_name }).then(() => {
      commit(commonTypes.UPDATE, { id, internal_name })
    })
  },

  transition({ commit, dispatch }, { studyId, transition }) {
    commit(commonTypes.UPDATE, { id: studyId, status: PROCESSING })
    return http
      .post(endpoints.STUDY_ACTION(studyId), { action: transition })
      .then(({ reward_level }) => {
        // need to inform researchers if their study is underpaying when
        // starting/pausing/stopping in the submissions view
        dispatch('updateStudyIsUnderpaying', {
          transition,
          studyId,
          reward_level,
        })
      })
  },

  pause({ dispatch }, studyId) {
    return dispatch('transition', { studyId, transition: transitions.PAUSE })
  },

  stop({ dispatch }, studyId) {
    return dispatch('transition', { studyId, transition: transitions.STOP })
  },

  start({ dispatch }, studyId) {
    return dispatch('transition', { studyId, transition: transitions.START })
  },

  cancelPublish({ commit }, studyId) {
    commit(commonTypes.UPDATE, { id: studyId, status: DRAFT })
  },

  downloadInvoice(
    { dispatch },
    { studyId, filePrefix = 'prolific_quote', detailed = false } = {}
  ) {
    dispatch('global/setLoading', true, { root: true })
    const dl = () =>
      http
        .get(endpoints.INVOICE(studyId), {
          params: { detailed: +detailed },
          responseType: 'blob',
          headers: { Accept: 'application/pdf' },
        })
        .then(data => {
          download(
            data,
            `${filePrefix}_${detailed ? 'detailed_' : ''}${studyId}.pdf`,
            'application/pdf'
          )
          dispatch('global/setLoading', false, { root: true })
        })
    return dl()
  },

  downloadReceipt({ dispatch }, { studyId, detailed }) {
    dispatch('downloadInvoice', {
      studyId,
      filePrefix: 'prolific_summary',
      detailed,
    })
  },

  appendToAllowlist({ state, dispatch, getters }, additionalIds) {
    const currentAllowlist = getters.appliedAllowlist
    if (!currentAllowlist) {
      return Promise.reject(new Error('No allowlist applied'))
    }
    const newAllowlist = union(currentAllowlist.selected_values, additionalIds)
    const { id: studyId } = state.study
    return http
      .patch(endpoints.STUDY_ALLOWLIST(studyId), {
        selected_values: newAllowlist,
      })
      .then(() => {
        dispatch('applyFilter', {
          filter_id: CUSTOM_ALLOWLIST_FILTER_ID,
          selected_values: newAllowlist,
        })
      })
  },

  updateStudyIsUnderpaying({ commit }, { transition, studyId, reward_level }) {
    const { START, PAUSE, STOP } = transitions
    const { name } = router.currentRoute.value
    if (
      [START, PAUSE, STOP].includes(transition) &&
      name?.match(/studies\.submissions/)
    ) {
      commit(commonTypes.UPDATE, { id: studyId, reward_level })
    }
  },

  fetchAdjustmentPaymentOptions({ commit }, studyId) {
    return http
      .get(endpoints.ADJUSTMENT_PAYMENTS_CALCULATOR(studyId))
      .then(adjustmentPaymentOptions => {
        commit(
          types.SET_ADJUSTMENT_PAYMENT_OPTIONS,
          adjustmentPaymentOptions.results
        )
      })
  },

  adjustRewardPerHour({ dispatch, commit }, { studyId, reward_per_hour }) {
    return http
      .post(endpoints.ADJUSTMENT_PAYMENTS(studyId), {
        reward_per_hour,
      })
      .then(() => {
        // need to fetch user to update balance
        dispatch('auth/fetchUser', null, { root: true })
        dispatch('fetch', studyId)
      })
  },

  addCompletionCode({ commit }, { code_type }) {
    const newCompletionCode = createNewCompletionCodeObject({ code_type })
    commit(types.ADD_COMPLETION_CODE, newCompletionCode)
  },

  removeCompletionCodeByType({ commit }, codeType) {
    commit(types.REMOVE_COMPLETION_CODE_BY_TYPE, codeType)
  },

  async startTrialStudy({ commit, rootGetters, dispatch }, payload) {
    try {
      const response = await http.post(
        endpoints.START_FREE_TRIAL_STUDY,
        payload,
        {
          useGlobalErrorHandling: false,
        }
      )

      const url = response.external_study_url
      const formId = getFormIdFromUrl(url)

      if (!formId) {
        notifier.error(translate('notifications.trial_study_publish_error'), {
          duration: 0,
        })
        return false
      }

      const trialStudyData = {
        formId,
        studyId: response.id,
        status: response.status,
      }

      commit(types.SET_TRIAL_STUDY_DATA, trialStudyData)
      commit(types.SET_HAS_STUDIES, true)

      return response
    } catch {
      notifier.error(translate('notifications.trial_study_publish_error'), {
        duration: 0,
      })
      return false
    }
  },

  fetchEstimatedRecruitmentTime({ commit }, payload) {
    commit(types.SET_LOADING_ESTIMATED_RECRUITMENT_TIME, true)
    return http
      .post(endpoints.ESTIMATED_RECRUITMENT_TIME, payload)
      .then(payload => {
        commit(types.SET_ESTIMATED_RECRUITMENT_TIME, payload)
        return payload
      })
      .finally(() => {
        commit(types.SET_LOADING_ESTIMATED_RECRUITMENT_TIME, false)
      })
  },
}

const getters = {
  total: ({ total }) => total,

  byStatus: (state, getters) => statuses => {
    if (!getters.all) {
      return []
    }
    if (!Array.isArray(statuses)) {
      statuses = [statuses]
    }
    statuses.push(PROCESSING)
    return getters.all.filter(study => isStatus(study.status, statuses))
  },

  draft: (state, getters) => getters.byStatus(DRAFT),

  active: (state, getters) => getters.byStatus([ACTIVE, PAUSED, PUBLISHING]),

  completed: (state, getters) => getters.byStatus(COMPLETED),

  study: ({ study }) => study,

  studyType: ({ studyType }) => studyType,

  dataCollection: ({ dataCollection }) => dataCollection,

  stratification: ({ stratification }) => stratification,

  hasRepresentativeSample: ({ study, studyType }) => {
    return !isEmpty(study) && isRepSample(studyType)
  },

  hasAllowlist: (_state, getters) => {
    return !!getters.appliedAllowlist
  },

  appliedAllowlist: (state, getters) => {
    return state.appliedFilters.find(
      ({ filter_id }) => filter_id === CUSTOM_ALLOWLIST_FILTER_ID
    )
  },

  adjustmentPaymentOptions: ({ adjustmentPaymentOptions }) =>
    adjustmentPaymentOptions,

  isPublished: ({ study }) => {
    return study.status && isPublished(study.status)
  },

  isScheduled: ({ study }) => {
    return study.status && isScheduled(study.status)
  },

  costs: ({ costs }) => costs,

  hasBalanceForStudy: ({ costs }, _getters, _rootState, rootGetters) => {
    const availableBalance =
      rootGetters['researcher/workspaces/wallet']?.available_balance

    const couponBalance = rootGetters[
      'auth/user'
    ]?.redeemable_referral_coupons?.reduce((acc, curr) => {
      return acc + curr.recipient_amount
    }, 0)

    return availableBalance + couponBalance >= costs.total_cost
  },

  loadingTotalCost: ({ loadingTotalCost }) => loadingTotalCost,

  discount: ({ discount }) => discount,

  discountPercentage: ({ discountPercentage }) => discountPercentage,

  // TODO: this probably doesn't belong here anymore. Refactor out to a separate helper perhaps?
  percentCompleted:
    () =>
    (study = {}) => {
      const { places_taken, total_available_places } = study
      if (!places_taken || !total_available_places) {
        return 0
      }
      const frac = places_taken / total_available_places
      if (Number.isNaN(frac)) {
        return 0
      }
      const percent = Math.floor(frac * 100)
      if (percent > 100) {
        return 100
      }
      return percent
    },
  recommendedRewardPerHour: (_state, _getters, _rootState, rootGetters) => {
    const recommendedRewardPerHourGBP = RECOMMENDED_REWARD_PER_HOUR_GBP
    const recommendedRewardPerHourUSD = RECOMMENDED_REWARD_PER_HOUR_USD

    return rootGetters['auth/isGBP']
      ? recommendedRewardPerHourGBP
      : recommendedRewardPerHourUSD
  },
  minRewardPerHour: (_state, _getters, _rootState, rootGetters) => {
    return rootGetters['auth/isGBP']
      ? MIN_REWARD_PER_HOUR_GBP
      : MIN_REWARD_PER_HOUR_USD
  },
  maxRewardPerHour: (_state, getters) =>
    2 * getters.recommendedRewardPerHour - getters.minRewardPerHour,
  getRecommendedReward: (_state, getters) => estimatedCompletionTime =>
    Math.ceil(
      (getters.recommendedRewardPerHour * estimatedCompletionTime) / 60
    ),
  getMinReward: (_state, getters) => estimatedCompletionTime =>
    Math.ceil((getters.minRewardPerHour * estimatedCompletionTime) / 60),
  getMaxAllowedTime: () => estimatedCompletionTime =>
    2 +
    estimatedCompletionTime +
    Math.ceil(10 * Math.sqrt(estimatedCompletionTime)),
  getRewardPerHour: () => (reward, estimatedCompletionTime) =>
    Math.floor(((reward || 0) / (estimatedCompletionTime || 1)) * 60),

  institution: ({ researcher }, _getters, _rootState, rootGetters) => {
    const id = rootGetters['auth/userId']
    if (researcher?.[id]?.institution?.name) {
      return researcher[id].institution
    }

    return null
  },

  canBeReallocated: ({ study }) => {
    return study?.can_be_reallocated ?? false
  },

  isReallocated: ({ study }) => {
    return study?.is_reallocated ?? false
  },

  completionCodeObjectByCode:
    ({ completionCodes }) =>
    code =>
      completionCodes.find(completionCode => completionCode.code === code),

  appliedFilters: ({ appliedFilters }) => appliedFilters,

  hasStudies: ({ hasStudies }) => hasStudies,

  trialStudyData: ({ trialStudyData }) => trialStudyData,

  strataCount: ({ appliedFilters }) => {
    return appliedFilters.reduce((acc, { weightings }) => {
      if (!weightings) {
        return acc
      }
      return acc * Object.keys(weightings).length
    }, 1)
  },

  estimatedRecruitmentTime: ({ estimatedRecruitmentTime }) =>
    estimatedRecruitmentTime,

  loadingEstimatedRecruitmentTime: ({ loadingEstimatedRecruitmentTime }) =>
    loadingEstimatedRecruitmentTime,
}

export default merge(common({ entities: 'studies', entity: 'study' }), {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
})
