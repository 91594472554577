import dataCollectionTool from './data-collection-tool'
import eligibility from './eligibility'
import payments from './payments'
import referralCampaigns from './referral-campaigns'
import studies from './studies'
import submissions from './submissions'
import surveys from './surveys'

export default {
  namespaced: true,
  modules: {
    studies,
    surveys,
    submissions,
    eligibility,
    payments,
    referralCampaigns,
    dataCollectionTool,
  },
}
