import aiTaskBuilder from './ai-task-builder'
import campaigns from './campaigns'
import filters from './filters'
import integrations from './integrations'
import participantGroups from './participant-groups'
import studies from './studies'
import surveys from './surveys'
import workspaces from './workspaces'

export const types = {}

const state = {}

const mutations = {}

const actions = {}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {
    studies,
    workspaces,
    integrations,
    surveys,
    filters,
    participantGroups,
    campaigns,
    aiTaskBuilder,
  },
}
