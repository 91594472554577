import { featureIsEnabled } from './index'

/**
 * MA (4/8/21): At the moment, this component is only
 * usable with flags that are of Boolean value (true/false).
 *
 * Usage:
 * <p-feature-toggle flag="SOME-FEATURE-FLAG-STRING">
 *   <template #on>
 *     ON
 *   </template>
 *   <template #off>
 *     OFF
 *   </template>
 * </p-feature-toggle>
 *
 * <p-feature-toggle flag="SOME-FEATURE-FLAG-STRING">
 *     ON
 * </p-feature-toggle>
 *
 * <p-feature-toggle flag="featureIsEnabled => featureIsEnabled('SOME-FEATURE-FLAG-STRING') || featureIsEnabled('SOME-FEATURE-OTHER-FLAG-STRING')">
 */

function FeatureToggle({ flag }, { slots: { on, off, default: defaultSlot } }) {
  const isEnabled =
    typeof flag === 'function' ? flag(featureIsEnabled) : featureIsEnabled(flag)
  return isEnabled ? on?.() || defaultSlot?.() : off?.()
}

FeatureToggle.props = {
  flag: {
    type: [String, Function],
    required: true,
  },
}

export default FeatureToggle
