const types = {
  SET_FULLHEIGHT: 'SET_FULLHEIGHT',
}

const state = {
  fullheight: false,
}

const mutations = {
  [types.SET_FULLHEIGHT](state, fullheight) {
    state.fullheight = fullheight
  },
}

const actions = {
  setFullHeight({ commit }, fullheight) {
    commit(types.SET_FULLHEIGHT, fullheight)
  },
}

export default {
  types,
  state,
  mutations,
  actions,
}
